import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import MailOutline from '@mui/icons-material/MailOutline';
import { FormattedMessage } from 'react-intl';
import { useLanguage, routeMap } from '../hooks/useLanguage';
function CtaFinal() {

    const [language] = useLanguage();
    const navigate = useNavigate();
    const handleClickContact = () => {
        navigate(`/${language}/${routeMap[language].contact}`);
      };

    return (
        <section className='content--container'>
            <div className='card-contact'>
                <div>
                <h4><FormattedMessage id="ctaFinal.title" /></h4>
                <p><FormattedMessage id="ctaFinal.description" /></p>

                </div>
        
            <button onClick={handleClickContact}   >
                    <MailOutline /> <FormattedMessage id="ctaFinal.buttonText" />
                </button>
         
            
            </div>
        </section>
    );
}

export default CtaFinal;