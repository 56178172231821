import React, { Suspense, useRef, useState, useEffect,  lazy } from 'react';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { useGLTF, Environment, OrbitControls } from '@react-three/drei';
import { EffectComposer, Bloom } from '@react-three/postprocessing';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLanguage } from '../hooks/useLanguage';
import "./About.scss"
import "./Skeleton.scss"
import * as THREE from 'three';
import CtaFinal from '../components/CtaFinal'

const Model = ({ url, userRotation, isMobile }) => {
  const { scene } = useGLTF(url);
  const modelRef = useRef();
  const rotationRef = useRef(0);
  const targetRotationRef = useRef(0);

  useEffect(() => {
    if (modelRef.current) {
      const scale = isMobile ? 2.0 : 2.5;
      modelRef.current.scale.set(scale, scale, scale);
      modelRef.current.position.set(0, isMobile ? -2.0 : -2.5, 0);
    }
  }, [isMobile]);

  useFrame((state, delta) => {
    if (modelRef.current) {
      rotationRef.current += delta * 0.5;
      const continuousRotation = Math.sin(rotationRef.current) * 0.2;
      targetRotationRef.current = THREE.MathUtils.lerp(
        targetRotationRef.current,
        userRotation,
        0.1
      );
      modelRef.current.rotation.y = targetRotationRef.current + continuousRotation;
    }
  });

  return <primitive ref={modelRef} object={scene} />;
};

const Scene = ({ model, isMobile }) => {
  const [userRotation, setUserRotation] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const previousMouseXRef = useRef(0);

  const handlePointerDown = (event) => {
    setIsDragging(true);
    previousMouseXRef.current = event.clientX;
  };

  const handlePointerUp = () => {
    setIsDragging(false);
  };

  const handlePointerMove = (event) => {
    if (isDragging) {
      const deltaX = event.clientX - previousMouseXRef.current;
      setUserRotation((prev) => prev + deltaX * 0.01);
      previousMouseXRef.current = event.clientX;
    }
  };

  useEffect(() => {
    window.addEventListener('pointerup', handlePointerUp);
    window.addEventListener('pointermove', handlePointerMove);
    return () => {
      window.removeEventListener('pointerup', handlePointerUp);
      window.removeEventListener('pointermove', handlePointerMove);
    };
  }, [isDragging]);

  return (
    <Canvas
      camera={{ position: [0, 0, isMobile ? 6 : 5], fov: isMobile ? 60 : 65 }}
      onPointerDown={handlePointerDown}
    >
      <ambientLight intensity={2} />
      <spotLight position={[10, 10, 10]} angle={0.15} penumbra={2} intensity={1} castShadow />
      <pointLight position={[-10, -10, -10]} intensity={0.5} />
      <Suspense fallback={null}>
        <Model url={model} userRotation={userRotation} isMobile={isMobile} />
        <Environment preset="city" />
      </Suspense>
      <EffectComposer>
        <Bloom
          intensity={isMobile ? 1.5 : 2}
          luminanceThreshold={0.2}
          luminanceSmoothing={0.9}
          mipmapBlur={true}
          resolutionScale={isMobile ? 0.5 : 1}
        />
      </EffectComposer>
    </Canvas>
  );
};

const About = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [language] = useLanguage();
  const intl = useIntl();

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const models = [
    `${process.env.PUBLIC_URL}/media/3d/1.glb`,
    `${process.env.PUBLIC_URL}/media/3d/2.glb`,
    `${process.env.PUBLIC_URL}/media/3d/3.glb`,
    `${process.env.PUBLIC_URL}/media/3d/4.glb`
  ];


  const sections = [
    {
      titleId: "about.section1.title",
      textId: "about.section1.text"
    },
    {
      titleId: "about.section2.title",
      textId: "about.section2.text"
    },
    {
      titleId: "about.section3.title",
      textId: "about.section3.text"
    },
    {
      titleId: "about.section4.title",
      textId: "about.section4.text"
    }
  ];
  return (
    <body>
      <section className='pegoraro-hero-section'  >




        {/* <img  className='pegoraro-hero-section-img'   src="/media/shape.png"  /> */}
        <section className='hero'>
          <div className='hero--container'>
            <h1>
              <FormattedMessage id="hero.title" />
            </h1>
            <p>
              <span className='span-cirle'>
                <FormattedMessage id="hero.founded" />
              </span>
              {' '}
              <FormattedMessage id="hero.description1" />
            </p>
            <p>
              <FormattedMessage id="hero.description2" />
            </p>
          </div>
        </section>


        <div style={{ marginTop: '50px' }} className='dashed-bottom'  >

        </div>


      </section>


      <div className='h2-central'  >

        <h2>
          <FormattedMessage id="home.whyChooseUs" />
        </h2>
      </div>

      <div className="about-page">




        <div className="model-viewer-container">
          {models.map((model, index) => (
            <div
              key={index}
              className={`model-section ${index === 1 || index === 3 ? 'alternate-section' : ''}`}
            >
              <div className='text-container'  >

                <h2 className="section-title">
                  <FormattedMessage id={sections[index].titleId} />
                </h2>
                <div className="text-section">
                  <p><FormattedMessage id={sections[index].textId} /></p>
                </div>
              </div>
              <div className="model-canvas-wrapper">
                <Scene model={model} isMobile={isMobile} />
              </div>
            </div>
          ))}
        </div>
      </div>

      <section className='margin-desktop'   >
        <CtaFinal />
      </section>
    </body>

  );
};

export default About;


