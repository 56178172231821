import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import './NotFound.scss';
import MetaTags from '../components/MetaTags';
import { useLanguage, routeMap } from '../hooks/useLanguage';

const NotFound = () => {
  const [language] = useLanguage();
  const intl = useIntl();

  return (
    <div className="not-found">
      <MetaTags
        title={intl.formatMessage({ id: "notFound.metaTitle" })}
        description={intl.formatMessage({ id: "notFound.metaDescription" })}
      />
      <div className="spinner-container">
        <div className="spinner"></div>
      </div>
      <h1 className="glitch" data-text="404">404</h1>
      <p><FormattedMessage id="notFound.message" /></p>
      <div className='button-flex'>
        <Link className='button-dark' to={`/${language}/${routeMap[language].home}`}>
          <FormattedMessage id="notFound.homeButton" />
        </Link>
      </div>
    </div>
  );
};

export default NotFound;