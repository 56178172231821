import React from 'react';
import { FormattedMessage, IntlProvider } from 'react-intl';
import { motion, AnimatePresence } from 'framer-motion';
import './LanguagePrompt.scss';
import messages from '../translations'; // Assicurati che il percorso sia corretto

const LanguagePrompt = ({ onAccept, onDecline, detectedLanguage }) => {
  const overlayVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 }
  };

  const promptVariants = {
    hidden: { y: 50, opacity: 0 },
    visible: { y: 0, opacity: 1, transition: { type: 'spring', stiffness: 300, damping: 30 } }
  };

  const buttonVariants = {
    hover: { scale: 1.05 },
    tap: { scale: 0.95 }
  };

  // Usa la lingua di sistema rilevata, o fallback su 'en' se non supportata
  const systemLanguage = messages[detectedLanguage] ? detectedLanguage : 'en';

  return (
    <IntlProvider messages={messages[systemLanguage]} locale={systemLanguage}>
      <AnimatePresence>
        <motion.div
          className="language-prompt-overlay"
          variants={overlayVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
        >
          <motion.div
            className="language-prompt-container"
            variants={promptVariants}
          >
            <h2><FormattedMessage id="languagePrompt.title" /></h2>
            <p>
              <FormattedMessage
                id="languagePrompt.message"
                values={{ language: <strong>{messages[systemLanguage].languageName}</strong> }}
              />
            </p>
            <div className="language-prompt-buttons">
              <motion.button

                onClick={onAccept}
                variants={buttonVariants}
                whileHover="hover"
                whileTap="tap"
              >
                <FormattedMessage id="languagePrompt.accept" />
              </motion.button>
              <motion.button
                onClick={onDecline}
                variants={buttonVariants}
                whileHover="hover"
                whileTap="tap"
              >
                <FormattedMessage id="languagePrompt.decline" />
              </motion.button>
            </div>
          </motion.div>
        </motion.div>
      </AnimatePresence>
    </IntlProvider>
  );
};

export default LanguagePrompt;