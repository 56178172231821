export default {
  it: {
    languageName: 'Italiano',
    'languagePrompt.title': 'Cambio Lingua',
    'languagePrompt.message': 'Abbiamo rilevato che la lingua del tuo sistema è {language}. Vuoi cambiare la lingua del sito?',
    'languagePrompt.accept': 'Sì, cambia lingua',
    'languagePrompt.decline': 'No, mantieni la lingua attuale',
    'nav.home': 'Home',
    'nav.services': 'Servizi',
    'nav.aboutUs': 'Chi Siamo',
    'nav.workWithUs': 'Lavora con Noi',
    'nav.disclaimer': 'Disclaimer',
    'nav.contact': 'Contattaci',
    'nav.contactButton': 'Contattaci',
    'footer.shortDescription': 'Esperti in traduzioni madrelingua dal 1990. Professionalità, precisione e qualità al servizio del tuo business globale.',
    'footer.services': 'Servizi',
    'footer.aboutUs': 'Chi Siamo',
    'footer.contacts': 'Contatti',
    'footer.workWithUs': 'Lavora con Noi',
    'footer.copyright': '© PegoraroTraduzioni Srl, {year}',
    'footer.vatNumber': 'P.Iva: {vat}',
    'footer.termsAndConditions': 'Termini e Condizioni',
    'footer.privacyPolicy': 'Privacy Policy',
    'footer.cookiePolicy': 'Cookie Policy',
    'footer.poweredBy': 'Powered by {company}',
    'home.title': 'Benvenuto nella Home',
    'contact.title': 'Contattaci',
    'contact.metaTitle': 'Contattaci - Pegoraro Traduzioni',
    'contact.metaDescription': 'Contattaci per qualsiasi domanda o richiesta di preventivo per i nostri servizi di traduzione.',
    'contact.metaKeywords': 'contatti, traduzioni, servizi di traduzione',
    'contact.title': 'Hai Domande? Contattaci',
    'contact.description': 'Il nostro team è pronto ad aiutarti. Siamo qui per supportarti in ogni fase del tuo progetto di traduzione.',
    'contact.namePlaceholder': 'Il Tuo Nome / Società',
    'contact.emailPlaceholder': 'La Tua Email',
    'contact.messagePlaceholder': 'Il tuo Messaggio',
    'contact.sendButton': 'Invia Messaggio',
    "services.metaTitle": "I Nostri Servizi - Pegoraro Traduzioni",
    "services.metaDescription": "Scopri tutti i nostri servizi di traduzione professionale per il tuo business.",
    "services.metaKeywords": "traduzioni, servizi di traduzione, traduzioni professionali",
    "services.title": "I Nostri Servizi",
    "services.description": "Traduzioni Professionali per il Tuo Business. Portiamo il tuo messaggio nel mondo con precisione e autenticità.",
    "services.quoteButton": "Richiedi un preventivo",
    "services.whyChooseUs": "PERCHÉ SCEGLIERE PEGORARO TRADUZIONI?",
    "serviceDetail.notFound.title": "Servizio non trovato - Pegoraro Traduzioni",
    "serviceDetail.notFound.description": "Pagina di errore 404, servizio non trovato.",
    "serviceDetail.notFound.message": "Servizio non trovato",
    "serviceDetail.metaTitle": "{service} - Pegoraro Traduzioni",
    "serviceDetail.metaKeywords": "traduzioni",
    "serviceDetail.home": "Home",
    "serviceDetail.quoteButton": "Richiedi un preventivo",
    "serviceDetail.ourServices": "I Nostri Servizi di {service}",
    "serviceDetail.whyChooseUs": "PERCHÉ SCEGLIERE PEGORARO TRADUZIONI?",
    "whyUs.experience.title": "Esperienza di 30 Anni",
    "whyUs.experience.description": "Tre decenni di esperienza ci rendono leader nel settore della traduzione.",
    "whyUs.nativeSpeakers.title": "Traduttori Madrelingua",
    "whyUs.nativeSpeakers.description": "Collaboriamo solo con traduttori madrelingua per garantire la massima qualità.",
    "whyUs.specialization.title": "Specializzazione per Settore",
    "whyUs.specialization.description": "Ogni traduttore è specializzato nel proprio settore, assicurando competenza e precisione.",
    "whyUs.innovation.title": "Innovazione e Tecnologia",
    "whyUs.innovation.description": "Utilizziamo le ultime tecnologie per ottimizzare il processo di traduzione e garantire risultati impeccabili.",
    "whyUs.ctaButton": "Richiedi Preventivo",
    "ctaFinal.title": "Hai Domande? Contattaci",
    "ctaFinal.description": "Il nostro team è pronto ad aiutarti. Siamo qui per supportarti in ogni fase del tuo progetto di traduzione.",
    "ctaFinal.buttonText": "Parla con Noi",
    "notFound.metaTitle": "Pagina non trovata - Pegoraro Traduzioni",
    "notFound.metaDescription": "Pagina non trovata. Torna alla home per esplorare i nostri servizi di traduzione.",
    "notFound.message": "Oops! Pagina Non Trovata",
    "notFound.homeButton": "Torna alla Home",
    "disclaimer.metaTitle": "Disclaimer - Pegoraro Traduzioni",
    "disclaimer.metaDescription": "Leggi il nostro disclaimer per informazioni legali e termini di utilizzo del sito.",
    "disclaimer.metaKeywords": "disclaimer, termini di utilizzo, informazioni legali",
    "disclaimer.title": "Disclaimer",
    "disclaimer.description": "In questa pagina si descrivono le modalità di gestione del sito in riferimento al trattamento dei dati personali degli utenti che lo consultano.",
    "disclaimer.termsOfService": "Termini di Servizio",
    "disclaimer.privacyPolicy": "Privacy Policy",
    "disclaimer.cookiePolicy": "Cookie Policy",
    "disclaimer.sectionTitle": "DISCLAIMER",
    "disclaimer.termsOfServiceContent": "...", // Aggiungi qui il contenuto completo
    "disclaimer.privacyPolicyContent": "...", // Aggiungi qui il contenuto completo
    "disclaimer.cookiePolicyContent": "...", // Aggiungi qui il contenuto completo
    "home.metaTitle": "Pegoraro Traduzioni - Home",
    "home.metaDescription": "Benvenuti su Pegoraro Traduzioni, il vostro partner per traduzioni professionali e accurate.",
    "home.metaKeywords": "traduzioni, servizi di traduzione, traduzioni professionali",
    "home.heroDescription": "I nostri traduttori madrelingua trasformano le tue comunicazioni, garantendo precisione e autenticità in ogni parola.",
    "home.quoteButton": "Richiedi un preventivo",
    "home.servicesButton": "Scopri i nostri servizi",
    "home.ourServices": "I NOSTRI SERVIZI",
    "home.servicesTitle": "Traduzioni di precisione, connettendo il tuo business al mondo",
    "home.servicesDescription": "Offriamo servizi di traduzione di alta qualità per soddisfare ogni tua esigenza, combinando l'esperienza dei nostri traduttori madrelingua con le tecnologie più avanzate:",
    "home.discoverAllServices": "Scopri tutti i Servizi",
    "home.whyChooseUs": "PERCHÉ SCEGLIERE PEGORARO TRADUZIONI?",
    "home.aboutUs": "CHI SIAMO",
    "home.aboutUsTitle": "Passione, esperienza, e professionalità.",
    "home.aboutUsDescription": "Pegoraro Traduzioni è stata fondata nel 1990 con l'obiettivo di offrire traduzioni di altissima qualità. La nostra missione è fornire traduzioni accurate e professionali, rispettando valori di etica e trasparenza. Siamo un team di traduttori madrelingua con un approccio personalizzato per ogni progetto, garantendo risultati che superano le aspettative dei nostri clienti. Siamo presenti in tutto il mondo, pronti a supportarti ovunque ti trovi.",
    "home.contactUsButton": "Contattaci",
    "home.discoverAboutUsButton": "Scopri Chi Siamo",
    "realiAnimation.title": "Da 30 Anni",
    "realiAnimation.subtitle": "Traduzioni Madrelingua",
    "about.metaTitle": "Chi Siamo - Pegoraro Traduzioni",
    "about.metaDescription": "Scopri Pegoraro Traduzioni: esperti in traduzioni madrelingua di alta qualità dal 1990. Servizi professionali per la crescita internazionale del tuo business.",
    "about.metaKeywords": "traduzioni, madrelingua, qualità, servizi linguistici, internazionalizzazione",
    "about.section1.title": "Traduzioni Madrelingua di Qualità Superiore",
    "about.section1.text": "Ciò che distingue Pegoraro Traduzioni è la nostra dedizione all'uso esclusivo di traduttori madrelingua. Questa scelta strategica assicura una fedeltà e un'accuratezza che pochi altri possono eguagliare. I nostri traduttori, dislocati in tutto il mondo, sono pronti a supportarti ovunque ti trovi, garantendo un servizio impeccabile a livello globale.",
    "about.section2.title": "Un Partner per la Crescita Internazionale del Tuo Business",
    "about.section2.text": "Siamo al fianco delle aziende da oltre 30 anni, supportandole nell'espansione verso nuovi mercati. Che tu sia una grande azienda o una piccola impresa, le nostre traduzioni tecniche, mediche, legali e commerciali sono progettate per aprire nuove opportunità di business e facilitare la comunicazione internazionale.",
    "about.section3.title": "Investimento nella Formazione Continua",
    "about.section3.text": "Investiamo costantemente nella formazione dei nostri traduttori, garantendo che siano sempre aggiornati sulle ultime tecnologie e metodologie di traduzione. Questo impegno si riflette nella qualità delle nostre traduzioni e nella soddisfazione dei nostri clienti.",
    "about.section4.title": "Un Servizio Globale e Affidabile",
    "about.section4.text": "Con una presenza internazionale, Pegoraro Traduzioni è in grado di offrire supporto linguistico ovunque ti trovi. La nostra rete di traduttori madrelingua copre ogni fuso orario, permettendoci di rispondere prontamente alle tue esigenze e di garantire tempi di consegna rapidi.",
    "work.metaTitle": "Lavora con Noi - Pegoraro Traduzioni",
    "work.metaDescription": "Unisciti al nostro team di traduttori professionisti. Opportunità di lavoro e crescita nel settore delle traduzioni.",
    "work.title": "Lavora con Noi",
    "work.description": "La nostra filosofia è semplice: creare un team di persone diverse e appassionate che valorizzino al meglio il nostro lavoro.",
    "work.applyAsTranslator": "Candidati come traduttore",
    "work.viewOpenPositions": "Vedi le posizioni aperte",
    "work.openPositions": "POSIZIONI APERTE",
    "work.careerTitle": "Costruisci una carriera gratificante nel settore della traduzione",
    "work.careerDescription": "Unisciti al nostro team! Offriamo un ambiente di lavoro stimolante e gratificante, un team di professionisti esperti e una vasta gamma di opportunità di crescita e sviluppo.",
    "work.jobTitle": "Traduttore",
    "work.jobCategory": "TRADUZIONE E LOCALIZZAZIONE",
    "work.jobDescription1": "I traduttori madrelingua sono fondamentali per la nostra agenzia: cerchiamo costantemente professionisti e nuovi talenti specializzati in vari campi.",
    "work.jobDescription2": "Se sei un traduttore freelance appassionato con una profonda conoscenza degli strumenti di traduzione assistita e delle sfide della traduzione e localizzazione, potresti essere la persona giusta per noi! Unisciti al nostro team di traduttori madrelingua e potrai lavorare su progetti stimolanti e vari, collaborando con realtà di diversi settori in tutto il mondo.",
    "work.jobType": "Freelance",
    "work.hideButton": "Nascondi",
    "work.readButton": "Leggi annuncio",
    "work.applyButton": "Candidati",
    "work.application": "Candidatura",
    "work.lookingForTalent": "Siamo alla ricerca di talenti",
    "work.applicationDescription": "Invia la tua candidatura per lavorare con noi. Cerchiamo giovani talenti, esperti professionisti e specialisti di settore.",
    "work.firstName": "Nome",
    "work.lastName": "Cognome",
    "work.email": "Email",
    "work.phone": "Telefono (Opzionale)",
    "work.message": "Messaggio",
    "work.submitApplication": "Invia Candidatura",
    "quote.description":"Richiedi oggi il tuo preventivo gratuito per traduzioni precise e professionali!",
    'quote.namePlaceholder': 'Il Tuo Nome / Società',
    'quote.emailPlaceholder': 'La Tua Email',
    'quote.messagePlaceholder': 'Il tuo Messaggio',
    'quote.servicePlaceholder': 'Scegli il servizio',
    'quote.sendButton' : 'Invia Richiesta',
    'hero.title': 'Passione, Esperienza e Professionalità',
    'hero.founded': 'Fondata nel 1990',
    'hero.description1': "PegoraroTranslations nasce con l'obiettivo di offrire traduzioni di altissima qualità.",
    'hero.description2': 'Siamo un team di traduttori madrelingua con un approccio personalizzato per ogni progetto, garantendo risultati che superano le aspettative dei nostri clienti.',
    'quote.successMessage': 'La tua richiesta è stata inviata con successo!',
    'quote.errorMessage': 'Si è verificato un errore. Per favore, riprova.',
    "job.category.interpreter": "Interprete",
    "job.category.translator": "Traduttore",
    "job.category.proofreader": "Revisore",
    "job.category.project-manager": "Project Manager",
    "job.type.full-time": "Tempo pieno",
    "job.type.part-time": "Part-time",
    "job.type.freelance": "Freelance",
  },
  en: {
    languageName: 'English',
    'languagePrompt.title': 'Language Change',
    'languagePrompt.message': 'We detected that your system language is {language}. Do you want to change the site language?',
    'languagePrompt.accept': 'Yes, change language',
    'languagePrompt.decline': 'No, keep current language',
    'nav.home': 'Home',
    'nav.services': 'Services',
    'nav.aboutUs': 'About Us',
    'nav.workWithUs': 'Work with Us',
    'nav.disclaimer': 'Disclaimer',
    'nav.contact': 'Contact Us',
    'nav.contactButton': 'Contact Us',
    'footer.shortDescription': 'Native translation experts since 1990. Professionalism, accuracy, and quality at the service of your global business.',
    'footer.services': 'Services',
    'footer.aboutUs': 'About Us',
    'footer.contacts': 'Contacts',
    'footer.workWithUs': 'Work with Us',
    'footer.copyright': '© PegoraroTraduzioni Srl, {year}',
    'footer.vatNumber': 'VAT: {vat}',
    'footer.termsAndConditions': 'Terms and Conditions',
    'footer.privacyPolicy': 'Privacy Policy',
    'footer.cookiePolicy': 'Cookie Policy',
    'footer.poweredBy': 'Powered by {company}',
    'contact.title': 'Contact Us',
    'contact.metaTitle': 'Contact Us - Pegoraro Translations',
    'contact.metaDescription': 'Contact us for any questions or quote requests for our translation services.',
    'contact.metaKeywords': 'contacts, translations, translation services',
    'contact.title': 'Have Questions? Contact Us',
    'contact.description': 'Our team is ready to help you. We are here to support you at every stage of your translation project.',
    'contact.namePlaceholder': 'Your Name / Company',
    'contact.emailPlaceholder': 'Your Email',
    'contact.messagePlaceholder': 'Your Message',
    'contact.sendButton': 'Send Message',
    "services.metaTitle": "Our Services - Pegoraro Translations",
    "services.metaDescription": "Discover all our professional translation services for your business.",
    "services.metaKeywords": "translations, translation services, professional translations",
    "services.title": "Our Services",
    "services.description": "Professional Translations for Your Business. We bring your message to the world with precision and authenticity.",
    "services.quoteButton": "Request a quote",
    "services.whyChooseUs": "WHY CHOOSE PEGORARO TRANSLATIONS?",
    "serviceDetail.notFound.title": "Service not found - Pegoraro Translations",
    "serviceDetail.notFound.description": "404 error page, service not found.",
    "serviceDetail.notFound.message": "Service not found",
    "serviceDetail.metaTitle": "{service} - Pegoraro Translations",
    "serviceDetail.metaKeywords": "translations",
    "serviceDetail.home": "Home",
    "serviceDetail.quoteButton": "Request a quote",
    "serviceDetail.ourServices": "Our {service} Services",
    "serviceDetail.whyChooseUs": "WHY CHOOSE PEGORARO TRANSLATIONS?",
    "whyUs.experience.title": "30 Years of Experience",
    "whyUs.experience.description": "Three decades of experience make us leaders in the translation industry.",
    "whyUs.nativeSpeakers.title": "Native Translators",
    "whyUs.nativeSpeakers.description": "We only collaborate with native translators to ensure the highest quality.",
    "whyUs.specialization.title": "Sector Specialization",
    "whyUs.specialization.description": "Each translator is specialized in their own sector, ensuring expertise and precision.",
    "whyUs.innovation.title": "Innovation and Technology",
    "whyUs.innovation.description": "We use the latest technologies to optimize the translation process and guarantee impeccable results.",
    "whyUs.ctaButton": "Request a Quote",
    "ctaFinal.title": "Have Questions? Contact Us",
    "ctaFinal.description": "Our team is ready to help you. We are here to support you at every stage of your translation project.",
    "ctaFinal.buttonText": "Talk to Us",
    "notFound.metaTitle": "Page Not Found - Pegoraro Translations",
    "notFound.metaDescription": "Page not found. Return to the homepage to explore our translation services.",
    "notFound.message": "Oops! Page Not Found",
    "notFound.homeButton": "Back to Home",
    "disclaimer.metaTitle": "Disclaimer - Pegoraro Translations",
    "disclaimer.metaDescription": "Read our disclaimer for legal information and terms of use of the site.",
    "disclaimer.metaKeywords": "disclaimer, terms of use, legal information",
    "disclaimer.title": "Disclaimer",
    "disclaimer.description": "This page describes how the site is managed with reference to the processing of personal data of users who consult it.",
    "disclaimer.termsOfService": "Terms of Service",
    "disclaimer.privacyPolicy": "Privacy Policy",
    "disclaimer.cookiePolicy": "Cookie Policy",
    "disclaimer.sectionTitle": "DISCLAIMER",
    "disclaimer.termsOfServiceContent": "...", // Add full content here
    "disclaimer.privacyPolicyContent": "...", // Add full content here
    "disclaimer.cookiePolicyContent": "...", // Add full content here
    "home.metaTitle": "Pegoraro Translations - Home",
    "home.metaDescription": "Welcome to Pegoraro Translations, your partner for professional and accurate translations.",
    "home.metaKeywords": "translations, translation services, professional translations",
    "home.heroDescription": "Our native-speaking translators transform your communications, ensuring precision and authenticity in every word.",
    "home.quoteButton": "Request a quote",
    "home.servicesButton": "Discover our services",
    "home.ourServices": "OUR SERVICES",
    "home.servicesTitle": "Precision translations, connecting your business to the world",
    "home.servicesDescription": "We offer high-quality translation services to meet all your needs, combining the expertise of our native-speaking translators with the most advanced technologies:",
    "home.discoverAllServices": "Discover All Services",
    "home.whyChooseUs": "WHY CHOOSE PEGORARO TRANSLATIONS?",
    "home.aboutUs": "ABOUT US",
    "home.aboutUsTitle": "Passion, experience, and professionalism.",
    "home.aboutUsDescription": "Pegoraro Translations was founded in 1990 with the aim of offering the highest quality translations. Our mission is to provide accurate and professional translations, respecting values of ethics and transparency. We are a team of native-speaking translators with a personalized approach for each project, ensuring results that exceed our clients' expectations. We are present worldwide, ready to support you wherever you are.",
    "home.contactUsButton": "Contact Us",
    "home.discoverAboutUsButton": "Discover About Us",
    "realiAnimation.title": "For 30 Years",
    "realiAnimation.subtitle": "Native Language Translations",
    "about.metaTitle": "About Us - Pegoraro Translations",
    "about.metaDescription": "Discover Pegoraro Translations: experts in high-quality native language translations since 1990. Professional services for your business's international growth.",
    "about.metaKeywords": "translations, native speakers, quality, language services, internationalization",
    "about.section1.title": "Superior Quality Native Language Translations",
    "about.section1.text": "What sets Pegoraro Translations apart is our dedication to exclusively using native-speaking translators. This strategic choice ensures a level of fidelity and accuracy that few others can match. Our translators, located all over the world, are ready to support you wherever you are, guaranteeing impeccable service on a global scale.",
    "about.section2.title": "A Partner for Your Business's International Growth",
    "about.section2.text": "We've been supporting companies for over 30 years in their expansion into new markets. Whether you're a large corporation or a small business, our technical, medical, legal, and commercial translations are designed to open new business opportunities and facilitate international communication.",
    "about.section3.title": "Investment in Continuous Training",
    "about.section3.text": "We constantly invest in the training of our translators, ensuring they are always up-to-date with the latest translation technologies and methodologies. This commitment is reflected in the quality of our translations and in our clients' satisfaction.",
    "about.section4.title": "A Global and Reliable Service",
    "about.section4.text": "With an international presence, Pegoraro Translations is able to offer linguistic support wherever you are. Our network of native-speaking translators covers every time zone, allowing us to respond promptly to your needs and guarantee quick delivery times.",
  "work.metaTitle": "Work with Us - Pegoraro Translations",
    "work.metaDescription": "Join our team of professional translators. Job opportunities and growth in the translation industry.",
    "work.title": "Work with Us",
    "work.description": "Our philosophy is simple: create a team of diverse and passionate people who bring out the best in our work.",
    "work.applyAsTranslator": "Apply as a translator",
    "work.viewOpenPositions": "View open positions",
    "work.openPositions": "OPEN POSITIONS",
    "work.careerTitle": "Build a rewarding career in the translation industry",
    "work.careerDescription": "Join our team! We offer a stimulating and rewarding work environment, a team of expert professionals, and a wide range of opportunities for growth and development.",
    "work.jobTitle": "Translator",
    "work.jobCategory": "TRANSLATION AND LOCALIZATION",
    "work.jobDescription1": "Native-speaking translators are fundamental to our agency: we constantly seek professionals and new talents specialized in various fields.",
    "work.jobDescription2": "If you are a passionate freelance translator with a deep understanding of computer-assisted translation tools and the challenges of translation and localization, you might be the right person for us! Join our team of native-speaking translators and you'll be able to work on stimulating and varied projects, collaborating with entities from different sectors worldwide.",
    "work.jobType": "Freelance",
    "work.hideButton": "Hide",
    "work.readButton": "Read job post",
    "work.applyButton": "Apply",
    "work.application": "Application",
    "work.lookingForTalent": "We're looking for talent",
    "work.applicationDescription": "Send your application to work with us. We're looking for young talents, experienced professionals, and industry specialists.",
    "work.firstName": "First Name",
    "work.lastName": "Last Name",
    "work.email": "Email",
    "work.phone": "Phone (Optional)",
    "work.message": "Message",
    "work.submitApplication": "Submit Application",
    "quote.description":"Request your free quote for precise and professional translations today!",
    'quote.namePlaceholder': 'Your Name / Company',
    'quote.emailPlaceholder': 'Your Email',
    'quote.messagePlaceholder': 'Your Message',
    'quote.servicePlaceholder': 'Choose the service',
    'quote.sendButton' : 'Send Request',
    'quote.successMessage': 'Your request has been successfully submitted!',
    'quote.errorMessage': 'An error occurred. Please try again.',
    'hero.title': 'Passion, Experience, and Professionalism',
    'hero.founded': 'Founded in 1990',
    'hero.description1': "PegoraroTranslations was born with the aim of offering translations of the highest quality.",
    'hero.description2': "We are a team of native-speaking translators with a personalized approach for each project, ensuring results that exceed our clients\' expectations.",
    "job.category.interpreter": "Interpreter",
    "job.category.translator": "Translator",
    "job.category.proofreader": "Proofreader",
    "job.category.project-manager": "Project Manager",
    "job.type.full-time": "Full-time",
    "job.type.part-time": "Part-time",
    "job.type.freelance": "Freelance",
  },
  de: {
    languageName: 'Deutsch',
    'languagePrompt.title': 'Sprachwechsel',
    'languagePrompt.message': 'Wir haben festgestellt, dass Ihre Systemsprache {language} ist. Möchten Sie die Sprache der Website ändern?',
    'languagePrompt.accept': 'Ja, Sprache ändern',
    'languagePrompt.decline': 'Nein, aktuelle Sprache beibehalten',
    'nav.home': 'Startseite',
    'nav.services': 'Dienstleistungen',
    'nav.aboutUs': 'Über uns',
    'nav.workWithUs': 'Arbeiten Sie mit uns',
    'nav.disclaimer': 'Haftungsausschluss',
    'nav.contact': 'Kontaktieren Sie uns',
    'nav.contactButton': 'Kontakt',
    'contact.metaTitle': 'Kontaktieren Sie uns - Pegoraro Übersetzungen',
    'contact.metaDescription': 'Kontaktieren Sie uns für Fragen oder Angebote zu unseren Übersetzungsdienstleistungen.',
    'contact.metaKeywords': 'Kontakte, Übersetzungen, Übersetzungsdienstleistungen',
    'contact.title': 'Haben Sie Fragen? Kontaktieren Sie uns',
    'contact.description': 'Unser Team ist bereit, Ihnen zu helfen. Wir unterstützen Sie in jeder Phase Ihres Übersetzungsprojekts.',
    'contact.namePlaceholder': 'Ihr Name / Unternehmen',
    'contact.emailPlaceholder': 'Ihre E-Mail',
    'contact.messagePlaceholder': 'Ihre Nachricht',
    'contact.sendButton': 'Nachricht senden',
    'footer.shortDescription': 'Experten für muttersprachliche Übersetzungen seit 1990. Professionalität, Präzision und Qualität im Dienste Ihres globalen Geschäfts.',
    'footer.services': 'Dienstleistungen',
    'footer.aboutUs': 'Über uns',
    'footer.contacts': 'Kontakte',
    'footer.workWithUs': 'Arbeiten Sie mit uns',
    'footer.copyright': '© PegoraroTraduzioni Srl, {year}',
    'footer.vatNumber': 'USt-IdNr.: {vat}',
    'footer.termsAndConditions': 'Allgemeine Geschäftsbedingungen',
    'footer.privacyPolicy': 'Datenschutzrichtlinie',
    'footer.cookiePolicy': 'Cookie-Richtlinie',
    'footer.poweredBy': 'Powered by {company}',
    "services.metaTitle": "Unsere Dienstleistungen - Pegoraro Übersetzungen",
    "services.metaDescription": "Entdecken Sie alle unsere professionellen Übersetzungsdienstleistungen für Ihr Unternehmen.",
    "services.metaKeywords": "Übersetzungen, Übersetzungsdienstleistungen, professionelle Übersetzungen",
    "services.title": "Unsere Dienstleistungen",
    "services.description": "Professionelle Übersetzungen für Ihr Unternehmen. Wir bringen Ihre Botschaft mit Präzision und Authentizität in die Welt.",
    "services.quoteButton": "Angebot anfordern",
    "services.whyChooseUs": "WARUM PEGORARO ÜBERSETZUNGEN WÄHLEN?",
    "serviceDetail.notFound.title": "Dienstleistung nicht gefunden - Pegoraro Übersetzungen",
    "serviceDetail.notFound.description": "404 Fehlerseite, Dienstleistung nicht gefunden.",
    "serviceDetail.notFound.message": "Dienstleistung nicht gefunden",
    "serviceDetail.metaTitle": "{service} - Pegoraro Übersetzungen",
    "serviceDetail.metaKeywords": "Übersetzungen",
    "serviceDetail.home": "Startseite",
    "serviceDetail.quoteButton": "Angebot anfordern",
    "serviceDetail.ourServices": "Unsere {service} Dienstleistungen",
    "serviceDetail.whyChooseUs": "WARUM PEGORARO ÜBERSETZUNGEN WÄHLEN?",
    "whyUs.experience.title": "30 Jahre Erfahrung",
    "whyUs.experience.description": "Drei Jahrzehnte Erfahrung machen uns zu Marktführern in der Übersetzungsbranche.",
    "whyUs.nativeSpeakers.title": "Muttersprachliche Übersetzer",
    "whyUs.nativeSpeakers.description": "Wir arbeiten nur mit muttersprachlichen Übersetzern zusammen, um höchste Qualität zu gewährleisten.",
    "whyUs.specialization.title": "Branchenspezialisierung",
    "whyUs.specialization.description": "Jeder Übersetzer ist auf seinen eigenen Sektor spezialisiert und gewährleistet so Fachwissen und Präzision.",
    "whyUs.innovation.title": "Innovation und Technologie",
    "whyUs.innovation.description": "Wir nutzen die neuesten Technologien, um den Übersetzungsprozess zu optimieren und einwandfreie Ergebnisse zu garantieren.",
    "whyUs.ctaButton": "Angebot anfordern",
    "ctaFinal.title": "Haben Sie Fragen? Kontaktieren Sie uns",
    "ctaFinal.description": "Unser Team ist bereit, Ihnen zu helfen. Wir sind hier, um Sie in jeder Phase Ihres Übersetzungsprojekts zu unterstützen.",
    "ctaFinal.buttonText": "Sprechen Sie mit uns",
    "notFound.metaTitle": "Seite nicht gefunden - Pegoraro Übersetzungen",
    "notFound.metaDescription": "Seite nicht gefunden. Kehren Sie zur Startseite zurück, um unsere Übersetzungsdienste zu erkunden.",
    "notFound.message": "Hoppla! Seite nicht gefunden",
    "notFound.homeButton": "Zurück zur Startseite",
    "disclaimer.metaTitle": "Haftungsausschluss - Pegoraro Übersetzungen",
    "disclaimer.metaDescription": "Lesen Sie unseren Haftungsausschluss für rechtliche Informationen und Nutzungsbedingungen der Website.",
    "disclaimer.metaKeywords": "Haftungsausschluss, Nutzungsbedingungen, rechtliche Informationen",
    "disclaimer.title": "Haftungsausschluss",
    "disclaimer.description": "Diese Seite beschreibt, wie die Website in Bezug auf die Verarbeitung personenbezogener Daten der Nutzer, die sie konsultieren, verwaltet wird.",
    "disclaimer.termsOfService": "Nutzungsbedingungen",
    "disclaimer.privacyPolicy": "Datenschutzerklärung",
    "disclaimer.cookiePolicy": "Cookie-Richtlinie",
    "disclaimer.sectionTitle": "HAFTUNGSAUSSCHLUSS",
    "disclaimer.termsOfServiceContent": "...", // Füge hier den vollständigen Inhalt auf Deutsch hinzu
    "disclaimer.privacyPolicyContent": "...", // Füge hier den vollständigen Inhalt auf Deutsch hinzu
    "disclaimer.cookiePolicyContent": "...", // Füge hier den vollständigen Inhalt auf Deutsch hinzu
    "home.metaTitle": "Pegoraro Übersetzungen - Startseite",
    "home.metaDescription": "Willkommen bei Pegoraro Übersetzungen, Ihrem Partner für professionelle und präzise Übersetzungen.",
    "home.metaKeywords": "Übersetzungen, Übersetzungsdienste, professionelle Übersetzungen",
    "home.heroDescription": "Unsere muttersprachlichen Übersetzer verwandeln Ihre Kommunikation und garantieren Präzision und Authentizität in jedem Wort.",
    "home.quoteButton": "Angebot anfordern",
    "home.servicesButton": "Entdecken Sie unsere Dienstleistungen",
    "home.ourServices": "UNSERE DIENSTLEISTUNGEN",
    "home.servicesTitle": "Präzisionsübersetzungen, die Ihr Unternehmen mit der Welt verbinden",
    "home.servicesDescription": "Wir bieten hochwertige Übersetzungsdienste, um all Ihre Bedürfnisse zu erfüllen, und kombinieren dabei die Expertise unserer muttersprachlichen Übersetzer mit modernsten Technologien:",
    "home.discoverAllServices": "Alle Dienstleistungen entdecken",
    "home.whyChooseUs": "WARUM PEGORARO ÜBERSETZUNGEN WÄHLEN?",
    "home.aboutUs": "ÜBER UNS",
    "home.aboutUsTitle": "Leidenschaft, Erfahrung und Professionalität.",
    "home.aboutUsDescription": "Pegoraro Übersetzungen wurde 1990 mit dem Ziel gegründet, Übersetzungen von höchster Qualität anzubieten. Unsere Mission ist es, genaue und professionelle Übersetzungen zu liefern und dabei Werte wie Ethik und Transparenz zu respektieren. Wir sind ein Team von muttersprachlichen Übersetzern mit einem personalisierten Ansatz für jedes Projekt und garantieren Ergebnisse, die die Erwartungen unserer Kunden übertreffen. Wir sind weltweit präsent und bereit, Sie zu unterstützen, wo immer Sie sich befinden.",
    "home.contactUsButton": "Kontaktieren Sie uns",
    "home.discoverAboutUsButton": "Erfahren Sie mehr über uns",
    "realiAnimation.title": "Seit 30 Jahren",
    "realiAnimation.subtitle": "Muttersprachliche Übersetzungen",
    "about.metaTitle": "Über Uns - Pegoraro Übersetzungen",
    "about.metaDescription": "Entdecken Sie Pegoraro Übersetzungen: Experten für hochwertige muttersprachliche Übersetzungen seit 1990. Professionelle Dienstleistungen für das internationale Wachstum Ihres Unternehmens.",
    "about.metaKeywords": "Übersetzungen, Muttersprachler, Qualität, Sprachdienstleistungen, Internationalisierung",
    "about.section1.title": "Erstklassige muttersprachliche Übersetzungen",
    "about.section1.text": "Was Pegoraro Übersetzungen auszeichnet, ist unser Engagement für den ausschließlichen Einsatz von muttersprachlichen Übersetzern. Diese strategische Entscheidung gewährleistet eine Genauigkeit und Treue, die nur wenige andere erreichen können. Unsere weltweit verteilten Übersetzer sind bereit, Sie überall zu unterstützen und einen tadellosen Service auf globaler Ebene zu garantieren.",
    "about.section2.title": "Ein Partner für das internationale Wachstum Ihres Unternehmens",
    "about.section2.text": "Seit über 30 Jahren unterstützen wir Unternehmen bei ihrer Expansion in neue Märkte. Ob Großunternehmen oder kleiner Betrieb, unsere technischen, medizinischen, juristischen und kommerziellen Übersetzungen sind darauf ausgelegt, neue Geschäftsmöglichkeiten zu eröffnen und die internationale Kommunikation zu erleichtern.",
    "about.section3.title": "Investition in kontinuierliche Weiterbildung",
    "about.section3.text": "Wir investieren ständig in die Weiterbildung unserer Übersetzer und stellen sicher, dass sie immer mit den neuesten Übersetzungstechnologien und -methoden vertraut sind. Dieses Engagement spiegelt sich in der Qualität unserer Übersetzungen und in der Zufriedenheit unserer Kunden wider.",
    "about.section4.title": "Ein globaler und zuverlässiger Service",
    "about.section4.text": "Mit unserer internationalen Präsenz ist Pegoraro Übersetzungen in der Lage, Ihnen überall sprachliche Unterstützung zu bieten. Unser Netzwerk von muttersprachlichen Übersetzern deckt jede Zeitzone ab, so dass wir schnell auf Ihre Bedürfnisse reagieren und kurze Lieferzeiten garantieren können.",
    "work.metaTitle": "Arbeiten Sie mit uns - Pegoraro Übersetzungen",
    "work.metaDescription": "Werden Sie Teil unseres Teams professioneller Übersetzer. Jobmöglichkeiten und Wachstum in der Übersetzungsbranche.",
    "work.title": "Arbeiten Sie mit uns",
    "work.description": "Unsere Philosophie ist einfach: Wir schaffen ein Team aus vielfältigen und leidenschaftlichen Menschen, die das Beste aus unserer Arbeit herausholen.",
    "work.applyAsTranslator": "Bewerben Sie sich als Übersetzer",
    "work.viewOpenPositions": "Offene Stellen anzeigen",
    "work.openPositions": "OFFENE STELLEN",
    "work.careerTitle": "Bauen Sie eine erfüllende Karriere in der Übersetzungsbranche auf",
    "work.careerDescription": "Werden Sie Teil unseres Teams! Wir bieten ein anregendes und lohnendes Arbeitsumfeld, ein Team von Fachleuten und eine breite Palette von Wachstums- und Entwicklungsmöglichkeiten.",
    "work.jobTitle": "Übersetzer",
    "work.jobCategory": "ÜBERSETZUNG UND LOKALISIERUNG",
    "work.jobDescription1": "Muttersprachliche Übersetzer sind für unsere Agentur von grundlegender Bedeutung: Wir suchen ständig nach Fachleuten und neuen Talenten, die sich auf verschiedene Bereiche spezialisiert haben.",
    "work.jobDescription2": "Wenn Sie ein leidenschaftlicher freiberuflicher Übersetzer mit einem tiefen Verständnis für computergestützte Übersetzungstools und die Herausforderungen der Übersetzung und Lokalisierung sind, könnten Sie der Richtige für uns sein! Werden Sie Teil unseres Teams muttersprachlicher Übersetzer und arbeiten Sie an spannenden und abwechslungsreichen Projekten, in Zusammenarbeit mit Unternehmen aus verschiedenen Branchen weltweit.",
    "work.jobType": "Freiberuflich",
    "work.hideButton": "Ausblenden",
    "work.readButton": "Stellenanzeige lesen",
    "work.applyButton": "Bewerben",
    "work.application": "Bewerbung",
    "work.lookingForTalent": "Wir suchen nach Talenten",
    "work.applicationDescription": "Senden Sie Ihre Bewerbung, um mit uns zu arbeiten. Wir suchen junge Talente, erfahrene Fachleute und Branchenspezialisten.",
    "work.firstName": "Vorname",
    "work.lastName": "Nachname",
    "work.email": "E-Mail",
    "work.phone": "Telefon (Optional)",
    "work.message": "Nachricht",
    "work.submitApplication": "Bewerbung absenden",
    "quote.description":"Fordern Sie noch heute Ihr kostenloses Angebot für präzise und professionelle Übersetzungen an!",
    'quote.namePlaceholder': 'Ihr Name / Unternehmen',
    'quote.emailPlaceholder': 'Ihre E-Mail',
    'quote.messagePlaceholder': 'Ihre Nachricht',
    'quote.servicePlaceholder': 'Wählen Sie den Service',
    'quote.sendButton' : 'Anfrage senden',
    'hero.title': 'Leidenschaft, Erfahrung und Professionalität',
    'hero.founded': 'Gegründet 1990',
    'hero.description1': 'PegoraroTranslations wurde mit dem Ziel gegründet, Übersetzungen von höchster Qualität anzubieten.',
    'hero.description2': 'Wir sind ein Team von muttersprachlichen Übersetzern mit einem personalisierten Ansatz für jedes Projekt und garantieren Ergebnisse, die die Erwartungen unserer Kunden übertreffen.',
    'quote.successMessage': 'Ihre Anfrage wurde erfolgreich gesendet!',
    'quote.errorMessage': 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.',
    "job.category.interpreter": "Dolmetscher",
    "job.category.translator": "Übersetzer",
    "job.category.proofreader": "Korrekturleser",
    "job.category.project-manager": "Projektmanager",
    "job.type.full-time": "Vollzeit",
    "job.type.part-time": "Teilzeit",
    "job.type.freelance": "Freiberuflich",
  },
  fr: {
    languageName: 'Français',
    'languagePrompt.title': 'Changement de langue',
    'languagePrompt.message': 'Nous avons détecté que la langue de votre système est {language}. Voulez-vous changer la langue du site ?',
    'languagePrompt.accept': 'Oui, changer la langue',
    'languagePrompt.decline': 'Non, conserver la langue actuelle',
    'nav.home': 'Accueil',
    'nav.services': 'Services',
    'nav.aboutUs': 'Qui sommes-nous',
    'nav.workWithUs': 'Travaillez avec nous',
    'nav.disclaimer': 'Avis de non-responsabilité',
    'nav.contact': 'Contactez-nous',
    'nav.contactButton': 'Contact',
    'footer.shortDescription': 'Experts en traductions natives depuis 1990. Professionnalisme, précision et qualité au service de votre entreprise mondiale.',
    'footer.services': 'Services',
    'footer.aboutUs': 'Qui sommes-nous',
    'footer.contacts': 'Contacts',
    'footer.workWithUs': 'Travaillez avec nous',
    'footer.copyright': '© PegoraroTraduzioni Srl, {year}',
    'footer.vatNumber': 'N° TVA : {vat}',
    'footer.termsAndConditions': 'Conditions générales',
    'footer.privacyPolicy': 'Politique de confidentialité',
    'footer.cookiePolicy': 'Politique des cookies',
    'footer.poweredBy': 'Propulsé par {company}',
    'contact.title': 'Contactez-nous',
    'contact.metaTitle': 'Contactez-nous - Traductions Pegoraro',
    'contact.metaDescription': 'Contactez-nous pour toute question ou demande de devis pour nos services de traduction.',
    'contact.metaKeywords': 'contacts, traductions, services de traduction',
    'contact.title': 'Vous avez des questions ? Contactez-nous',
    'contact.description': 'Notre équipe est prête à vous aider. Nous sommes là pour vous soutenir à chaque étape de votre projet de traduction.',
    'contact.namePlaceholder': 'Votre Nom / Société',
    'contact.emailPlaceholder': 'Votre Email',
    'contact.messagePlaceholder': 'Votre Message',
    'contact.sendButton': 'Envoyer le message',
    "services.metaTitle": "Nos Services - Traductions Pegoraro",
    "services.metaDescription": "Découvrez tous nos services de traduction professionnelle pour votre entreprise.",
    "services.metaKeywords": "traductions, services de traduction, traductions professionnelles",
    "services.title": "Nos Services",
    "services.description": "Traductions Professionnelles pour Votre Entreprise. Nous transmettons votre message au monde avec précision et authenticité.",
    "services.quoteButton": "Demander un devis",
    "services.whyChooseUs": "POURQUOI CHOISIR PEGORARO TRADUCTIONS ?",
    "serviceDetail.notFound.title": "Service non trouvé - Traductions Pegoraro",
    "serviceDetail.notFound.description": "Page d'erreur 404, service non trouvé.",
    "serviceDetail.notFound.message": "Service non trouvé",
    "serviceDetail.metaTitle": "{service} - Traductions Pegoraro",
    "serviceDetail.metaKeywords": "traductions",
    "serviceDetail.home": "Accueil",
    "serviceDetail.quoteButton": "Demander un devis",
    "serviceDetail.ourServices": "Nos Services de {service}",
    "serviceDetail.whyChooseUs": "POURQUOI CHOISIR PEGORARO TRADUCTIONS ?",
    "whyUs.experience.title": "30 Ans d'Expérience",
    "whyUs.experience.description": "Trois décennies d'expérience font de nous des leaders dans l'industrie de la traduction.",
    "whyUs.nativeSpeakers.title": "Traducteurs Natifs",
    "whyUs.nativeSpeakers.description": "Nous collaborons uniquement avec des traducteurs natifs pour garantir la plus haute qualité.",
    "whyUs.specialization.title": "Spécialisation Sectorielle",
    "whyUs.specialization.description": "Chaque traducteur est spécialisé dans son propre secteur, assurant expertise et précision.",
    "whyUs.innovation.title": "Innovation et Technologie",
    "whyUs.innovation.description": "Nous utilisons les dernières technologies pour optimiser le processus de traduction et garantir des résultats impeccables.",
    "whyUs.ctaButton": "Demander un Devis",
    "ctaFinal.title": "Vous avez des questions ? Contactez-nous",
    "ctaFinal.description": "Notre équipe est prête à vous aider. Nous sommes là pour vous soutenir à chaque étape de votre projet de traduction.",
    "ctaFinal.buttonText": "Parlez-nous",
    "notFound.metaTitle": "Page non trouvée - Traductions Pegoraro",
    "notFound.metaDescription": "Page non trouvée. Retournez à la page d'accueil pour explorer nos services de traduction.",
    "notFound.message": "Oups ! Page Non Trouvée",
    "notFound.homeButton": "Retour à l'Accueil",
    "disclaimer.metaTitle": "Avis de non-responsabilité - Traductions Pegoraro",
    "disclaimer.metaDescription": "Lisez notre avis de non-responsabilité pour les informations légales et les conditions d'utilisation du site.",
    "disclaimer.metaKeywords": "avis de non-responsabilité, conditions d'utilisation, informations légales",
    "disclaimer.title": "Avis de non-responsabilité",
    "disclaimer.description": "Cette page décrit comment le site est géré en ce qui concerne le traitement des données personnelles des utilisateurs qui le consultent.",
    "disclaimer.termsOfService": "Conditions d'utilisation",
    "disclaimer.privacyPolicy": "Politique de confidentialité",
    "disclaimer.cookiePolicy": "Politique des cookies",
    "disclaimer.sectionTitle": "AVIS DE NON-RESPONSABILITÉ",
    "disclaimer.termsOfServiceContent": "...", // Ajoutez ici le contenu complet en français
    "disclaimer.privacyPolicyContent": "...", // Ajoutez ici le contenu complet en français
    "disclaimer.cookiePolicyContent": "...", // Ajoutez ici le contenu complet en français
    "home.metaTitle": "Pegoraro Traductions - Accueil",
    "home.metaDescription": "Bienvenue chez Pegoraro Traductions, votre partenaire pour des traductions professionnelles et précises.",
    "home.metaKeywords": "traductions, services de traduction, traductions professionnelles",
    "home.heroDescription": "Nos traducteurs natifs transforment vos communications, garantissant précision et authenticité dans chaque mot.",
    "home.quoteButton": "Demander un devis",
    "home.servicesButton": "Découvrez nos services",
    "home.ourServices": "NOS SERVICES",
    "home.servicesTitle": "Traductions de précision, connectant votre entreprise au monde",
    "home.servicesDescription": "Nous offrons des services de traduction de haute qualité pour répondre à tous vos besoins, en combinant l'expertise de nos traducteurs natifs avec les technologies les plus avancées :",
    "home.discoverAllServices": "Découvrir tous les services",
    "home.whyChooseUs": "POURQUOI CHOISIR PEGORARO TRADUCTIONS ?",
    "home.aboutUs": "QUI SOMMES-NOUS",
    "home.aboutUsTitle": "Passion, expérience et professionnalisme.",
    "home.aboutUsDescription": "Pegoraro Traductions a été fondée en 1990 dans le but d'offrir des traductions de la plus haute qualité. Notre mission est de fournir des traductions précises et professionnelles, en respectant les valeurs d'éthique et de transparence. Nous sommes une équipe de traducteurs natifs avec une approche personnalisée pour chaque projet, garantissant des résultats qui dépassent les attentes de nos clients. Nous sommes présents dans le monde entier, prêts à vous soutenir où que vous soyez.",
    "home.contactUsButton": "Contactez-nous",
    "home.discoverAboutUsButton": "Découvrez qui nous sommes",
    "realiAnimation.title": "Depuis 30 Ans",
    "realiAnimation.subtitle": "Traductions en Langue Maternelle",
    "about.metaTitle": "À Propos de Nous - Pegoraro Traductions",
    "about.metaDescription": "Découvrez Pegoraro Traductions : experts en traductions de haute qualité par des locuteurs natifs depuis 1990. Services professionnels pour la croissance internationale de votre entreprise.",
    "about.metaKeywords": "traductions, locuteurs natifs, qualité, services linguistiques, internationalisation",
    "about.section1.title": "Traductions de Qualité Supérieure par des Locuteurs Natifs",
    "about.section1.text": "Ce qui distingue Pegoraro Traductions, c'est notre engagement à utiliser exclusivement des traducteurs de langue maternelle. Ce choix stratégique assure un niveau de fidélité et de précision que peu d'autres peuvent égaler. Nos traducteurs, situés dans le monde entier, sont prêts à vous soutenir où que vous soyez, garantissant un service impeccable à l'échelle mondiale.",
    "about.section2.title": "Un Partenaire pour la Croissance Internationale de Votre Entreprise",
    "about.section2.text": "Nous soutenons les entreprises depuis plus de 30 ans dans leur expansion vers de nouveaux marchés. Que vous soyez une grande entreprise ou une petite entreprise, nos traductions techniques, médicales, juridiques et commerciales sont conçues pour ouvrir de nouvelles opportunités commerciales et faciliter la communication internationale.",
    "about.section3.title": "Investissement dans la Formation Continue",
    "about.section3.text": "Nous investissons constamment dans la formation de nos traducteurs, en veillant à ce qu'ils soient toujours à jour avec les dernières technologies et méthodologies de traduction. Cet engagement se reflète dans la qualité de nos traductions et dans la satisfaction de nos clients.",
    "about.section4.title": "Un Service Global et Fiable",
    "about.section4.text": "Avec une présence internationale, Pegoraro Traductions est en mesure d'offrir un support linguistique où que vous soyez. Notre réseau de traducteurs de langue maternelle couvre tous les fuseaux horaires, nous permettant de répondre rapidement à vos besoins et de garantir des délais de livraison rapides.",
    "work.metaTitle": "Travaillez avec Nous - Pegoraro Traductions",
    "work.metaDescription": "Rejoignez notre équipe de traducteurs professionnels. Opportunités d'emploi et de croissance dans l'industrie de la traduction.",
    "work.title": "Travaillez avec Nous",
    "work.description": "Notre philosophie est simple : créer une équipe de personnes diverses et passionnées qui valorisent au mieux notre travail.",
    "work.applyAsTranslator": "Postuler comme traducteur",
    "work.viewOpenPositions": "Voir les postes ouverts",
    "work.openPositions": "POSTES OUVERTS",
    "work.careerTitle": "Construisez une carrière enrichissante dans l'industrie de la traduction",
    "work.careerDescription": "Rejoignez notre équipe ! Nous offrons un environnement de travail stimulant et gratifiant, une équipe de professionnels experts et un large éventail d'opportunités de croissance et de développement.",
    "work.jobTitle": "Traducteur",
    "work.jobCategory": "TRADUCTION ET LOCALISATION",
    "work.jobDescription1": "Les traducteurs natifs sont fondamentaux pour notre agence : nous recherchons constamment des professionnels et de nouveaux talents spécialisés dans divers domaines.",
    "work.jobDescription2": "Si vous êtes un traducteur freelance passionné avec une connaissance approfondie des outils de traduction assistée par ordinateur et des défis de la traduction et de la localisation, vous pourriez être la personne qu'il nous faut ! Rejoignez notre équipe de traducteurs natifs et vous pourrez travailler sur des projets stimulants et variés, en collaboration avec des entités de différents secteurs dans le monde entier.",
    "work.jobType": "Freelance",
    "work.hideButton": "Masquer",
    "work.readButton": "Lire l'annonce",
    "work.applyButton": "Postuler",
    "work.application": "Candidature",
    "work.lookingForTalent": "Nous recherchons des talents",
    "work.applicationDescription": "Envoyez votre candidature pour travailler avec nous. Nous recherchons de jeunes talents, des professionnels expérimentés et des spécialistes de l'industrie.",
    "work.firstName": "Prénom",
    "work.lastName": "Nom",
    "work.email": "Email",
    "work.phone": "Téléphone (Optionnel)",
    "work.message": "Message",
    "work.submitApplication": "Envoyer la candidature",
    'hero.title': 'Passion, Expérience et Professionnalisme',
    'hero.founded': 'Fondée en 1990',
    'hero.description1': "PegoraroTranslations est née dans le but d'offrir des traductions de la plus haute qualité.",
    'hero.description2': 'Nous sommes une équipe de traducteurs natifs avec une approche personnalisée pour chaque projet, garantissant des résultats qui dépassent les attentes de nos clients.',
    'quote.successMessage': 'Votre demande a été envoyée avec succès !',
    'quote.errorMessage': "Une erreur s\'est produite. Veuillez réessayer.",
    "job.category.interpreter": "Interprète",
    "job.category.translator": "Traducteur",
    "job.category.proofreader": "Relecteur",
    "job.category.project-manager": "Chef de projet",
    "job.type.full-time": "Temps plein",
    "job.type.part-time": "Temps partiel",
    "job.type.freelance": "Freelance",
  },
};


// Object.keys(translations).forEach(lang => {
//   translations[lang] = {
//     ...translations[lang],
//     "work.fileUpload": {
//       it: "Carica il tuo CV",
//       en: "Upload your CV",
//       de: "Lebenslauf hochladen",
//       fr: "Télécharger votre CV",
//     }[lang],
//     "work.formSuccess": {
//       it: "La tua candidatura è stata inviata con successo!",
//       en: "Your application has been successfully submitted!",
//       de: "Ihre Bewerbung wurde erfolgreich eingereicht!",
//       fr: "Votre candidature a été soumise avec succès !",
//     }[lang],
//     "work.formError": {
//       it: "Si è verificato un errore. Per favore, riprova.",
//       en: "An error occurred. Please try again.",
//       de: "Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.",
//       fr: "Une erreur s'est produite. Veuillez réessayer.",
//     }[lang],
//   };
// });