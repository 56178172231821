import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import WhyUs from "../components/WhyUs"
import Cta from "../components/CtaFinal"
import { motion } from 'framer-motion';
import './Skeleton.scss'
import { servicesData } from '../data/servicesData';
import MetaTags from '../components/MetaTags';
import { useLanguage, routeMap } from '../hooks/useLanguage';
import { CallMade } from '@mui/icons-material';
import Preventivo from "../components/Preventivo"
const Servizi = () => {
  const [language] = useLanguage();
  const intl = useIntl();

  return (
    <>
      <MetaTags
        title={intl.formatMessage({ id: "services.metaTitle" })}
        description={intl.formatMessage({ id: "services.metaDescription" })}
        keywords={intl.formatMessage({ id: "services.metaKeywords" })}
      />
      <body>
        
        <section className='pegoraro-hero-section'  > 
          <div className="spinner-container desktop-remove">
          <div className="spinner"></div>
        </div>

          
          {/* <img  className='pegoraro-hero-section-img'   src="/media/shape.png"  /> */}
        <section className='hero mt-300 dashed-bottom'>
          {/* FRAMER MOTION: 1 BOUNCE IN */}
          {/* <motion.div 
        className='hero--container'
        initial={{ y: -100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ type: 'spring', stiffness: 100 }}
      > */}

      <div        className='hero--container' >
      <h1><FormattedMessage id="services.title" /></h1>
        <p className='p-mobile p-desc-hero'><FormattedMessage id="services.description" /></p>
        <div className='hero--buttons'>
        <Preventivo />
        </div>

      </div>
       
      {/* </motion.div> */}
{/* FRAMER MOTION: 2 GIRO A MEZZA LUNA + da piccolo in grande */}
{/* <motion.div
       
    
        initial={{ scale: 0, rotate: 5 }}
        animate={{ scale: 1, rotate: 0 }}
        transition={{ type: 'spring', stiffness: 100 }}
      > */}
          <img className='img--hero- mobile-remove' src="/media/shape3.png"  />
          {/* </motion.div> */}
          <div>


          </div>
        </section>

        </section>
   
     
        <div className="servizi-cards ">
          {/* FRAMER MOTION: 3 UNA CARD DOPO L'ALTRA */}
          {servicesData.map((card, index) => (
            
            <Link 
              to={`/${language}/${routeMap[language].services}/${card.urls[language]}`} 
              key={card.id} 
              className='card'
            >
               {/* <motion.div
          key={card.id}
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: index * 0.2 }}
          
        > */}
        <CallMade className="hover-arrow mobile-remove"  />
              <div className='icon'>{card.icon}</div>
              <div>
                <span>{card[language].service}</span>
                <p>{card[language].descriptionCard}</p>
                <p>{card[language].subtitle}</p>
              </div>
              {/* </motion.div> */}
            </Link>
          ))}
        </div>

        <div style={{ marginTop: '50px'}} className='dashed-bottom'  >

        </div>
        {/* <section className='content--container content--container-img'>
                    <div>
                        <span>I NOSTRI SERVIZI</span>
                        <h2>Il Nostro Impegno per l'Eccellenza</h2>
                        <p>
                        In Pegoraro Traduzioni, ci impegniamo a fornire traduzioni di altissima qualità. Il nostro team di traduttori madrelingua e specialisti del settore lavora con passione e precisione per garantire che ogni progetto sia un successo. Da oltre 30 anni, aiutiamo aziende di tutto il mondo a superare le barriere linguistiche, investendo continuamente nella formazione dei nostri professionisti e utilizzando le tecnologie più avanzate.
                        </p>
                        <div className='content--container--buttons'>
                      
                            <button className='button-light'><FormattedMessage id="home.contactUsButton" /></button>
                        </div>
                    </div>
                    <img className='image-desktop ' src="/media/servizi.png" alt="pegoraro-servizi" />
                    <img className='image-mobile' src="/media/servizi-mobile.png" alt="pegoraro-servizi" /> 
                </section> */}
<div className='margin-desktop'  >

<section className='content--container'>
          <span><FormattedMessage id="services.whyChooseUs" /></span>
        </section>
        <WhyUs />
        <Cta />
</div>

    
      </body>
    </>
  );
};

export default Servizi;