import { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { servicesData } from '../data/servicesData';

export const languages = ['it', 'en', 'de', 'fr'];
export const defaultLanguage = 'en';

export const routeMap = {
  it: { 
    home: 'home', 
    contact: 'contattaci', 
    services: 'servizi', 
    disclaimer: 'disclaimer', 
    about: 'chi-siamo',
    workWithUs: 'lavora-con-noi'
  },
  en: { 
    home: 'home', 
    contact: 'contact-us', 
    services: 'services', 
    disclaimer: 'disclaimer', 
    about: 'about-us',
    workWithUs: 'work-with-us'
  },
  de: { 
    home: 'startseite', 
    contact: 'kontaktieren-sie-uns', 
    services: 'dienstleistungen', 
    disclaimer: 'haftungsausschluss', 
    about: 'uber-uns',
    workWithUs: 'arbeiten-sie-mit-uns'
  },
  fr: { 
    home: 'accueil', 
    contact: 'contactez-nous', 
    services: 'services', 
    disclaimer: 'avis-de-non-responsabilite', 
    about: 'a-propos',
    workWithUs: 'travaillez-avec-nous'
  }
};
  
export function useLanguage() {
  const location = useLocation();
  const navigate = useNavigate();
  const [language, setLanguage] = useState(defaultLanguage);
  const [showLanguagePrompt, setShowLanguagePrompt] = useState(false);
  const [isManualChange, setIsManualChange] = useState(false);
  const initialLoadRef = useRef(true);

  const pathname = location.pathname;

  useEffect(() => {
    const path = pathname.split('/');
    const langFromUrl = path[1];
    const browserLang = navigator.language.split('-')[0];
    
    if (languages.includes(langFromUrl)) {
      setLanguage(langFromUrl);
      if (initialLoadRef.current && langFromUrl !== browserLang && languages.includes(browserLang)) {
        const preference = localStorage.getItem(`lang_preference_${browserLang}_${langFromUrl}`);
        if (preference !== 'declined') {
          setShowLanguagePrompt(true);
        }
      }
    } else {
      const newLang = languages.includes(browserLang) ? browserLang : defaultLanguage;
      setLanguage(newLang);
    }
    initialLoadRef.current = false;
  }, [pathname]);

  const changeLanguage = useCallback((newLang) => {
    console.log('changeLanguage called with:', newLang);
    
    if (!languages.includes(newLang)) {
      console.error('Invalid language:', newLang);
      return;
    }

    setLanguage(newLang);
    setShowLanguagePrompt(false);
    setIsManualChange(true);

    // Aggiorna l'URL solo se necessario
    const currentPath = location.pathname.split('/').slice(2).join('/');
    let newPath = currentPath;

    // Gestione speciale per i dettagli dei servizi
    const serviceDetailMatch = currentPath.match(new RegExp(`^${routeMap[language].services}/(.+)$`));
    if (serviceDetailMatch) {
      const currentServiceUrl = serviceDetailMatch[1];
      console.log('Current service URL:', currentServiceUrl);
      const service = servicesData.find(s => Object.values(s.urls).includes(currentServiceUrl));
      if (service) {
        newPath = `${routeMap[newLang].services}/${service.urls[newLang]}`;
        console.log('New service path:', newPath);
      }
    } else {
      // Gestione per altre pagine
      for (const [key, value] of Object.entries(routeMap[language])) {
        if (currentPath === value || currentPath.startsWith(value + '/')) {
          newPath = routeMap[newLang][key] + currentPath.slice(value.length);
          console.log('New path for other pages:', newPath);
          break;
        }
      }
    }

    console.log('Final new path after language change:', newPath);
    navigate(`/${newLang}/${newPath}`, { replace: true });
  }, [location.pathname, language, navigate]);

  const declineLanguageChange = useCallback(() => {
    const browserLang = navigator.language.split('-')[0];
    console.log('Declining language change. Current language:', language);
    localStorage.setItem(`lang_preference_${browserLang}_${language}`, 'declined');
    setShowLanguagePrompt(false);
  }, [language]);

  return [language, changeLanguage, showLanguagePrompt, setShowLanguagePrompt, declineLanguageChange, isManualChange];
}