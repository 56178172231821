import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import './Footer.scss';
import { useLanguage, routeMap } from '../hooks/useLanguage';
import LanguageSwitcher from './LanguageSwitcher';
const Footer = () => {
  const [language] = useLanguage();

  return (
    <footer>
      <div className='mt-50'>
        <div className="decorartion-shape">
          
          <img src="/media/shape.png" alt="decoration" />
        </div>
      </div>
      <section>
      <div className='link-and-switcher'  >
        <div>
        <img src="https://utfs.io/f/f0ca3436-7465-4f65-9d79-cf67a6a357a4-mj8qc2.png" className='logo' alt="logo" />
        <p className='short-desc'>
          <FormattedMessage id="footer.shortDescription" />
        </p>
        </div>
        <div  className='footer-switcher'  >
<LanguageSwitcher language={language} />
</div>
</div>

        <div className='link-section'>
          <Link to={`/${language}/${routeMap[language].services}`}>
            <FormattedMessage id="footer.services" />
          </Link>
          <Link to={`/${language}/${routeMap[language].aboutUs}`}>
            <FormattedMessage id="footer.aboutUs" />
          </Link>
          <Link to={`/${language}/${routeMap[language].contact}`}>
            <FormattedMessage id="nav.contact" />
          </Link>
          <Link to={`/${language}/${routeMap[language].workWithUs}`}>
            <FormattedMessage id="footer.workWithUs" />
          </Link>

       
      

</div>
        
        <div className='legal-privacy'  >
        <div className='legal-section'>
          <p>
         GiovannaPegoraro© 2024
          </p>
          <p>
          NIE / USt-IdNr: ES Y4573907A
          </p>
        </div>
        <div className='privacy-link'>
          <p><FormattedMessage id="footer.termsAndConditions" /></p>
          <Link to={`/${language}/${routeMap[language].disclaimer}`}>
          <p><FormattedMessage id="footer.privacyPolicy" /></p>
          </Link>
          <Link to={`/${language}/${routeMap[language].disclaimer}`}>
          <p><FormattedMessage id="footer.cookiePolicy" /></p>
          </Link>
        </div>
        </div>
    

        <hr/>
        <p className="firewall">
          <FormattedMessage id="footer.poweredBy" values={{ company: <span>Digyland S.r.l</span> }} />
        </p>
      </section>
    </footer>
  );
};

export default Footer;
