import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { servicesData } from '../data/servicesData';
import './ServiceDetail.scss';
import WhyUs from "../components/WhyUs";
import CtaFinal from "../components/CtaFinal";
import MetaTags from '../components/MetaTags';
import { useLanguage, routeMap } from '../hooks/useLanguage';
import { motion } from 'framer-motion';
import Preventivo from "../components/Preventivo"
const ServiceDetail = () => {
    const { url } = useParams();
    const [language, changeLanguage] = useLanguage();
    const intl = useIntl();
    const navigate = useNavigate();
    const [service, setService] = useState(null);
    const [loading, setLoading] = useState(true);

    console.log('ServiceDetail rendered. URL:', url, 'Language:', language);


    
    useEffect(() => {
        const findService = () => {
            console.log('Finding service for URL:', url);
            const foundService = servicesData.find((s) => 
                Object.values(s.urls).includes(url)
            );
            console.log('Found service:', foundService);

            if (foundService) {
                setService(foundService);
                const correctLanguage = Object.keys(foundService.urls).find(lang => foundService.urls[lang] === url);
                if (correctLanguage && correctLanguage !== language) {
                    console.log('Language mismatch. Changing language to:', correctLanguage);
                    changeLanguage(correctLanguage);
                }
            } else {
                console.log('Service not found. Redirecting to services page.');
                navigate(`/${language}/${routeMap[language].services}`, { replace: true });
            }
            setLoading(false);
        };

        findService();
    }, [url, language, navigate, changeLanguage]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!service || !service[language]) {
        console.log('Service or language data not available:', service, language);
        return <div>Service not found</div>;
    }

    const serviceData = service[language];
    console.log('Rendering service data:', serviceData);

    return (
        <div className="service-detail">
            <MetaTags
                title={intl.formatMessage({ id: "serviceDetail.metaTitle" }, { service: serviceData.servizio })}
                description={serviceData.description}
                keywords={`${intl.formatMessage({ id: "serviceDetail.metaKeywords" })}, ${serviceData.service.toLowerCase()}, ${serviceData.descriptionCard.toLowerCase()}`}
            />
                <section className='pegoraro-service-section'  >
                <div className="decorartion-shape mobile-remove">
                    <img src="/media/shape.png" alt="decoration" />
                </div> 
            <section className='service-content'>
                <span className='link-to-home'>
                    <Link to={`/${language}/${routeMap[language].home}`}>
                        <FormattedMessage id="serviceDetail.home" />
                    </Link> / {serviceData.servizio}
                </span>
                <h1>{serviceData.servizio}</h1>
                <p>{serviceData.bodysubtitle}</p>
                <Preventivo />
            </section>
</section>

            <div className='mt-50 desktop-remove'>
                <div className="decorartion-shape">
                    <img src="/media/shape.png" alt="decoration" />
                </div>
            </div>
         
            <section className='margin-desktop'   >

            <section className='service-content service-desc-body'>
                <span className='title-uppercase'>{serviceData.servizio}</span>
               <div className='service-desc-icon'   >
               <motion.p 
      className='service-desc'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      {serviceData.body}
    </motion.p>
  

               </div>
              
   

            </section>
            <section className='service-content'>
                <span className='title-uppercase'>
                    <FormattedMessage id="serviceDetail.ourServices" values={{ service: serviceData.servizio }} />
                </span>
                <div className="services">
                {serviceData.servizi.map((subService, index) => (
        <motion.div 
          key={index} 
          className="sub-service"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: index * 0.3 }}
        >
          <h4>{subService.name}</h4>
          <h5>{subService.subtitle}</h5>
          <p>{subService.description}</p>
        </motion.div>
      ))}

      
                </div>
            </section>
            <section className='content--container'>
                <span><FormattedMessage id="serviceDetail.whyChooseUs" /></span>
            </section>
            <WhyUs />
            <CtaFinal />

        </section>
        </div>
    );
};

export default ServiceDetail;