import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import './Contact.scss'
import './Skeleton.scss'
import { CallMade } from '@mui/icons-material';
import MetaTags from '../components/MetaTags';
import { useLanguage } from '../hooks/useLanguage';

const Contact = () => {
    const [language] = useLanguage();
    const intl = useIntl();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitResult, setSubmitResult] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        try {
            const response = await fetch('https://pegoraro-backend.onrender.com/api/send-contact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
            const data = await response.json();
            if (response.ok) {
                setSubmitResult({ success: true, message: intl.formatMessage({ id: 'contact.successMessage' }) });
                setFormData({ name: '', email: '', message: '' });
            } else {
                throw new Error(data.error || 'Errore nell\'invio del form');
            }
        } catch (error) {
            setSubmitResult({ success: false, message: intl.formatMessage({ id: 'contact.errorMessage' }) });
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <>
            <MetaTags
                title={intl.formatMessage({ id: 'contact.metaTitle' })}
                description={intl.formatMessage({ id: 'contact.metaDescription' })}
                keywords={intl.formatMessage({ id: 'contact.metaKeywords' })}
            />
            <div className='contact-page'>
                <section className='hero'>
                    <div className='hero--container'>
                        <h1><FormattedMessage id="contact.title" /></h1>
                        <p><FormattedMessage id="contact.description" /></p>
                        <div className='hero--buttons'>
                            {/* Puoi aggiungere qui i pulsanti se necessario */}
                        </div>
                    </div>
                </section>

                <section className='form--container'>
                    <form onSubmit={handleSubmit}>
                        <div className='name-email'>
                            <input name="name" value={formData.name} onChange={handleInputChange} placeholder={intl.formatMessage({ id: 'contact.namePlaceholder' })} required />
                            <input name="email" type="email" value={formData.email} onChange={handleInputChange} placeholder={intl.formatMessage({ id: 'contact.emailPlaceholder' })} required />
                        </div>
                        <textarea name="message" value={formData.message} onChange={handleInputChange} placeholder={intl.formatMessage({ id: 'contact.messagePlaceholder' })} required />
                        <button className='icon-button' type="submit" disabled={isSubmitting}>
                            <span>
                                {isSubmitting 
                                    ? <FormattedMessage id="contact.sendingButton" />
                                    : <FormattedMessage id="contact.sendButton" />
                                }
                            </span>
                            <CallMade/>
                        </button>
                    </form>
                    {submitResult && (
                        <div className={`submit-result ${submitResult.success ? 'success' : 'error'}`}>
                            {submitResult.message}
                        </div>
                    )}
                </section>
            </div>
        </>
    )
}

export default Contact;