import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import './InfiniteImageCarousel.scss';

const InfiniteImageCarousel = () => {
    const [images, setImages] = useState([]);

    useEffect(() => {
        const generateImageNames = () => {
            const imageCount = 6;
            return Array.from({ length: imageCount }, (_, i) => `slide-${String(i).padStart(2, '0')}.png`);
        };
        setImages(generateImageNames());
    }, []);
    return (
        <div className="infinite-carousel-container">
            <motion.div
                className="infinite-carousel"
                animate={{
                    x: images.length ? [-100 * images.length, 0] : 0,
                }}
                transition={{
                    x: {
                        repeat: Infinity,
                        repeatType: "loop",
                        duration: 20,
                        ease: "linear",
                    },
                }}
            >
                {images.concat(images).map((src, index) => (
                    <img
                        key={index}
                        src={`/media/slider/${src}`}
                        alt={`Slide ${index}`}
                        className="carousel-image"
                    />
                ))}
            </motion.div>
        </div>
    );
};

export default InfiniteImageCarousel;