import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { motion, AnimatePresence } from 'framer-motion';
import { CallMade, Close } from '@mui/icons-material';
import { servicesData } from '../data/servicesData';
import { useLanguage } from '../hooks/useLanguage';
import './Preventivo.scss';

const Preventivo = () => {
    const [language] = useLanguage();
    const intl = useIntl();
    const [isQuoteModal, setIsQuoteModal] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
        service: ''
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitResult, setSubmitResult] = useState(null);

    const toggleModal = () => {
        setIsQuoteModal(!isQuoteModal);
        setSubmitResult(null);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        try {
            const response = await fetch('https://pegoraro-backend.onrender.com/api/send-preventivo', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
            const data = await response.json();
            if (response.ok) {
                setSubmitResult({ success: true, message: intl.formatMessage({ id: 'quote.successMessage' }) });
                setFormData({ name: '', email: '', message: '', service: '' });
            } else {
                throw new Error(data.error || 'Errore nell\'invio del form');
            }
        } catch (error) {
            setSubmitResult({ success: false, message: intl.formatMessage({ id: 'quote.errorMessage' }) });
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <>
            <button className='button-dark cta-button' onClick={toggleModal}>
                <FormattedMessage id="home.quoteButton" />
            </button>

            <AnimatePresence>
                {isQuoteModal && (
                    <motion.div 
                        className="modal-overlay"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        onClick={toggleModal}
                    >
                        <motion.div 
                            className="modal-content"
                            initial={{ y: "-100vh", opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            exit={{ y: "100vh", opacity: 0 }}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <div className="modal-close"  onClick={toggleModal}><Close/></div>
                            <h2><FormattedMessage id="home.quoteButton" /></h2> 
                            <p><FormattedMessage id="ctaFinal.description" /></p>

                            <section className='form--container'>
                                {submitResult ? (
                                    <div className={`submit-result ${submitResult.success ? 'success' : 'error'}`}>
                                        {submitResult.message}
                                    </div>
                                ) : (
                                    <form onSubmit={handleSubmit}>
                                        <div className='name-email'>
                                            <input 
                                                name="name"
                                                value={formData.name}
                                                onChange={handleInputChange}
                                                placeholder={intl.formatMessage({ id: 'quote.namePlaceholder' })} 
                                                required
                                            />
                                            <input 
                                                name="email"
                                                type="email"
                                                value={formData.email}
                                                onChange={handleInputChange}
                                                placeholder={intl.formatMessage({ id: 'quote.emailPlaceholder' })} 
                                                required
                                            />
                                        </div>
                                        <textarea 
                                            name="message"
                                            value={formData.message}
                                            onChange={handleInputChange}
                                            placeholder={intl.formatMessage({ id: 'quote.messagePlaceholder' })} 
                                            required
                                        />
                                        <div className='name-email'>
                                            <select
                                                name="service"
                                                value={formData.service}
                                                onChange={handleInputChange}
                                                required
                                            >
                                                <option value="" disabled>{intl.formatMessage({ id: 'quote.servicePlaceholder' })}</option>
                                                {servicesData.map((card, index) => (
                                                    <option key={index} value={card[language].servizio}>
                                                        {card[language].servizio}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <button className='icon-button' type="submit" disabled={isSubmitting}>
                                            <span>
                                                {isSubmitting 
                                                    ? <FormattedMessage id="quote.sendingButton" />
                                                    : <FormattedMessage id="quote.sendButton" />
                                                }
                                            </span>
                                            <CallMade/>
                                        </button>
                                    </form>
                                )}
                            </section>
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    );
};

export default Preventivo;