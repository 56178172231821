import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { FormattedMessage } from 'react-intl';
import './Navbar.scss';
import LanguageSwitcher from "./LanguageSwitcher"
import { useLanguage, routeMap } from '../hooks/useLanguage';
import { servicesData } from '../data/servicesData'; 

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isServicesHovered, setIsServicesHovered] = useState(false);
  const location = useLocation();
  const [language, changeLanguage] = useLanguage();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLanguageChange = (newLang) => {
    changeLanguage(newLang);
    setIsMenuOpen(false);
  };

  const menuVariants = {
    closed: {
      opacity: 0,
      y: '-100%',
      transition: {
        y: { stiffness: 1000 },
      },
    },
    open: {
      opacity: 1,
      y: 0,
      transition: {
        y: { stiffness: 1000, velocity: -100 },
      },
    },
  };

  const linkVariants = {
    closed: { y: 50, opacity: 0 },
    open: (i) => ({
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 },
        delay: i * 0.1,
      },
    }),
  };

  const closeButtonVariants = {
    hidden: { opacity: 0, scale: 0.5 },
    visible: { opacity: 1, scale: 1 },
  };

  const dropdownVariants = {
    hidden: { 
      opacity: 0, 
      y: -20,
      transition: {
        staggerChildren: 0.05,
        staggerDirection: -1
      }
    },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        staggerChildren: 0.07,
        delayChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0 }
  };

  const navItems = [
    { translationKey: 'nav.home', path: `/${language}/${routeMap[language].home}` },
    { translationKey: 'nav.services', path: `/${language}/${routeMap[language].services}`, hasDropdown: true },
    { translationKey: 'nav.aboutUs', path: `/${language}/${routeMap[language].about}` },
    { translationKey: 'nav.workWithUs', path: `/${language}/${routeMap[language].workWithUs}` },
    { translationKey: 'nav.disclaimer', path: `/${language}/${routeMap[language].disclaimer}` }, // Aggiunto questo
    { translationKey: 'nav.contact', path: `/${language}/${routeMap[language].contact}` },
  ];

  return (
    <header>
      <div className='navbar'>
        <Link to={`/${language}/${routeMap[language].home}`}>
          <img src='https://utfs.io/f/f0ca3436-7465-4f65-9d79-cf67a6a357a4-mj8qc2.png' alt='logo' />
        </Link>
        {console.log(language)}
        <div className={`navbar--option desktop-menu ${(language === "de" || language === "fr" ) ? 'resize-nav-text' : ''}`}>
          {navItems.map((item, index) => (
            <div
              key={item.translationKey}
              onMouseEnter={() => item.hasDropdown && setIsServicesHovered(true)}
              onMouseLeave={() => item.hasDropdown && setIsServicesHovered(false)}
            >
              <Link 
                to={item.path} 
                className={`${location.pathname === item.path ? 'active' : ''} ${index === navItems.length - 1 ? 'last-item' : ''}`}
              >
                <FormattedMessage id={item.translationKey} />
              </Link>
              <AnimatePresence>
                {item.hasDropdown && isServicesHovered && (
                  <motion.div 
                    className="services-dropdown"
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    variants={dropdownVariants}
                  >
                    {servicesData.map((service) => (
                      <motion.div key={service.id} variants={itemVariants}>
                        <Link 
                          to={`/${language}/${routeMap[language].services}/${service.urls[language]}`}
                        >{service.icon}
                          {service[language].service} - {service[language].descriptionCard}
                        </Link>
                      </motion.div>
                    ))}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          ))}
        </div>
     

        {!isMenuOpen && (
          <motion.div className='hamburger' onClick={toggleMenu} whileTap={{ scale: 0.97 }}>
            <span></span>
            <span></span>
            <span></span>
          </motion.div>
        )}
      </div>
      <AnimatePresence>
        {isMenuOpen && (
          <motion.div className={`mobile-menu ${(language === "de" || language === "fr" ) ? 'resize-nav-text' : ''}`} initial='closed' animate='open' exit='closed' variants={menuVariants}>
            <motion.div
              className='close-button'
              onClick={toggleMenu}
              variants={closeButtonVariants}
              initial='hidden'
              animate='visible'
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
            >
              &#x2715;
            </motion.div>
            {navItems.map((item, i) => (
              <motion.div key={item.translationKey} custom={i} variants={linkVariants}>
                <Link 
                  to={item.path} 
                  onClick={toggleMenu} 
                  className={location.pathname === item.path ? 'active' : ''}
                >
                  <FormattedMessage id={item.translationKey} />
                </Link>
              </motion.div>
            ))}
            

    <LanguageSwitcher language={language} changeLanguage={handleLanguageChange} />
          </motion.div>
        )}
      </AnimatePresence>
    </header>
  );
};

export default Navbar;
