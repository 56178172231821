import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLanguage } from '../hooks/useLanguage';

const languages = [
  { text: 'Reali', lang: 'it' },
  { text: 'Real', lang: 'de' },
  { text: '真实的', lang: 'zh' },
  { text: 'Real', lang: 'en' },
  { text: 'Réelles', lang: 'fr' },
];

const TypewriterText = ({ text }) => {
  const [displayText, setDisplayText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (currentIndex < text.length) {
      const timer = setTimeout(() => {
        setDisplayText((prev) => prev + text[currentIndex]);
        setCurrentIndex((prev) => prev + 1);
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [text, currentIndex]);

  return <span>{displayText}</span>;
};

const RealiAnimation = () => {
  const [language] = useLanguage();
  const intl = useIntl();
  const [currentLanguageIndex, setCurrentLanguageIndex] = useState(0);

  useEffect(() => {
    const currentIndex = languages.findIndex(lang => lang.lang === language);
    setCurrentLanguageIndex(currentIndex !== -1 ? currentIndex : 0);
  }, [language]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentLanguageIndex((prev) => (prev + 1) % languages.length);
    }, 3000);
    return () => clearTimeout(timer);
  }, [currentLanguageIndex]);

  return (
    <div style={{
      width: '100%',
      maxWidth: '100vw',
      overflow: 'hidden',
    }}>
      <h1 className='h1-dynamic' style={{
        width: '100%',
        maxWidth: '100%',
        wordWrap: 'break-word',
        overflowWrap: 'break-word',
        hyphens: 'auto',
        whiteSpace: 'normal',
      }}>
        <FormattedMessage id="realiAnimation.title" />
        <br />
        <span className='reali' style={{ 
          display: 'flex', 
          flexWrap: 'wrap',
          alignItems: 'center',
          gap: '5px'
        }}>
          <FormattedMessage id="realiAnimation.subtitle" />
          <div style={{
            height: '1.5em', // Altezza fissa per l'area di animazione
            minWidth: '80px', // Larghezza minima per garantire spazio sufficiente
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}>
            <AnimatePresence mode="wait">
              <motion.div
                className='text-animated'
                key={currentLanguageIndex}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.5 }}
              >
                <TypewriterText text={languages[currentLanguageIndex].text} />
              </motion.div>
            </AnimatePresence>
          </div>
        </span>
      </h1>
    </div>
  );
};

export default RealiAnimation;