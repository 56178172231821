import React from 'react';
import { useLanguage } from '../hooks/useLanguage';
import './LanguageSwitcher.scss';

const LanguageSwitcher = () => {
  const [language, changeLanguage] = useLanguage();

  const languages = [
    { code: 'it', name: 'Italiano' },
    { code: 'en', name: 'English' },
    { code: 'de', name: 'Deutsch' },
    { code: 'fr', name: 'Français' },
  ];

  return (
    <div className="language-switcher mt-50">
      {languages.map((lang) => (
        <button
          key={lang.code}
          onClick={() => changeLanguage(lang.code)}
          className={language === lang.code ? 'active' : ''}
        >
          {lang.name}
        </button>
      ))}
    </div>
  );
};

export default LanguageSwitcher;