import React, { useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import MetaTags from '../components/MetaTags';
import LanguageSwitcher from "../components/LanguageSwitcher";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import InfiniteImageCarousel from '../components/InfiniteImageCarousel';
import WhyUs from "../components/WhyUs";
import CtaFinal from "../components/CtaFinal";
import RealiAnimation from "../components/RealiAnimation";
import { servicesData } from '../data/servicesData';  // Importa servicesData
import { useLanguage, routeMap } from '../hooks/useLanguage';
import './Skeleton.scss';
import { motion } from 'framer-motion';
import { useInView } from '../hooks/useInView';
import Preventivo from "../components/Preventivo"
function Home() {
    const [language] = useLanguage();
    const intl = useIntl();
    const [ref, isInView] = useInView({ threshold: 0.1 });
    const navigate = useNavigate();
    // Prendiamo i primi 5 servizi
    const firstFiveServices = servicesData.slice(0, 5);

    const leftImageRef = useRef(null);
    const rightImageRef = useRef(null);
  
    const handleClick = () => {
        navigate(`/${language}/${routeMap[language].services}`);
      };

      const handleClickAbout = () => {
        navigate(`/${language}/${routeMap[language].about}`);
      };

      const handleClickContact = () => {
        navigate(`/${language}/${routeMap[language].contact}`);
      };

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.2
            }
        }
    };

    const itemVariants = {
        hidden: { y: 20, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1,
            transition: {
                type: 'spring',
                stiffness: 100
            }
        }
    };


    useEffect(() => {
      const handleScroll = () => {
        const scrollY = window.scrollY;
        const rotation = scrollY * 0.1; 
  
        if (leftImageRef.current) {
          leftImageRef.current.style.transform = `rotate(${rotation}deg)`;
        }
        if (rightImageRef.current) {
          rightImageRef.current.style.transform = `rotate(${-rotation}deg)`;
        }
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);


    return (
        <>
            <MetaTags
                title={intl.formatMessage({ id: "home.metaTitle" })}
                description={intl.formatMessage({ id: "home.metaDescription" })}
                keywords={intl.formatMessage({ id: "home.metaKeywords" })}
            />
    {/* <div class="spinner-background desktop-remove">
    <div class="spinner-wrapper">
        <div class="spinner"></div>
    </div>
</div> */}
            <div className="App ">
                <div className='hero-wrapper'>
                    <div className='background-images mobile-remove'>
                        <img  ref={leftImageRef} src="/media/phase2.png" alt="" className='background-image left' />
                        <img  ref={rightImageRef} src="/media/phase2.png" alt="" className='background-image right' />
                    </div>
                    <div className={`hero ${window.innerWidth > 768 ? 'mt-300' : ''}`}>
                        <div className='hero--container'>
                            <RealiAnimation />
                            <p><FormattedMessage id="home.heroDescription" /></p>
                            <div className='hero--buttons'>
                            <Preventivo/>
                                <button  onClick={handleClick} className='button-light'><FormattedMessage id="home.servicesButton" /></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mt-50'>
                    <div className="decorartion-shape">
                        <img src="/media/shape.png" alt="decoration" />
                    </div>
                </div>

                
                <InfiniteImageCarousel />
<div  className='div-center'  >

<LanguageSwitcher />
</div>
     
                <section className='content--container ' ref={ref}>
                <span><FormattedMessage id="home.ourServices" /></span>
                <h2><FormattedMessage id="home.servicesTitle" /></h2>
                <p><FormattedMessage id="home.servicesDescription" /></p>
                <motion.div 
                    className="cards-grid"
                    variants={containerVariants}
                    initial="hidden"
                    animate={isInView ? "visible" : "hidden"}
                >
                    {firstFiveServices.map((service) => (
                        
                        <motion.div key={service.id} className='card' variants={itemVariants}>
                                <Link 
                          to={`/${language}/${routeMap[language].services}/${service.urls[language]}`} 
                       
                      >
                            <div className='icon'>{service.icon}</div>
                            <span>
                                {service[language].service}
                            </span>
                            <p>
                                {service[language].descriptionCard}
                            </p>
                            </Link>
                        </motion.div>
                     
                    
                    ))}
                    <motion.div className='card-grey' variants={itemVariants}>
                        <Link to={`/${language}/${routeMap[language].services}`} >
                            <p>
                                <FormattedMessage id="home.discoverAllServices" />
                            </p>
                            <ArrowForwardIcon />
                        </Link>
                    </motion.div>
                </motion.div>
            </section>
                <section className='content--container'>
                    <span><FormattedMessage id="home.whyChooseUs" /></span>
                </section>
                <WhyUs />

                <div className='mt-50'>
                    <div className="decorartion-shape">
                        <img src="/media/shape.png" alt="decoration" />
                    </div>
                </div>

                <section className='content--container content--container-img'>
                    <div>
                        <span><FormattedMessage id="home.aboutUs" /></span>
                        <h2><FormattedMessage id="home.aboutUsTitle" /></h2>
                        <p>
                            <FormattedMessage id="home.aboutUsDescription" />
                        </p>
                        <div className='content--container--buttons'>
                            <button onClick={handleClickContact} className='button-dark' ><FormattedMessage id="home.contactUsButton" /></button>
                            <button onClick={handleClickAbout}  className='button-light'><FormattedMessage id="home.discoverAboutUsButton" /></button>
                        </div>
                    </div>
                    <img className='image-desktop' src="/media/home.png" alt="pegoraro-chi-siamo" />
                    <img className='image-mobile' src="/media/home-mobile.png" alt="pegoraro-chi-siamo" />
                </section>
                <CtaFinal />
            </div>
        </>
    );
}

export default Home;