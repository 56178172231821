import React, { useState, useEffect } from 'react';

import { ArrowBack, ArrowForward, HourglassEmpty, Translate, WorkOutline, SyncAlt } from '@mui/icons-material';
import { motion, AnimatePresence } from 'framer-motion';
import { useSwipeable } from 'react-swipeable';
import { FormattedMessage, useIntl } from 'react-intl';
import '../components/Carousel.scss';
import Preventivo from "../components/Preventivo"
const WhyUs = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
    const intl = useIntl();

    const cards = [
        {
            icon: <HourglassEmpty style={{ fontSize: 40 }} />,
            titleId: 'whyUs.experience.title',
            descriptionId: 'whyUs.experience.description',
        },
        {
            icon: <Translate style={{ fontSize: 40 }} />,
            titleId: 'whyUs.nativeSpeakers.title',
            descriptionId: 'whyUs.nativeSpeakers.description',
        },
        {
            icon: <WorkOutline style={{ fontSize: 40 }} />,
            titleId: 'whyUs.specialization.title',
            descriptionId: 'whyUs.specialization.description',
        },
        {
            icon: <SyncAlt style={{ fontSize: 40 }} />,
            titleId: 'whyUs.innovation.title',
            descriptionId: 'whyUs.innovation.description',
        },
    ];

    useEffect(() => {
        const handleResize = () => {
            setIsDesktop(window.innerWidth > 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % cards.length);
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + cards.length) % cards.length);
    };

    const swipeHandlers = useSwipeable({
        onSwipedLeft: handleNext,
        onSwipedRight: handlePrev,
        preventDefaultTouchmoveEvent: true,
        trackMouse: true,
    });

    const renderCard = (card, index) => (
        <div key={index} className="card-us card">
            <div className="icon">{card.icon}</div>
            <h4><FormattedMessage id={card.titleId} /></h4>
            <p><FormattedMessage id={card.descriptionId} /></p>
        </div>
    );

    const renderDesktopView = () => (
        <div className="desktop-cards">
            {cards.map((card, index) => renderCard(card, index))}
        </div>
    );

    const renderMobileView = () => (
        <div className="carousel-container" {...swipeHandlers}>
            <div className="carousel">
                <div className="carousel-inner">
                    <AnimatePresence initial={false} mode="wait">
                        <motion.div
                            key={currentIndex}
                            initial={{ opacity: 0, x: 100 }}
                            animate={{ opacity: 1, x: 0 }}
                            exit={{ opacity: 0, x: -100 }}
                            transition={{ duration: 0.5 }}
                        >
                            {renderCard(cards[currentIndex], currentIndex)}
                        </motion.div>
                    </AnimatePresence>
                </div>
            </div>
            <div className="controls">
                <button onClick={handlePrev}><ArrowBack /></button>
                <button onClick={handleNext}><ArrowForward /></button>
                <div className="progress-bar">
                    <div
                        className="progress"
                        style={{ width: `${((currentIndex + 1) / cards.length) * 100}%` }}
                    />
                </div>
            </div>
        </div>
    );

    return (
        <div className="why-us-container">
            {isDesktop ? renderDesktopView() : renderMobileView()}
            <div className='cta-container'  >
             
            <Preventivo />
            </div>
           
        </div>
    );
};

export default WhyUs;