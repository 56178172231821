import {
    CodeOutlined,
    MedicalServicesOutlined,
    PrecisionManufacturingOutlined,
    BoltOutlined,
    GavelOutlined,
    MicOutlined,
    SearchOutlined,
    PublicOutlined,
    DirectionsCarOutlined,
    LanguageOutlined,
    SchoolOutlined,
} from '@mui/icons-material';

export const servicesData = [
    {
        id: 1,
        icon: <CodeOutlined />,
        urls: {
            it: "traduzioni-tecniche",
            en: "technical-translations",
            de: "technische-ubersetzungen",
            fr: "traductions-techniques"
        },
        it: {
            service: 'Traduzioni',
            descriptionCard: 'Tecniche',
            subtitle: "Manuali, software e progetti STEM.",
            servizio: "Traduzioni Tecniche",
            description: "Tre decenni di esperienza ci rendono leader nel settore della traduzione tecnica.",
            bodysubtitle: "Precisione e Competenza al Servizio della Tua Tecnologia",
            body: "Le traduzioni tecniche richiedono un alto livello di precisione e competenza specifica nel settore. In Pegoraro Traduzioni, offriamo traduzioni tecniche di qualità superiore, realizzate da traduttori madrelingua esperti nei vari campi tecnici. La nostra esperienza trentennale ci permette di fornire soluzioni linguistiche affidabili per ogni tipo di documento tecnico.",
            servizi: [
                {
                    name: "Manuali Tecnici",
                    subtitle: "Redazione e traduzione di manuali d'uso e manutenzione.",
                    description: "Garantiamo che i tuoi manuali siano chiari, dettagliati e facilmente comprensibili per gli utenti finali."
                },
                {
                    name: "Documentazione Software",
                    subtitle: "Localizzazione e traduzione di software e guide utente.",
                    description: "Assicuriamo che il tuo software sia accessibile e utilizzabile in diverse lingue, rispettando le normative locali."
                    // norme diventa normative
                },
                {
                    name: "Progetti STEM",
                    subtitle: "Traduzioni per scienza, tecnologia, ingegneria e matematica.",
                    description: "Offriamo traduzioni precise e dettagliate per progetti scientifici e tecnici, mantenendo l'integrità del contenuto originale."
                },
                {
                    name: "Brevetti e Documenti Legali Tecnici",
                    subtitle: "Protezione della proprietà intellettuale.",
                    description: "Traduciamo brevetti e documenti legali tecnici, garantendo la conformità con le normative internazionali."
                },
                {
                    name: "Schede Tecniche e Specifiche di Prodotto",
                    subtitle: "Descrizioni accurate dei prodotti e delle loro caratteristiche.",
                    description: "Creiamo schede tecniche dettagliate che aiutano i tuoi clienti a comprendere meglio i tuoi prodotti."
                },
                {
                    name: "Cataloghi di Prodotti",
                    subtitle: "Presentazione professionale dei tuoi prodotti.",
                    description: "Traduciamo cataloghi di prodotti per una presentazione chiara e professionale sul mercato internazionale."
                },
            ],
        },
        en: {
            service: 'Translations',
            descriptionCard: 'Technical',
            subtitle: "Manuals, software, and STEM projects.",
            servizio: "Technical Translations",
            description: "Three decades of experience make us leaders in technical translation.",
            bodysubtitle: "Precision and Expertise at the Service of Your Technology",
            body: "Technical translations require a high level of precision and specific expertise in the field. At Pegoraro Translations, we offer superior quality technical translations, carried out by native-speaking translators who are experts in various technical fields. Our thirty years of experience allow us to provide reliable linguistic solutions for every type of technical document.",
            servizi: [
                {
                    name: "Technical Manuals",
                    subtitle: "Writing and translation of user and maintenance manuals.",
                    description: "We ensure that your manuals are clear, detailed, and easily understandable for end users."
                },
                {
                    name: "Software Documentation",
                    subtitle: "Localization and translation of software and user guides.",
                    description: "We ensure that your software is accessible and usable in different languages, respecting local norms."
                },
                {
                    name: "STEM Projects",
                    subtitle: "Translations for science, technology, engineering, and mathematics.",
                    description: "We offer precise and detailed translations for scientific and technical projects, maintaining the integrity of the original content."
                },
                {
                    name: "Patents and Technical Legal Documents",
                    subtitle: "Protection of intellectual property.",
                    description: "We translate patents and technical legal documents, ensuring compliance with international regulations."
                },
                {
                    name: "Technical Data Sheets and Product Specifications",
                    subtitle: "Accurate descriptions of products and their characteristics.",
                    description: "We create detailed technical sheets that help your customers better understand your products."
                },
                {
                    name: "Product Catalogs",
                    subtitle: "Professional presentation of your products.",
                    description: "We translate product catalogs for a clear and professional presentation in the international market."
                },
            ],
        },
        de: {
            service: 'Übersetzungen',
            descriptionCard: 'Technisch',
            subtitle: "Handbücher, Software und MINT-Projekte.",
            servizio: "Technische Übersetzungen",
            description: "Drei Jahrzehnte Erfahrung machen uns zu Marktführern im Bereich technischer Übersetzungen.",
            bodysubtitle: "Präzision und Fachwissen im Dienste Ihrer Technologie",
            body: "Technische Übersetzungen erfordern ein hohes Maß an Präzision und spezifisches Fachwissen im jeweiligen Bereich. Bei Pegoraro Übersetzungen bieten wir technische Übersetzungen von höchster Qualität, die von muttersprachlichen Übersetzern durchgeführt werden, die Experten in verschiedenen technischen Bereichen sind. Unsere dreißigjährige Erfahrung ermöglicht es uns, zuverlässige sprachliche Lösungen für jede Art von technischem Dokument zu liefern.",
            servizi: [
                {
                    name: "Technische Handbücher",
                    subtitle: "Erstellung und Übersetzung von Benutzer- und Wartungshandbüchern.",
                    description: "Wir garantieren, dass Ihre Handbücher klar, detailliert und für Endbenutzer leicht verständlich sind."
                },
                {
                    name: "Software-Dokumentation",
                    subtitle: "Lokalisierung und Übersetzung von Software und Benutzerhandbüchern.",
                    description: "Wir stellen sicher, dass Ihre Software in verschiedenen Sprachen zugänglich und nutzbar ist und dabei lokale Normen respektiert."
                },
                {
                    name: "MINT-Projekte",
                    subtitle: "Übersetzungen für Wissenschaft, Technologie, Ingenieurwesen und Mathematik.",
                    description: "Wir bieten präzise und detaillierte Übersetzungen für wissenschaftliche und technische Projekte und bewahren dabei die Integrität des Originalinhalts."
                },
                {
                    name: "Patente und technische Rechtsdokumente",
                    subtitle: "Schutz geistigen Eigentums.",
                    description: "Wir übersetzen Patente und technische Rechtsdokumente und gewährleisten die Einhaltung internationaler Vorschriften."
                },
                {
                    name: "Technische Datenblätter und Produktspezifikationen",
                    subtitle: "Genaue Beschreibungen von Produkten und deren Eigenschaften.",
                    description: "Wir erstellen detaillierte technische Datenblätter, die Ihren Kunden helfen, Ihre Produkte besser zu verstehen."
                },
                {
                    name: "Produktkataloge",
                    subtitle: "Professionelle Präsentation Ihrer Produkte.",
                    description: "Wir übersetzen Produktkataloge für eine klare und professionelle Präsentation auf dem internationalen Markt."
                },
            ],
        },
        fr: {
            service: 'Traductions',
            descriptionCard: 'Techniques',
            subtitle: "Manuels, logiciels et projets STEM.",
            servizio: "Traductions Techniques",
            description: "Trois décennies d'expérience font de nous des leaders dans le domaine de la traduction technique.",
            bodysubtitle: "Précision et Expertise au Service de Votre Technologie",
            body: "Les traductions techniques nécessitent un haut niveau de précision et une expertise spécifique dans le domaine. Chez Pegoraro Traductions, nous offrons des traductions techniques de qualité supérieure, réalisées par des traducteurs natifs experts dans divers domaines techniques. Nos trente années d'expérience nous permettent de fournir des solutions linguistiques fiables pour tout type de document technique.",
            servizi: [
                {
                    name: "Manuels Techniques",
                    subtitle: "Rédaction et traduction de manuels d'utilisation et de maintenance.",
                    description: "Nous garantissons que vos manuels sont clairs, détaillés et facilement compréhensibles pour les utilisateurs finaux."
                },
                {
                    name: "Documentation Logicielle",
                    subtitle: "Localisation et traduction de logiciels et de guides d'utilisation.",
                    description: "Nous nous assurons que votre logiciel est accessible et utilisable dans différentes langues, en respectant les normes locales."
                },
                {
                    name: "Projets STEM",
                    subtitle: "Traductions pour la science, la technologie, l'ingénierie et les mathématiques.",
                    description: "Nous offrons des traductions précises et détaillées pour les projets scientifiques et techniques, en maintenant l'intégrité du contenu original."
                },
                {
                    name: "Brevets et Documents Juridiques Techniques",
                    subtitle: "Protection de la propriété intellectuelle.",
                    description: "Nous traduisons les brevets et les documents juridiques techniques, en assurant la conformité aux réglementations internationales."
                },
                {
                    name: "Fiches Techniques et Spécifications de Produits",
                    subtitle: "Descriptions précises des produits et de leurs caractéristiques.",
                    description: "Nous créons des fiches techniques détaillées qui aident vos clients à mieux comprendre vos produits."
                },
                {
                    name: "Catalogues de Produits",
                    subtitle: "Présentation professionnelle de vos produits.",
                    description: "Nous traduisons les catalogues de produits pour une présentation claire et professionnelle sur le marché international."
                },
            ],
        },
    },
    {
        id: 2,
        icon: <MedicalServicesOutlined />,
        urls: {
            it: "traduzioni-medicali",
            en: "medical-translations",
            de: "medizinische-ubersetzungen",
            fr: "traductions-medicales"
        },
        it: {
            service: 'Traduzioni',
            descriptionCard: 'Medicali',
            subtitle: "Settore sanitario con terminologia corretta.",
            servizio: "Traduzioni Medicali",
            description: "Collaboriamo con esperti per garantire traduzioni mediche precise.",
            bodysubtitle: "Precisione e Riservatezza per il Settore Medico",
            body: "Le traduzioni mediche richiedono una conoscenza approfondita della terminologia sanitaria e un alto livello di riservatezza. In Pegoraro Traduzioni, collaboriamo con esperti del settore per garantire traduzioni accurate e conformi agli standard medici internazionali.",
            servizi: [
                {
                    name: "Cartelle Cliniche",
                    subtitle: "Traduzione di documenti medici",
                    description: "Garantiamo traduzioni accurate e riservate delle cartelle cliniche per una comunicazione sanitaria efficace."
                },
                {
                    name: "Documenti di Ricerca",
                    subtitle: "Traduzione di articoli scientifici e ricerche mediche",
                    description: "Offriamo traduzioni precise per articoli scientifici, ricerche mediche e pubblicazioni accademiche."
                },
                {
                    name: "Documenti Legali Medici",
                    subtitle: "Traduzioni per il settore sanitario",
                    description: "Forniamo traduzioni accurate di documenti legali e normative nel settore medico."
                },
                {
                    name: "Manuali e Guide",
                    subtitle: "Traduzione di manuali medici",
                    description: "Traduciamo manuali d'uso e guide per apparecchiature mediche, assicurando chiarezza e precisione."
                },
                {
                    name: "Comunicazioni Pazienti",
                    subtitle: "Traduzione di materiale informativo per pazienti",
                    description: "Creiamo traduzioni comprensibili e accurate di materiali informativi destinati ai pazienti."
                },
            ],
        },
        en: {
            service: 'Translations',
            descriptionCard: 'Medical',
            subtitle: "Healthcare sector with correct terminology.",
            servizio: "Medical Translations",
            description: "We collaborate with experts to ensure precise medical translations.",
            bodysubtitle: "Precision and Confidentiality for the Medical Sector",
            body: "Medical translations require an in-depth knowledge of healthcare terminology and a high level of confidentiality. At Pegoraro Translations, we collaborate with industry experts to ensure accurate translations that meet international medical standards.",
            servizi: [
                {
                    name: "Medical Records",
                    subtitle: "Translation of medical documents",
                    description: "We ensure accurate and confidential translations of medical records for effective healthcare communication."
                },
                {
                    name: "Research Documents",
                    subtitle: "Translation of scientific articles and medical research",
                    description: "We offer precise translations for scientific articles, medical research, and academic publications."
                },
                {
                    name: "Medical Legal Documents",
                    subtitle: "Translations for the healthcare sector",
                    description: "We provide accurate translations of legal documents and regulations in the medical field."
                },
                {
                    name: "Manuals and Guides",
                    subtitle: "Translation of medical manuals",
                    description: "We translate user manuals and guides for medical equipment, ensuring clarity and precision."
                },
                {
                    name: "Patient Communications",
                    subtitle: "Translation of informational materials for patients",
                    description: "We create understandable and accurate translations of informational materials intended for patients."
                },
            ],
        },
        de: {
            service: 'Übersetzungen',
            descriptionCard: 'Medizinisch',
            subtitle: "Gesundheitssektor mit korrekter Terminologie.",
            servizio: "Medizinische Übersetzungen",
            description: "Wir arbeiten mit Experten zusammen, um präzise medizinische Übersetzungen zu gewährleisten.",
            bodysubtitle: "Präzision und Vertraulichkeit im medizinischen Bereich",
            body: "Medizinische Übersetzungen erfordern umfassende Kenntnisse der medizinischen Terminologie und ein hohes Maß an Vertraulichkeit. Bei Pegoraro Übersetzungen arbeiten wir mit Branchenexperten zusammen, um genaue Übersetzungen zu gewährleisten, die internationalen medizinischen Standards entsprechen.",
            servizi: [
                {
                    name: "Patientenakten",
                    subtitle: "Übersetzung von medizinischen Dokumenten",
                    description: "Wir gewährleisten genaue und vertrauliche Übersetzungen von Patientenakten für eine effektive Gesundheitskommunikation."
                },
                {
                    name: "Forschungsdokumente",
                    subtitle: "Übersetzung wissenschaftlicher Artikel und medizinischer Forschung",
                    description: "Wir bieten präzise Übersetzungen für wissenschaftliche Artikel, medizinische Forschung und akademische Veröffentlichungen."
                },
                {
                    name: "Medizinische Rechtsdokumente",
                    subtitle: "Übersetzungen für den Gesundheitssektor",
                    description: "Wir bieten genaue Übersetzungen von Rechtsdokumenten und Vorschriften im medizinischen Bereich."
                },
                {
                    name: "Handbücher und Leitfäden",
                    subtitle: "Übersetzung von medizinischen Handbüchern",
                    description: "Wir übersetzen Bedienungsanleitungen und Handbücher für medizinische Geräte und gewährleisten Klarheit und Präzision."
                },
                {
                    name: "Patientenkommunikation",
                    subtitle: "Übersetzung von Informationsmaterialien für Patienten",
                    description: "Wir erstellen verständliche und genaue Übersetzungen von Informationsmaterialien für Patienten."
                },
            ],
        },
        fr: {
            service: 'Traductions',
            descriptionCard: 'Médicales',
            subtitle: "Secteur de la santé avec une terminologie correcte.",
            servizio: "Traductions Médicales",
            description: "Nous collaborons avec des experts pour garantir des traductions médicales précises.",
            bodysubtitle: "Précision et Confidentialité pour le Secteur Médical",
            body: "Les traductions médicales nécessitent une connaissance approfondie de la terminologie médicale et un haut niveau de confidentialité. Chez Pegoraro Traductions, nous collaborons avec des experts du secteur pour garantir des traductions précises conformes aux normes médicales internationales.",
            servizi: [
                {
                    name: "Dossiers Médicaux",
                    subtitle: "Traduction de documents médicaux",
                    description: "Nous garantissons des traductions précises et confidentielles des dossiers médicaux pour une communication sanitaire efficace."
                },
                {
                    name: "Documents de Recherche",
                    subtitle: "Traduction d'articles scientifiques et de recherches médicales",
                    description: "Nous offrons des traductions précises pour des articles scientifiques, des recherches médicales et des publications académiques."
                },
                {
                    name: "Documents Juridiques Médicaux",
                    subtitle: "Traductions pour le secteur de la santé",
                    description: "Nous fournissons des traductions précises de documents juridiques et de réglementations dans le domaine médical."
                },
                {
                    name: "Manuels et Guides",
                    subtitle: "Traduction de manuels médicaux",
                    description: "Nous traduisons des manuels d'utilisation et des guides pour les équipements médicaux, en garantissant clarté et précision."
                },
                {
                    name: "Communications avec les Patients",
                    subtitle: "Traduction de matériels d'information pour les patients",
                    description: "Nous créons des traductions compréhensibles et précises des matériels d'information destinés aux patients."
                },
            ],
        },
    },
    {
        id: 3,
        icon: <PrecisionManufacturingOutlined />,
        urls: {
            it: "traduzioni-industria-4-0",
            en: "industry-4-0-translations",
            de: "industrie-4-0-ubersetzungen",
            fr: "traductions-industrie-4-0"
        },
        it: {
            service: 'Traduzioni',
            descriptionCard: 'Industria 4.0',
            subtitle: "Supporto nell'era dell'automazione.",
            servizio: "Traduzioni Industria 4.0",
            description: "Forniamo supporto nelle traduzioni per l'industria dell'automazione.",
            bodysubtitle: "Innovazione e Precisione per l'Industria 4.0",
            body: "Le traduzioni per l'Industria 4.0 richiedono una profonda comprensione delle tecnologie emergenti e dei processi di automazione. In Pegoraro Traduzioni, offriamo traduzioni tecniche precise per supportare le aziende nell'era della digitalizzazione e dell'automazione.",
            servizi: [
                {
                    name: "Manuali di Automazione",
                    subtitle: "Traduzione di documenti tecnici per l'automazione industriale",
                    description: "Offriamo traduzioni precise per manuali di automazione, garantendo chiarezza e accuratezza."
                },
                {
                    name: "Documentazione di Robotica",
                    subtitle: "Traduzione di manuali e guide di robotica",
                    description: "Forniamo traduzioni dettagliate per documentazione di robotica, supportando l'innovazione tecnologica."
                },
                {
                    name: "Manuali di Manutenzione",
                    subtitle: "Traduzione di guide e manuali di manutenzione",
                    description: "Garantiamo traduzioni accurate per manuali di manutenzione industriale, assicurando una corretta operatività."
                },
                {
                    name: "Documenti di Sicurezza",
                    subtitle: "Traduzione di normative e linee guida sulla sicurezza",
                    description: "Offriamo traduzioni conformi alle normative per documenti di sicurezza industriale."
                },
                {
                    name: "Software Industriale",
                    subtitle: "Localizzazione di software per l'industria 4.0",
                    description: "Assicuriamo che il tuo software industriale sia accessibile in diverse lingue, rispettando le norme locali."
                },
            ],
        },
        en: {
            service: 'Translations',
            descriptionCard: 'Industry 4.0',
            subtitle: "Support in the era of automation.",
            servizio: "Industry 4.0 Translations",
            description: "We provide support in translations for the automation industry.",
            bodysubtitle: "Innovation and Precision for Industry 4.0",
            body: "Translations for Industry 4.0 require a deep understanding of emerging technologies and automation processes. At Pegoraro Translations, we offer precise technical translations to support companies in the era of digitization and automation.",
            servizi: [
                {
                    name: "Automation Manuals",
                    subtitle: "Translation of technical documents for industrial automation",
                    description: "We offer precise translations for automation manuals, ensuring clarity and accuracy."
                },
                {
                    name: "Robotics Documentation",
                    subtitle: "Translation of robotics manuals and guides",
                    description: "We provide detailed translations for robotics documentation, supporting technological innovation."
                },
                {
                    name: "Maintenance Manuals",
                    subtitle: "Translation of maintenance guides and manuals",
                    description: "We ensure accurate translations for industrial maintenance manuals, ensuring proper operation."
                },
                {
                    name: "Safety Documents",
                    subtitle: "Translation of safety regulations and guidelines",
                    description: "We offer translations compliant with regulations for industrial safety documents."
                },
                {
                    name: "Industrial Software",
                    subtitle: "Localization of software for Industry 4.0",
                    description: "We ensure that your industrial software is accessible in different languages, respecting local norms."
                },
            ],
        },
        de: {
            service: 'Übersetzungen',
            descriptionCard: 'Industrie 4.0',
            subtitle: "Unterstützung im Zeitalter der Automatisierung.",
            servizio: "Industrie 4.0 Übersetzungen",
            description: "Wir bieten Unterstützung bei Übersetzungen für die Automatisierungsindustrie.",
            bodysubtitle: "Innovation und Präzision für Industrie 4.0",
            body: "Übersetzungen für Industrie 4.0 erfordern ein tiefes Verständnis für aufkommende Technologien und Automatisierungsprozesse. Bei Pegoraro Übersetzungen bieten wir präzise technische Übersetzungen, um Unternehmen im Zeitalter der Digitalisierung und Automatisierung zu unterstützen.",
            servizi: [
                {
                    name: "Automatisierungshandbücher",
                    subtitle: "Übersetzung technischer Dokumente für die Industrieautomatisierung",
                    description: "Wir bieten präzise Übersetzungen für Automatisierungshandbücher und gewährleisten Klarheit und Genauigkeit."
                },
                {
                    name: "Robotik-Dokumentation",
                    subtitle: "Übersetzung von Robotik-Handbüchern und -Leitfäden",
                    description: "Wir bieten detaillierte Übersetzungen für Robotik-Dokumentationen und unterstützen technologische Innovationen."
                },
                {
                    name: "Wartungshandbücher",
                    subtitle: "Übersetzung von Wartungsanleitungen und -handbüchern",
                    description: "Wir gewährleisten genaue Übersetzungen für industrielle Wartungshandbücher und sichern den ordnungsgemäßen Betrieb."
                },
                {
                    name: "Sicherheitsdokumente",
                    subtitle: "Übersetzung von Sicherheitsvorschriften und -richtlinien",
                    description: "Wir bieten Übersetzungen, die den Vorschriften für industrielle Sicherheitsdokumente entsprechen."
                },
                {
                    name: "Industrielle Software",
                    subtitle: "Lokalisierung von Software für Industrie 4.0",
                    description: "Wir stellen sicher, dass Ihre industrielle Software in verschiedenen Sprachen zugänglich ist und lokale Normen berücksichtigt."
                },
            ],
        },
        fr: {
            service: 'Traductions',
            descriptionCard: 'Industrie 4.0',
            subtitle: "Support à l'ère de l'automatisation.",
            servizio: "Traductions Industrie 4.0",
            description: "Nous fournissons un support en traductions pour l'industrie de l'automatisation.",
            bodysubtitle: "Innovation et Précision pour l'Industrie 4.0",
            body: "Les traductions pour l'Industrie 4.0 nécessitent une compréhension approfondie des technologies émergentes et des processus d'automatisation. Chez Pegoraro Traductions, nous offrons des traductions techniques précises pour soutenir les entreprises à l'ère de la numérisation et de l'automatisation.",
            servizi: [
                {
                    name: "Manuels d'Automatisation",
                    subtitle: "Traduction de documents techniques pour l'automatisation industrielle",
                    description: "Nous offrons des traductions précises pour les manuels d'automatisation, garantissant clarté et précision."
                },
                {
                    name: "Documentation Robotique",
                    subtitle: "Traduction de manuels et de guides de robotique",
                    description: "Nous fournissons des traductions détaillées pour la documentation robotique, soutenant l'innovation technologique."
                },
                {
                    name: "Manuels de Maintenance",
                    subtitle: "Traduction de guides et de manuels de maintenance",
                    description: "Nous garantissons des traductions précises pour les manuels de maintenance industrielle, assurant un bon fonctionnement."
                },
                {
                    name: "Documents de Sécurité",
                    subtitle: "Traduction de réglementations et de lignes directrices sur la sécurité",
                    description: "Nous offrons des traductions conformes aux réglementations pour les documents de sécurité industrielle."
                },
                {
                    name: "Logiciels Industriels",
                    subtitle: "Localisation de logiciels pour l'Industrie 4.0",
                    description: "Nous veillons à ce que votre logiciel industriel soit accessible dans différentes langues, en respectant les normes locales."
                },
            ],
        },
    },
    {
        id: 4,
        icon: <BoltOutlined />,
        urls: {
            it: "traduzioni-energetiche",
            en: "energy-translations",
            de: "energie-ubersetzungen",
            fr: "traductions-energetiques"
        },
        it: {
            service: 'Traduzioni',
            descriptionCard: 'Energetiche',
            subtitle: "Soluzioni per energie rinnovabili e sicurezza.",
            servizio: "Traduzioni Energetiche",
            description: "Traduciamo documenti per soluzioni energetiche rinnovabili e di sicurezza.",
            bodysubtitle: "Sostenibilità e Sicurezza nel Settore Energetico",
            body: "Le traduzioni energetiche richiedono una conoscenza approfondita delle tecnologie rinnovabili e delle normative di sicurezza. In Pegoraro Traduzioni, offriamo traduzioni specializzate per supportare le aziende nel settore delle energie rinnovabili e della sicurezza energetica.",
            servizi: [
                {
                    name: "Progetti di Energie Rinnovabili",
                    subtitle: "Traduzione di documenti per energie rinnovabili",
                    description: "Forniamo traduzioni accurate per progetti di energie rinnovabili, supportando la sostenibilità."
                },
                {
                    name: "Manuali di Sicurezza Energetica",
                    subtitle: "Traduzione di guide e manuali di sicurezza",
                    description: "Offriamo traduzioni precise per manuali di sicurezza energetica, assicurando conformità alle normative."
                },
                {
                    name: "Schede Tecniche di Prodotti Energetici",
                    subtitle: "Traduzione di schede tecniche e specifiche",
                    description: "Garantiamo traduzioni dettagliate di schede tecniche per prodotti energetici."
                },
                {
                    name: "Documenti di Normativa Energetica",
                    subtitle: "Traduzione di leggi e regolamenti energetici",
                    description: "Offriamo traduzioni conformi per documenti di normativa energetica."
                },
                {
                    name: "Report di Impatto Ambientale",
                    subtitle: "Traduzione di documenti di valutazione ambientale",
                    description: "Forniamo traduzioni accurate per report di impatto ambientale, supportando la sostenibilità."
                },
            ],
        },
        en: {
            service: 'Translations',
            descriptionCard: 'Energy',
            subtitle: "Solutions for renewable energy and safety.",
            servizio: "Energy Translations",
            description: "We translate documents for renewable energy and safety solutions.",
            bodysubtitle: "Sustainability and Safety in the Energy Sector",
            body: "Energy translations require an in-depth knowledge of renewable technologies and safety regulations. At Pegoraro Translations, we offer specialized translations to support companies in the renewable energy and energy safety sector.",
            servizi: [
                {
                    name: "Renewable Energy Projects",
                    subtitle: "Translation of documents for renewable energy",
                    description: "We provide accurate translations for renewable energy projects, supporting sustainability."
                },
                {
                    name: "Energy Safety Manuals",
                    subtitle: "Translation of safety guides and manuals",
                    description: "We offer precise translations for energy safety manuals, ensuring compliance with regulations."
                },
                {
                    name: "Technical Data Sheets for Energy Products",
                    subtitle: "Translation of technical data sheets and specifications",
                    description: "We guarantee detailed translations of technical data sheets for energy products."
                },
                {
                    name: "Energy Regulation Documents",
                    subtitle: "Translation of energy laws and regulations",
                    description: "We offer compliant translations for energy regulation documents."
                },
                {
                    name: "Environmental Impact Reports",
                    subtitle: "Translation of environmental assessment documents",
                    description: "We provide accurate translations for environmental impact reports, supporting sustainability."
                },
            ],
        },
        de: {
            service: 'Übersetzungen',
            descriptionCard: 'Energie',
            subtitle: "Lösungen für erneuerbare Energien und Sicherheit.",
            servizio: "Energieübersetzungen",
            description: "Wir übersetzen Dokumente für erneuerbare Energie- und Sicherheitslösungen.",
            bodysubtitle: "Nachhaltigkeit und Sicherheit im Energiesektor",
            body: "Energieübersetzungen erfordern umfassende Kenntnisse der erneuerbaren Technologien und Sicherheitsvorschriften. Bei Pegoraro Übersetzungen bieten wir spezialisierte Übersetzungen, um Unternehmen im Bereich der erneuerbaren Energien und der Energiesicherheit zu unterstützen.",
            servizi: [
                {
                    name: "Projekte für erneuerbare Energien",
                    subtitle: "Übersetzung von Dokumenten für erneuerbare Energien",
                    description: "Wir bieten genaue Übersetzungen für Projekte im Bereich der erneuerbaren Energien und unterstützen die Nachhaltigkeit."
                },
                {
                    name: "Sicherheitsanleitungen für Energie",
                    subtitle: "Übersetzung von Sicherheitsleitfäden und -handbüchern",
                    description: "Wir bieten präzise Übersetzungen für Energiesicherheitsanleitungen und gewährleisten die Einhaltung der Vorschriften."
                },
                {
                    name: "Technische Datenblätter für Energieprodukte",
                    subtitle: "Übersetzung von technischen Datenblättern und Spezifikationen",
                    description: "Wir gewährleisten detaillierte Übersetzungen von technischen Datenblättern für Energieprodukte."
                },
                {
                    name: "Energie-Regulierungsdokumente",
                    subtitle: "Übersetzung von Energiegesetzen und -vorschriften",
                    description: "Wir bieten konforme Übersetzungen für Energie-Regulierungsdokumente."
                },
                {
                    name: "Umweltverträglichkeitsberichte",
                    subtitle: "Übersetzung von Umweltbewertungsdokumenten",
                    description: "Wir bieten genaue Übersetzungen von Umweltverträglichkeitsberichten und unterstützen die Nachhaltigkeit."
                },
            ],
        },
        fr: {
            service: 'Traductions',
            descriptionCard: 'Énergie',
            subtitle: "Solutions pour l'énergie renouvelable et la sécurité.",
            servizio: "Traductions Énergétiques",
            description: "Nous traduisons des documents pour des solutions d'énergie renouvelable et de sécurité.",
            bodysubtitle: "Durabilité et Sécurité dans le Secteur de l'Énergie",
            body: "Les traductions énergétiques nécessitent une connaissance approfondie des technologies renouvelables et des réglementations de sécurité. Chez Pegoraro Traductions, nous offrons des traductions spécialisées pour soutenir les entreprises dans le secteur des énergies renouvelables et de la sécurité énergétique.",
            servizi: [
                {
                    name: "Projets d'Énergie Renouvelable",
                    subtitle: "Traduction de documents pour l'énergie renouvelable",
                    description: "Nous fournissons des traductions précises pour les projets d'énergie renouvelable, soutenant la durabilité."
                },
                {
                    name: "Manuels de Sécurité Énergétique",
                    subtitle: "Traduction de guides et de manuels de sécurité",
                    description: "Nous offrons des traductions précises pour les manuels de sécurité énergétique, garantissant la conformité aux réglementations."
                },
                {
                    name: "Fiches Techniques des Produits Énergétiques",
                    subtitle: "Traduction de fiches techniques et de spécifications",
                    description: "Nous garantissons des traductions détaillées des fiches techniques pour les produits énergétiques."
                },
                {
                    name: "Documents de Réglementation Énergétique",
                    subtitle: "Traduction de lois et de règlements énergétiques",
                    description: "Nous offrons des traductions conformes pour les documents de réglementation énergétique."
                },
                {
                    name: "Rapports d'Impact Environnemental",
                    subtitle: "Traduction de documents d'évaluation environnementale",
                    description: "Nous fournissons des traductions précises pour les rapports d'impact environnemental, soutenant la durabilité."
                },
            ],
        },
    },
    {
        id: 5,
        icon: <GavelOutlined />,
        urls: {
            it: "traduzioni-legali",
            en: "legal-translations",
            de: "rechtliche-ubersetzungen",
            fr: "traductions-juridiques"
        },
        it: {
            service: 'Traduzioni',
            descriptionCard: 'Legali',
            subtitle: "Documenti legali accurati e riservati.",
            servizio: "Traduzioni Legali",
            description: "Garantiamo traduzioni legali accurate e riservate.",
            bodysubtitle: "Affidabilità e Riservatezza per le Tue Traduzioni Legali",
            body: "Le traduzioni legali richiedono precisione e una profonda comprensione delle terminologie giuridiche. In Pegoraro Traduzioni, garantiamo traduzioni legali accurate e riservate, realizzate da esperti del settore.",
            servizi: [
                {
                    name: "Contratti e Accordi",
                    subtitle: "Traduzione di contratti e accordi legali",
                    description: "Offriamo traduzioni accurate di contratti e accordi, garantendo chiarezza e conformità legale."
                },
                {
                    name: "Documenti di Causa",
                    subtitle: "Traduzione di documenti per cause legali",
                    description: "Forniamo traduzioni precise per documenti di causa, supportando la tua difesa legale."
                },
                {
                    name: "Documenti Normativi",
                    subtitle: "Traduzione di leggi e regolamenti",
                    description: "Offriamo traduzioni conformi per documenti normativi, garantendo il rispetto delle leggi locali."
                },
                {
                    name: "Certificati Legali",
                    subtitle: "Traduzione di certificati e documenti ufficiali",
                    description: "Forniamo traduzioni accurate di certificati legali, assicurando la validità dei documenti."
                },
                {
                    name: "Documenti di Proprietà Intellettuale",
                    subtitle: "Traduzione di brevetti e marchi",
                    description: "Offriamo traduzioni precise per documenti di proprietà intellettuale, proteggendo i tuoi diritti legali."
                },
            ],
        },
        en: {
            service: 'Translations',
            descriptionCard: 'Legal',
            subtitle: "Accurate and confidential legal documents.",
            servizio: "Legal Translations",
            description: "We ensure accurate and confidential legal translations.",
            bodysubtitle: "Reliability and Confidentiality for Your Legal Translations",
            body: "Legal translations require precision and a deep understanding of legal terminology. At Pegoraro Translations, we ensure accurate and confidential legal translations, carried out by industry experts.",
            servizi: [
                {
                    name: "Contracts and Agreements",
                    subtitle: "Translation of legal contracts and agreements",
                    description: "We offer accurate translations of contracts and agreements, ensuring clarity and legal compliance."
                },
                {
                    name: "Litigation Documents",
                    subtitle: "Translation of legal case documents",
                    description: "We provide precise translations for litigation documents, supporting your legal defense."
                },
                {
                    name: "Regulatory Documents",
                    subtitle: "Translation of laws and regulations",
                    description: "We offer compliant translations for regulatory documents, ensuring adherence to local laws."
                },
                {
                    name: "Legal Certificates",
                    subtitle: "Translation of certificates and official documents",
                    description: "We provide accurate translations of legal certificates, ensuring the validity of documents."
                },
                {
                    name: "Intellectual Property Documents",
                    subtitle: "Translation of patents and trademarks",
                    description: "We offer precise translations for intellectual property documents, protecting your legal rights."
                },
            ],
        },
        de: {
            service: 'Übersetzungen',
            descriptionCard: 'Rechtlich',
            subtitle: "Genau und vertraulich rechtliche Dokumente.",
            servizio: "Rechtliche Übersetzungen",
            description: "Wir gewährleisten genaue und vertrauliche rechtliche Übersetzungen.",
            bodysubtitle: "Zuverlässigkeit und Vertraulichkeit für Ihre rechtlichen Übersetzungen",
            body: "Rechtliche Übersetzungen erfordern Präzision und ein tiefes Verständnis der juristischen Terminologie. Bei Pegoraro Übersetzungen gewährleisten wir genaue und vertrauliche rechtliche Übersetzungen, die von Branchenexperten durchgeführt werden.",
            servizi: [
                {
                    name: "Verträge und Vereinbarungen",
                    subtitle: "Übersetzung von rechtlichen Verträgen und Vereinbarungen",
                    description: "Wir bieten genaue Übersetzungen von Verträgen und Vereinbarungen und gewährleisten Klarheit und rechtliche Konformität."
                },
                {
                    name: "Prozessdokumente",
                    subtitle: "Übersetzung von rechtlichen Falldokumenten",
                    description: "Wir bieten präzise Übersetzungen für Prozessdokumente und unterstützen Ihre rechtliche Verteidigung."
                },
                {
                    name: "Regulierungsdokumente",
                    subtitle: "Übersetzung von Gesetzen und Vorschriften",
                    description: "Wir bieten konforme Übersetzungen für Regulierungsdokumente und gewährleisten die Einhaltung lokaler Gesetze."
                },
                {
                    name: "Rechtszertifikate",
                    subtitle: "Übersetzung von Zertifikaten und offiziellen Dokumenten",
                    description: "Wir bieten genaue Übersetzungen von Rechtszertifikaten und gewährleisten die Gültigkeit der Dokumente."
                },
                {
                    name: "Dokumente zum geistigen Eigentum",
                    subtitle: "Übersetzung von Patenten und Marken",
                    description: "Wir bieten präzise Übersetzungen für Dokumente zum geistigen Eigentum und schützen Ihre rechtlichen Rechte."
                },
            ],
        },
        fr: {
            service: 'Traductions',
            descriptionCard: 'Juridiques',
            subtitle: "Documents juridiques précis et confidentiels.",
            servizio: "Traductions Juridiques",
            description: "Nous garantissons des traductions juridiques précises et confidentielles.",
            bodysubtitle: "Fiabilité et Confidentialité pour Vos Traductions Juridiques",
            body: "Les traductions juridiques nécessitent précision et une compréhension approfondie de la terminologie juridique. Chez Pegoraro Traductions, nous garantissons des traductions juridiques précises et confidentielles, réalisées par des experts du secteur.",
            servizi: [
                {
                    name: "Contrats et Accords",
                    subtitle: "Traduction de contrats et accords juridiques",
                    description: "Nous offrons des traductions précises de contrats et d'accords, garantissant clarté et conformité légale."
                },
                {
                    name: "Documents de Litige",
                    subtitle: "Traduction de documents de litige",
                    description: "Nous fournissons des traductions précises pour les documents de litige, soutenant votre défense juridique."
                },
                {
                    name: "Documents Réglementaires",
                    subtitle: "Traduction de lois et de règlements",
                    description: "Nous offrons des traductions conformes pour les documents réglementaires, garantissant le respect des lois locales."
                },
                {
                    name: "Certificats Juridiques",
                    subtitle: "Traduction de certificats et documents officiels",
                    description: "Nous fournissons des traductions précises de certificats juridiques, garantissant la validité des documents."
                },
                {
                    name: "Documents de Propriété Intellectuelle",
                    subtitle: "Traduction de brevets et de marques",
                    description: "Nous offrons des traductions précises pour les documents de propriété intellectuelle, protégeant vos droits légaux."
                },
            ],
        },
    },
    {
        id: 6,
        icon: <MicOutlined />,
        urls: {
            it: "revisione-contenuti",
            en: "content-review",
            de: "inhaltliche-überprüfung",
            fr: "révision-des-contenus"
        },
        it: {
            service: 'Revisione',
            descriptionCard: 'Contenuti',
            subtitle: "Trasformazione di audio e video in testo.",
            servizio: "Revisione Contenuti",
            description: "Offriamo servizi di revisione di contenuti multimediali.",
            bodysubtitle: "Qualità e Accuratezza per i Tuoi Contenuti Multimediali",
            body: "La revisione di contenuti multimediali richiede attenzione ai dettagli e una conoscenza approfondita delle lingue coinvolte. In Pegoraro Traduzioni, offriamo servizi di revisione accurati per garantire che i tuoi contenuti audio e video siano di alta qualità.",
            servizi: [
                {
                    name: "Trascrizioni Audio",
                    subtitle: "Trascrizione accurata di contenuti audio",
                    description: "Offriamo servizi di trascrizione precisi per contenuti audio, garantendo chiarezza e accuratezza."
                },
                {
                    name: "Sottotitoli per Video",
                    subtitle: "Creazione di sottotitoli per contenuti video",
                    description: "Forniamo sottotitoli accurati per video, migliorando l'accessibilità e la comprensione."
                },
                {
                    name: "Revisione di Testi",
                    subtitle: "Correzione e miglioramento di testi scritti",
                    description: "Offriamo servizi di revisione per garantire che i tuoi testi siano privi di errori e ben scritti."
                },
                {
                    name: "Localizzazione di Contenuti",
                    subtitle: "Adattamento di contenuti per mercati locali",
                    description: "Assicuriamo che i tuoi contenuti siano culturalmente rilevanti e comprensibili per i mercati locali."
                },
                {
                    name: "Sincronizzazione Audio",
                    subtitle: "Allineamento di audio e testo",
                    description: "Offriamo servizi di sincronizzazione per garantire che l'audio sia perfettamente allineato con il testo."
                },
            ],
        },
        en: {
            service: 'Review',
            descriptionCard: 'Content',
            subtitle: "Transforming audio and video into text.",
            servizio: "Content Review",
            description: "We offer multimedia content review services.",
            bodysubtitle: "Quality and Accuracy for Your Multimedia Content",
            body: "Reviewing multimedia content requires attention to detail and a thorough understanding of the languages involved. At Pegoraro Translations, we offer accurate review services to ensure that your audio and video content is of high quality.",
            servizi: [
                {
                    name: "Audio Transcriptions",
                    subtitle: "Accurate transcription of audio content",
                    description: "We offer precise transcription services for audio content, ensuring clarity and accuracy."
                },
                {
                    name: "Video Subtitles",
                    subtitle: "Creating subtitles for video content",
                    description: "We provide accurate subtitles for videos, improving accessibility and understanding."
                },
                {
                    name: "Text Review",
                    subtitle: "Correction and improvement of written texts",
                    description: "We offer review services to ensure that your texts are error-free and well-written."
                },
                {
                    name: "Content Localization",
                    subtitle: "Adapting content for local markets",
                    description: "We ensure that your content is culturally relevant and understandable for local markets."
                },
                {
                    name: "Audio Synchronization",
                    subtitle: "Aligning audio and text",
                    description: "We offer synchronization services to ensure that audio is perfectly aligned with the text."
                },
            ],
        },
        de: {
            service: 'Überprüfung',
            descriptionCard: 'Inhalte',
            subtitle: "Umwandlung von Audio und Video in Text.",
            servizio: "Inhaltliche Überprüfung",
            description: "Wir bieten Überprüfungsdienste für multimediale Inhalte an.",
            bodysubtitle: "Qualität und Genauigkeit für Ihre multimedialen Inhalte",
            body: "Die Überprüfung multimedialer Inhalte erfordert Aufmerksamkeit für Details und ein gründliches Verständnis der beteiligten Sprachen. Bei Pegoraro Übersetzungen bieten wir genaue Überprüfungsdienste an, um sicherzustellen, dass Ihre Audio- und Videoinhalte von hoher Qualität sind.",
            servizi: [
                {
                    name: "Audio-Transkriptionen",
                    subtitle: "Genaue Transkription von Audioinhalten",
                    description: "Wir bieten präzise Transkriptionsdienste für Audioinhalte an und gewährleisten Klarheit und Genauigkeit."
                },
                {
                    name: "Video-Untertitel",
                    subtitle: "Erstellung von Untertiteln für Videoinhalte",
                    description: "Wir bieten genaue Untertitel für Videos an und verbessern die Zugänglichkeit und das Verständnis."
                },
                {
                    name: "Textüberprüfung",
                    subtitle: "Korrektur und Verbesserung von geschriebenen Texten",
                    description: "Wir bieten Überprüfungsdienste an, um sicherzustellen, dass Ihre Texte fehlerfrei und gut geschrieben sind."
                },
                {
                    name: "Inhaltslokalisierung",
                    subtitle: "Anpassung von Inhalten für lokale Märkte",
                    description: "Wir stellen sicher, dass Ihre Inhalte kulturell relevant und verständlich für lokale Märkte sind."
                },
                {
                    name: "Audio-Synchronisierung",
                    subtitle: "Ausrichtung von Audio und Text",
                    description: "Wir bieten Synchronisierungsdienste an, um sicherzustellen, dass das Audio perfekt mit dem Text übereinstimmt."
                },
            ],
        },
        fr: {
            service: 'Révision',
            descriptionCard: 'Contenus',
            subtitle: "Transformation de l'audio et de la vidéo en texte.",
            servizio: "Révision des Contenus",
            description: "Nous offrons des services de révision de contenus multimédias.",
            bodysubtitle: "Qualité et Précision pour Vos Contenus Multimédias",
            body: "La révision de contenus multimédias nécessite une attention aux détails et une compréhension approfondie des langues impliquées. Chez Pegoraro Traductions, nous offrons des services de révision précis pour garantir que vos contenus audio et vidéo soient de haute qualité.",
            servizi: [
                {
                    name: "Transcriptions Audio",
                    subtitle: "Transcription précise de contenus audio",
                    description: "Nous offrons des services de transcription précis pour les contenus audio, garantissant clarté et précision."
                },
                {
                    name: "Sous-titres pour Vidéos",
                    subtitle: "Création de sous-titres pour contenus vidéo",
                    description: "Nous fournissons des sous-titres précis pour les vidéos, améliorant l'accessibilité et la compréhension."
                },
                {
                    name: "Révision de Textes",
                    subtitle: "Correction et amélioration de textes écrits",
                    description: "Nous offrons des services de révision pour garantir que vos textes soient sans erreur et bien écrits."
                },
                {
                    name: "Localisation de Contenus",
                    subtitle: "Adaptation de contenus pour les marchés locaux",
                    description: "Nous assurons que vos contenus soient culturellement pertinents et compréhensibles pour les marchés locaux."
                },
                {
                    name: "Synchronisation Audio",
                    subtitle: "Alignement de l'audio et du texte",
                    description: "Nous offrons des services de synchronisation pour garantir que l'audio soit parfaitement aligné avec le texte."
                },
            ],
        },
    },
    {
        id: 7,
        icon: <SearchOutlined />,
        urls: {
            it: "traduzioni-seo-web",
            en: "seo-web-translations",
            de: "seo-web-übersetzungen",
            fr: "traductions-seo-web"
        },
        it: {
            service: 'Traduzioni',
            descriptionCard: 'SEO & Web',
            subtitle: "Traduzioni SEO e Siti Web.",
            servizio: "Traduzioni SEO & Web",
            description: "Ottimizziamo le traduzioni per il web e i motori di ricerca.",
            bodysubtitle: "Visibilità e Prestazioni per il Tuo Sito Web",
            body: "Le traduzioni per SEO e siti web richiedono una combinazione di competenze linguistiche e conoscenze tecniche. In Pegoraro Traduzioni, offriamo traduzioni ottimizzate per migliorare la visibilità del tuo sito web sui motori di ricerca e garantire un'esperienza utente eccellente.",
            servizi: [
                {
                    name: "Ottimizzazione SEO",
                    subtitle: "Traduzione di contenuti ottimizzati per motori di ricerca",
                    description: "Offriamo traduzioni SEO per migliorare la visibilità del tuo sito web sui motori di ricerca."
                },
                {
                    name: "Traduzione di Siti Web",
                    subtitle: "Localizzazione di contenuti web",
                    description: "Forniamo traduzioni precise per siti web, garantendo che i tuoi contenuti siano rilevanti per i mercati locali."
                },
                {
                    name: "Contenuti per Blog",
                    subtitle: "Traduzione di articoli e post",
                    description: "Offriamo traduzioni per articoli di blog, migliorando l'engagement del pubblico."
                },
                {
                    name: "Annunci Pubblicitari",
                    subtitle: "Traduzione di annunci e campagne PPC",
                    description: "Forniamo traduzioni per annunci pubblicitari, assicurando che i tuoi messaggi siano efficaci e persuasivi."
                },
                {
                    name: "Analisi delle Parole Chiave",
                    subtitle: "Ricerca e traduzione di parole chiave",
                    description: "Offriamo servizi di ricerca e traduzione di parole chiave per migliorare il tuo posizionamento SEO."
                },
            ],
        },
        en: {
            service: 'Translations',
            descriptionCard: 'SEO & Web',
            subtitle: "SEO and Website Translations.",
            servizio: "SEO & Web Translations",
            description: "We optimize translations for the web and search engines.",
            bodysubtitle: "Visibility and Performance for Your Website",
            body: "Translations for SEO and websites require a combination of linguistic skills and technical knowledge. At Pegoraro Translations, we offer optimized translations to improve your website's visibility on search engines and ensure an excellent user experience.",
            servizi: [
                {
                    name: "SEO Optimization",
                    subtitle: "Translation of search engine optimized content",
                    description: "We offer SEO translations to improve your website's visibility on search engines."
                },
                {
                    name: "Website Translation",
                    subtitle: "Localization of web content",
                    description: "We provide precise translations for websites, ensuring your content is relevant to local markets."
                },
                {
                    name: "Blog Content",
                    subtitle: "Translation of articles and posts",
                    description: "We offer translations for blog articles, enhancing audience engagement."
                },
                {
                    name: "Advertising Campaigns",
                    subtitle: "Translation of ads and PPC campaigns",
                    description: "We provide translations for advertising campaigns, ensuring your messages are effective and persuasive."
                },
                {
                    name: "Keyword Analysis",
                    subtitle: "Research and translation of keywords",
                    description: "We offer keyword research and translation services to improve your SEO ranking."
                },
            ],
        },
        de: {
            service: 'Übersetzungen',
            descriptionCard: 'SEO & Web',
            subtitle: "SEO- und Website-Übersetzungen.",
            servizio: "SEO & Web Übersetzungen",
            description: "Wir optimieren Übersetzungen für das Web und Suchmaschinen.",
            bodysubtitle: "Sichtbarkeit und Leistung für Ihre Website",
            body: "Übersetzungen für SEO und Websites erfordern eine Kombination aus sprachlichen Fähigkeiten und technischem Wissen. Bei Pegoraro Übersetzungen bieten wir optimierte Übersetzungen an, um die Sichtbarkeit Ihrer Website in Suchmaschinen zu verbessern und eine hervorragende Benutzererfahrung zu gewährleisten.",
            servizi: [
                {
                    name: "SEO-Optimierung",
                    subtitle: "Übersetzung von suchmaschinenoptimierten Inhalten",
                    description: "Wir bieten SEO-Übersetzungen an, um die Sichtbarkeit Ihrer Website in Suchmaschinen zu verbessern."
                },
                {
                    name: "Website-Übersetzung",
                    subtitle: "Lokalisierung von Webinhalten",
                    description: "Wir bieten präzise Übersetzungen für Websites an und stellen sicher, dass Ihre Inhalte für lokale Märkte relevant sind."
                },
                {
                    name: "Blog-Inhalte",
                    subtitle: "Übersetzung von Artikeln und Beiträgen",
                    description: "Wir bieten Übersetzungen für Blogartikel an und erhöhen das Engagement des Publikums."
                },
                {
                    name: "Werbekampagnen",
                    subtitle: "Übersetzung von Anzeigen und PPC-Kampagnen",
                    description: "Wir bieten Übersetzungen für Werbekampagnen an und stellen sicher, dass Ihre Botschaften effektiv und überzeugend sind."
                },
                {
                    name: "Schlüsselwortanalyse",
                    subtitle: "Recherche und Übersetzung von Schlüsselwörtern",
                    description: "Wir bieten Dienstleistungen zur Schlüsselwortrecherche und -übersetzung an, um Ihr SEO-Ranking zu verbessern."
                },
            ],
        },
        fr: {
            service: 'Traductions',
            descriptionCard: 'SEO & Web',
            subtitle: "Traductions SEO et de sites web.",
            servizio: "Traductions SEO & Web",
            description: "Nous optimisons les traductions pour le web et les moteurs de recherche.",
            bodysubtitle: "Visibilité et Performances pour Votre Site Web",
            body: "Les traductions pour le SEO et les sites web nécessitent une combinaison de compétences linguistiques et de connaissances techniques. Chez Pegoraro Traductions, nous proposons des traductions optimisées pour améliorer la visibilité de votre site web sur les moteurs de recherche et garantir une excellente expérience utilisateur.",
            servizi: [
                {
                    name: "Optimisation SEO",
                    subtitle: "Traduction de contenus optimisés pour les moteurs de recherche",
                    description: "Nous offrons des traductions SEO pour améliorer la visibilité de votre site web sur les moteurs de recherche."
                },
                {
                    name: "Traduction de Sites Web",
                    subtitle: "Localisation de contenus web",
                    description: "Nous fournissons des traductions précises pour les sites web, garantissant que vos contenus soient pertinents pour les marchés locaux."
                },
                {
                    name: "Contenu de Blog",
                    subtitle: "Traduction d'articles et de posts",
                    description: "Nous proposons des traductions pour les articles de blog, augmentant l'engagement de l'audience."
                },
                {
                    name: "Campagnes Publicitaires",
                    subtitle: "Traduction d'annonces et de campagnes PPC",
                    description: "Nous offrons des traductions pour les campagnes publicitaires, garantissant que vos messages soient efficaces et persuasifs."
                },
                {
                    name: "Analyse des Mots-Clés",
                    subtitle: "Recherche et traduction de mots-clés",
                    description: "Nous proposons des services de recherche et de traduction de mots-clés pour améliorer votre classement SEO."
                },
            ],
        },
    },
    {
        id: 8,
        icon: <PublicOutlined />,
        urls: {
            it: "traduzioni-pubblicitarie",
            en: "advertising-translations",
            de: "werbeübersetzungen",
            fr: "traductions-publicitaires"
        },
        it: {
            service: 'Traduzioni',
            descriptionCard: 'Pubblicitarie',
            subtitle: "Messaggi efficaci e persuasivi.",
            servizio: "Traduzioni Pubblicitarie",
            description: "Traduciamo messaggi pubblicitari per renderli efficaci e persuasivi.",
            bodysubtitle: "Creatività e Persuasione per le Tue Campagne Pubblicitarie",
            body: "Le traduzioni pubblicitarie devono essere creative e persuasive per catturare l'attenzione del pubblico. In Pegoraro Traduzioni, offriamo traduzioni pubblicitarie che mantengono l'efficacia del messaggio originale e lo adattano al contesto culturale locale.",
            servizi: [
                {
                    name: "Campagne Pubblicitarie",
                    subtitle: "Traduzione di campagne pubblicitarie",
                    description: "Offriamo traduzioni per campagne pubblicitarie, garantendo che il tuo messaggio sia efficace e persuasivo."
                },
                {
                    name: "Materiale Promozionale",
                    subtitle: "Traduzione di brochure e volantini",
                    description: "Forniamo traduzioni precise per materiale promozionale, migliorando la comunicazione con i clienti."
                },
                {
                    name: "Annunci Digitali",
                    subtitle: "Traduzione di annunci online",
                    description: "Offriamo traduzioni per annunci digitali, assicurando che il tuo messaggio raggiunga il pubblico giusto."
                },
                {
                    name: "Script di Pubblicità",
                    subtitle: "Traduzione di script per spot pubblicitari",
                    description: "Forniamo traduzioni accurate per script pubblicitari, garantendo la coerenza del messaggio."
                },
                {
                    name: "Contenuti Social Media",
                    subtitle: "Traduzione di post e contenuti social",
                    description: "Offriamo traduzioni per contenuti social media, migliorando l'engagement e la visibilità del tuo brand."
                },
            ],
        },
        en: {
            service: 'Translations',
            descriptionCard: 'Advertising',
            subtitle: "Effective and persuasive messages.",
            servizio: "Advertising Translations",
            description: "We translate advertising messages to make them effective and persuasive.",
            bodysubtitle: "Creativity and Persuasion for Your Advertising Campaigns",
            body: "Advertising translations must be creative and persuasive to capture the audience's attention. At Pegoraro Translations, we offer advertising translations that maintain the effectiveness of the original message and adapt it to the local cultural context.",
            servizi: [
                {
                    name: "Advertising Campaigns",
                    subtitle: "Translation of advertising campaigns",
                    description: "We offer translations for advertising campaigns, ensuring that your message is effective and persuasive."
                },
                {
                    name: "Promotional Material",
                    subtitle: "Translation of brochures and flyers",
                    description: "We provide precise translations for promotional material, improving communication with customers."
                },
                {
                    name: "Digital Ads",
                    subtitle: "Translation of online ads",
                    description: "We offer translations for digital ads, ensuring that your message reaches the right audience."
                },
                {
                    name: "Advertising Scripts",
                    subtitle: "Translation of scripts for advertisements",
                    description: "We provide accurate translations for advertising scripts, ensuring message consistency."
                },
                {
                    name: "Social Media Content",
                    subtitle: "Translation of posts and social content",
                    description: "We offer translations for social media content, improving engagement and brand visibility."
                },
            ],
        },
        de: {
            service: 'Übersetzungen',
            descriptionCard: 'Werbung',
            subtitle: "Wirksame und überzeugende Botschaften.",
            servizio: "Werbeübersetzungen",
            description: "Wir übersetzen Werbebotschaften, um sie effektiv und überzeugend zu machen.",
            bodysubtitle: "Kreativität und Überzeugungskraft für Ihre Werbekampagnen",
            body: "Werbeübersetzungen müssen kreativ und überzeugend sein, um die Aufmerksamkeit des Publikums zu erregen. Bei Pegoraro Übersetzungen bieten wir Werbeübersetzungen an, die die Wirksamkeit der Originalbotschaft bewahren und an den lokalen kulturellen Kontext anpassen.",
            servizi: [
                {
                    name: "Werbekampagnen",
                    subtitle: "Übersetzung von Werbekampagnen",
                    description: "Wir bieten Übersetzungen für Werbekampagnen an und gewährleisten, dass Ihre Botschaft effektiv und überzeugend ist."
                },
                {
                    name: "Werbematerial",
                    subtitle: "Übersetzung von Broschüren und Flyern",
                    description: "Wir bieten präzise Übersetzungen für Werbematerial an und verbessern die Kommunikation mit Kunden."
                },
                {
                    name: "Digitale Anzeigen",
                    subtitle: "Übersetzung von Online-Anzeigen",
                    description: "Wir bieten Übersetzungen für digitale Anzeigen an und gewährleisten, dass Ihre Botschaft die richtige Zielgruppe erreicht."
                },
                {
                    name: "Werbeskripte",
                    subtitle: "Übersetzung von Skripten für Werbung",
                    description: "Wir bieten genaue Übersetzungen für Werbeskripte an und gewährleisten die Konsistenz der Botschaft."
                },
                {
                    name: "Social-Media-Inhalte",
                    subtitle: "Übersetzung von Beiträgen und sozialen Inhalten",
                    description: "Wir bieten Übersetzungen für Social-Media-Inhalte an und verbessern das Engagement und die Sichtbarkeit Ihrer Marke."
                },
            ],
        },
        fr: {
            service: 'Traductions',
            descriptionCard: 'Publicitaires',
            subtitle: "Messages efficaces et persuasifs.",
            servizio: "Traductions Publicitaires",
            description: "Nous traduisons des messages publicitaires pour les rendre efficaces et persuasifs.",
            bodysubtitle: "Créativité et Persuasion pour Vos Campagnes Publicitaires",
            body: "Les traductions publicitaires doivent être créatives et persuasives pour capter l'attention du public. Chez Pegoraro Traductions, nous offrons des traductions publicitaires qui maintiennent l'efficacité du message original et l'adaptent au contexte culturel local.",
            servizi: [
                {
                    name: "Campagnes Publicitaires",
                    subtitle: "Traduction de campagnes publicitaires",
                    description: "Nous offrons des traductions pour les campagnes publicitaires, garantissant que votre message soit efficace et persuasif."
                },
                {
                    name: "Matériel Promotionnel",
                    subtitle: "Traduction de brochures et de dépliants",
                    description: "Nous fournissons des traductions précises pour le matériel promotionnel, améliorant la communication avec les clients."
                },
                {
                    name: "Annonces Digitales",
                    subtitle: "Traduction d'annonces en ligne",
                    description: "Nous offrons des traductions pour les annonces digitales, garantissant que votre message atteigne le bon public."
                },
                {
                    name: "Scripts Publicitaires",
                    subtitle: "Traduction de scripts pour publicités",
                    description: "Nous fournissons des traductions précises pour les scripts publicitaires, garantissant la cohérence du message."
                },
                {
                    name: "Contenus de Médias Sociaux",
                    subtitle: "Traduction de publications et de contenus sociaux",
                    description: "Nous offrons des traductions pour les contenus de médias sociaux, améliorant l'engagement et la visibilité de votre marque."
                },
            ],
        },
    },
    {
        id: 9,
        icon: <DirectionsCarOutlined />,
        urls: {
            it: "traduzioni-automotive",
            en: "automotive-translations",
            de: "automobil-übersetzungen",
            fr: "traductions-automobiles"
        },
        it: {
            service: 'Traduzioni',
            descriptionCard: 'Automotive',
            subtitle: "Documentazione tecnica per l'automotive.",
            servizio: "Traduzioni Automotive",
            description: "Forniamo traduzioni per documentazione tecnica nel settore automotive.",
            bodysubtitle: "Precisione e Affidabilità per il Settore Automotive",
            body: "Le traduzioni nel settore automotive richiedono una conoscenza approfondita della terminologia tecnica e dei processi di produzione. In Pegoraro Traduzioni, offriamo traduzioni accurate per supportare la tua azienda nel mercato globale dell'automotive.",
            servizi: [
                {
                    name: "Manuali Tecnici",
                    subtitle: "Traduzione di manuali d'uso e manutenzione per veicoli",
                    description: "Offriamo traduzioni precise per manuali tecnici, garantendo chiarezza e accuratezza."
                },
                {
                    name: "Cataloghi di Ricambi",
                    subtitle: "Traduzione di cataloghi di componenti e ricambi",
                    description: "Forniamo traduzioni dettagliate per cataloghi di ricambi, migliorando la gestione dei componenti."
                },
                {
                    name: "Documentazione di Produzione",
                    subtitle: "Traduzione di documenti di processo e produzione",
                    description: "Offriamo traduzioni accurate per documentazione di produzione, supportando la qualità dei processi."
                },
                {
                    name: "Manuali di Riparazione",
                    subtitle: "Traduzione di guide e manuali di riparazione",
                    description: "Forniamo traduzioni precise per manuali di riparazione, assicurando una corretta manutenzione dei veicoli."
                },
                {
                    name: "Documenti di Sicurezza",
                    subtitle: "Traduzione di normative e linee guida sulla sicurezza",
                    description: "Offriamo traduzioni conformi alle normative per documenti di sicurezza nel settore automotive."
                },
            ],
        },
        en: {
            service: 'Translations',
            descriptionCard: 'Automotive',
            subtitle: "Technical documentation for the automotive sector.",
            servizio: "Automotive Translations",
            description: "We provide translations for technical documentation in the automotive sector.",
            bodysubtitle: "Precision and Reliability for the Automotive Sector",
            body: "Translations in the automotive sector require an in-depth knowledge of technical terminology and production processes. At Pegoraro Translations, we offer accurate translations to support your company in the global automotive market.",
            servizi: [
                {
                    name: "Technical Manuals",
                    subtitle: "Translation of user and maintenance manuals for vehicles",
                    description: "We offer precise translations for technical manuals, ensuring clarity and accuracy."
                },
                {
                    name: "Spare Parts Catalogs",
                    subtitle: "Translation of component and spare parts catalogs",
                    description: "We provide detailed translations for spare parts catalogs, improving component management."
                },
                {
                    name: "Production Documentation",
                    subtitle: "Translation of process and production documents",
                    description: "We offer accurate translations for production documentation, supporting process quality."
                },
                {
                    name: "Repair Manuals",
                    subtitle: "Translation of repair guides and manuals",
                    description: "We provide precise translations for repair manuals, ensuring proper vehicle maintenance."
                },
                {
                    name: "Safety Documents",
                    subtitle: "Translation of safety regulations and guidelines",
                    description: "We offer translations compliant with regulations for safety documents in the automotive sector."
                },
            ],
        },
        de: {
            service: 'Übersetzungen',
            descriptionCard: 'Automobil',
            subtitle: "Technische Dokumentation für die Automobilbranche.",
            servizio: "Automobil Übersetzungen",
            description: "Wir bieten Übersetzungen für technische Dokumentationen im Automobilsektor an.",
            bodysubtitle: "Präzision und Zuverlässigkeit für die Automobilbranche",
            body: "Übersetzungen im Automobilsektor erfordern ein tiefes Verständnis der technischen Terminologie und Produktionsprozesse. Bei Pegoraro Übersetzungen bieten wir genaue Übersetzungen an, um Ihr Unternehmen im globalen Automobilmarkt zu unterstützen.",
            servizi: [
                {
                    name: "Technische Handbücher",
                    subtitle: "Übersetzung von Benutzer- und Wartungshandbüchern für Fahrzeuge",
                    description: "Wir bieten präzise Übersetzungen für technische Handbücher an und gewährleisten Klarheit und Genauigkeit."
                },
                {
                    name: "Ersatzteilkataloge",
                    subtitle: "Übersetzung von Katalogen für Komponenten und Ersatzteile",
                    description: "Wir bieten detaillierte Übersetzungen für Ersatzteilkataloge an und verbessern das Komponentenmanagement."
                },
                {
                    name: "Produktionsdokumentation",
                    subtitle: "Übersetzung von Prozess- und Produktionsdokumenten",
                    description: "Wir bieten genaue Übersetzungen für Produktionsdokumentationen an und unterstützen die Prozessqualität."
                },
                {
                    name: "Reparaturhandbücher",
                    subtitle: "Übersetzung von Reparaturanleitungen und Handbüchern",
                    description: "Wir bieten präzise Übersetzungen für Reparaturhandbücher an und gewährleisten die ordnungsgemäße Fahrzeugwartung."
                },
                {
                    name: "Sicherheitsdokumente",
                    subtitle: "Übersetzung von Sicherheitsvorschriften und -richtlinien",
                    description: "Wir bieten Übersetzungen für Sicherheitsdokumente im Automobilsektor an, die den Vorschriften entsprechen."
                },
            ],
        },
        fr: {
            service: 'Traductions',
            descriptionCard: 'Automobile',
            subtitle: "Documentation technique pour le secteur automobile.",
            servizio: "Traductions Automobiles",
            description: "Nous fournissons des traductions pour la documentation technique dans le secteur automobile.",
            bodysubtitle: "Précision et Fiabilité pour le Secteur Automobile",
            body: "Les traductions dans le secteur automobile nécessitent une connaissance approfondie de la terminologie technique et des processus de production. Chez Pegoraro Traductions, nous offrons des traductions précises pour soutenir votre entreprise sur le marché mondial de l'automobile.",
            servizi: [
                {
                    name: "Manuels Techniques",
                    subtitle: "Traduction de manuels d'utilisation et d'entretien pour véhicules",
                    description: "Nous offrons des traductions précises pour les manuels techniques, garantissant clarté et précision."
                },
                {
                    name: "Catalogues de Pièces Détachées",
                    subtitle: "Traduction de catalogues de composants et de pièces détachées",
                    description: "Nous fournissons des traductions détaillées pour les catalogues de pièces détachées, améliorant la gestion des composants."
                },
                {
                    name: "Documentation de Production",
                    subtitle: "Traduction de documents de processus et de production",
                    description: "Nous offrons des traductions précises pour la documentation de production, soutenant la qualité des processus."
                },
                {
                    name: "Manuels de Réparation",
                    subtitle: "Traduction de guides et de manuels de réparation",
                    description: "Nous fournissons des traductions précises pour les manuels de réparation, garantissant un entretien adéquat des véhicules."
                },
                {
                    name: "Documents de Sécurité",
                    subtitle: "Traduction de réglementations et de lignes directrices sur la sécurité",
                    description: "Nous offrons des traductions conformes aux réglementations pour les documents de sécurité dans le secteur automobile."
                },
            ],
        },
    },
    {
        id: 10,
        icon: <LanguageOutlined />,
        urls: {
            it: "traduzioni-turistiche",
            en: "tourism-translations",
            de: "touristische-ubersetzungen",
            fr: "traductions-touristiques"
        },
        it: {
            service: 'Traduzioni',
            descriptionCard: 'Turistiche',
            subtitle: "Comunicazioni per il settore turistico.",
            servizio: "Traduzioni Turistiche",
            description: "Traduciamo comunicazioni per il settore turistico.",
            bodysubtitle: "Accoglienza e Chiarezza per il Settore Turistico",
            body: "Le traduzioni per il settore turistico devono essere accoglienti e chiare per fornire un'esperienza positiva ai visitatori. In Pegoraro Traduzioni, offriamo traduzioni che migliorano la comunicazione e l'accessibilità delle informazioni turistiche.",
            servizi: [
                {
                    name: "Guide Turistiche",
                    subtitle: "Traduzione di guide e itinerari",
                    description: "Offriamo traduzioni precise per guide turistiche, migliorando l'esperienza dei visitatori."
                },
                {
                    name: "Materiale Promozionale",
                    subtitle: "Traduzione di brochure e volantini",
                    description: "Forniamo traduzioni accurate per materiale promozionale, attirando più visitatori."
                },
                {
                    name: "Siti Web Turistici",
                    subtitle: "Localizzazione di contenuti web per il turismo",
                    description: "Offriamo traduzioni per siti web turistici, garantendo una comunicazione efficace."
                },
                {
                    name: "Segnaletica e Cartellonistica",
                    subtitle: "Traduzione di segnaletica e cartellonistica",
                    description: "Forniamo traduzioni per segnaletica turistica, migliorando la navigazione dei visitatori."
                },
                {
                    name: "App e Guide Digitali",
                    subtitle: "Traduzione di applicazioni turistiche",
                    description: "Offriamo traduzioni per app turistiche, migliorando l'accesso alle informazioni."
                },
            ],
        },
        en: {
            service: 'Translations',
            descriptionCard: 'Tourism',
            subtitle: "Communications for the tourism sector.",
            servizio: "Tourism Translations",
            description: "We translate communications for the tourism sector.",
            bodysubtitle: "Welcome and Clarity for the Tourism Sector",
            body: "Translations for the tourism sector need to be welcoming and clear to provide a positive experience for visitors. At Pegoraro Translations, we offer translations that improve the communication and accessibility of tourist information.",
            servizi: [
                {
                    name: "Tourist Guides",
                    subtitle: "Translation of guides and itineraries",
                    description: "We offer precise translations for tourist guides, enhancing visitors' experiences."
                },
                {
                    name: "Promotional Material",
                    subtitle: "Translation of brochures and flyers",
                    description: "We provide accurate translations for promotional material, attracting more visitors."
                },
                {
                    name: "Tourism Websites",
                    subtitle: "Localization of web content for tourism",
                    description: "We offer translations for tourism websites, ensuring effective communication."
                },
                {
                    name: "Signage and Billboards",
                    subtitle: "Translation of signage and billboards",
                    description: "We provide translations for tourist signage, improving visitor navigation."
                },
                {
                    name: "Apps and Digital Guides",
                    subtitle: "Translation of tourism applications",
                    description: "We offer translations for tourism apps, improving access to information."
                },
            ],
        },
        de: {
            service: 'Übersetzungen',
            descriptionCard: 'Tourismus',
            subtitle: "Kommunikation für den Tourismussektor.",
            servizio: "Touristische Übersetzungen",
            description: "Wir übersetzen Kommunikation für den Tourismussektor.",
            bodysubtitle: "Willkommen und Klarheit für den Tourismussektor",
            body: "Übersetzungen für den Tourismussektor müssen einladend und klar sein, um den Besuchern eine positive Erfahrung zu bieten. Bei Pegoraro Übersetzungen bieten wir Übersetzungen an, die die Kommunikation und Zugänglichkeit von touristischen Informationen verbessern.",
            servizi: [
                {
                    name: "Reiseführer",
                    subtitle: "Übersetzung von Reiseführern und Reiserouten",
                    description: "Wir bieten präzise Übersetzungen für Reiseführer an und verbessern das Erlebnis der Besucher."
                },
                {
                    name: "Werbematerial",
                    subtitle: "Übersetzung von Broschüren und Flyern",
                    description: "Wir bieten genaue Übersetzungen für Werbematerial an und ziehen mehr Besucher an."
                },
                {
                    name: "Tourismus-Websites",
                    subtitle: "Lokalisierung von Webinhalten für den Tourismus",
                    description: "Wir bieten Übersetzungen für Tourismus-Websites an und gewährleisten eine effektive Kommunikation."
                },
                {
                    name: "Beschilderung und Plakate",
                    subtitle: "Übersetzung von Beschilderung und Plakaten",
                    description: "Wir bieten Übersetzungen für touristische Beschilderung an und verbessern die Navigation der Besucher."
                },
                {
                    name: "Apps und digitale Reiseführer",
                    subtitle: "Übersetzung von Tourismusanwendungen",
                    description: "Wir bieten Übersetzungen für Tourismus-Apps an und verbessern den Zugang zu Informationen."
                },
            ],
        },
        fr: {
            service: 'Traductions',
            descriptionCard: 'Touristiques',
            subtitle: "Communications pour le secteur touristique.",
            servizio: "Traductions Touristiques",
            description: "Nous traduisons des communications pour le secteur touristique.",
            bodysubtitle: "Accueil et Clarté pour le Secteur Touristique",
            body: "Les traductions pour le secteur touristique doivent être accueillantes et claires pour offrir une expérience positive aux visiteurs. Chez Pegoraro Traductions, nous offrons des traductions qui améliorent la communication et l'accessibilité des informations touristiques.",
            servizi: [
                {
                    name: "Guides Touristiques",
                    subtitle: "Traduction de guides et d'itinéraires",
                    description: "Nous offrons des traductions précises pour les guides touristiques, améliorant l'expérience des visiteurs."
                },
                {
                    name: "Matériel Promotionnel",
                    subtitle: "Traduction de brochures et de dépliants",
                    description: "Nous fournissons des traductions précises pour le matériel promotionnel, attirant plus de visiteurs."
                },
                {
                    name: "Sites Web Touristiques",
                    subtitle: "Localisation de contenu web pour le tourisme",
                    description: "Nous offrons des traductions pour les sites web touristiques, garantissant une communication efficace."
                },
                {
                    name: "Signalisation et Panneaux",
                    subtitle: "Traduction de signalisation et de panneaux",
                    description: "Nous offrons des traductions pour la signalisation touristique, améliorant la navigation des visiteurs."
                },
                {
                    name: "Applications et Guides Numériques",
                    subtitle: "Traduction d'applications touristiques",
                    description: "Nous offrons des traductions pour les applications touristiques, améliorant l'accès à l'information."
                },
            ],
        },
    },
    {
        id: 11,
        icon: <SchoolOutlined />,
        urls: {
            it: "traduzioni-corsi-aziendali",
            en: "corporate-training-translations",
            de: "übersetzungen-für-unternehmensschulungen",
            fr: "traductions-pour-formations-entreprises"
        },
        it: {
            service: 'Traduzioni',
            descriptionCard: 'Corsi Aziendali',
            subtitle: "Materiali didattici aziendali.",
            servizio: "Traduzioni Corsi Aziendali",
            description: "Offriamo traduzioni per materiali didattici aziendali.",
            bodysubtitle: "Formazione e Crescita per le Aziende",
            body: "Le traduzioni per corsi aziendali devono essere precise e coinvolgenti per facilitare l'apprendimento e la crescita dei dipendenti. In Pegoraro Traduzioni, offriamo traduzioni accurate per materiali didattici, corsi di formazione e contenuti di e-learning.",
            servizi: [
                {
                    name: "Manuali di Formazione",
                    subtitle: "Traduzione di manuali di formazione aziendale",
                    description: "Offriamo traduzioni precise per manuali di formazione, migliorando l'apprendimento."
                },
                {
                    name: "Corsi di E-learning",
                    subtitle: "Traduzione di contenuti per corsi online",
                    description: "Forniamo traduzioni per corsi di e-learning, supportando la formazione a distanza."
                },
                {
                    name: "Documentazione di Sicurezza",
                    subtitle: "Traduzione di guide e manuali di sicurezza aziendale",
                    description: "Offriamo traduzioni conformi alle normative per documenti di sicurezza aziendale."
                },
                {
                    name: "Materiali Didattici",
                    subtitle: "Traduzione di materiali di studio e dispense",
                    description: "Forniamo traduzioni accurate per materiali didattici, migliorando la qualità della formazione."
                },
                {
                    name: "Workshop e Seminari",
                    subtitle: "Traduzione di contenuti per eventi formativi",
                    description: "Offriamo traduzioni per workshop e seminari, facilitando la partecipazione internazionale."
                },
            ],
        },
        en: {
            service: 'Translations',
            descriptionCard: 'Corporate Training',
            subtitle: "Corporate training materials.",
            servizio: "Corporate Training Translations",
            description: "We offer translations for corporate training materials.",
            bodysubtitle: "Training and Growth for Companies",
            body: "Translations for corporate training must be precise and engaging to facilitate employee learning and growth. At Pegoraro Translations, we offer accurate translations for training materials, courses, and e-learning content.",
            servizi: [
                {
                    name: "Training Manuals",
                    subtitle: "Translation of corporate training manuals",
                    description: "We offer precise translations for training manuals, improving learning."
                },
                {
                    name: "E-learning Courses",
                    subtitle: "Translation of online course content",
                    description: "We provide translations for e-learning courses, supporting distance learning."
                },
                {
                    name: "Safety Documentation",
                    subtitle: "Translation of corporate safety guides and manuals",
                    description: "We offer translations compliant with regulations for corporate safety documents."
                },
                {
                    name: "Educational Materials",
                    subtitle: "Translation of study materials and handouts",
                    description: "We provide accurate translations for educational materials, improving the quality of training."
                },
                {
                    name: "Workshops and Seminars",
                    subtitle: "Translation of content for training events",
                    description: "We offer translations for workshops and seminars, facilitating international participation."
                },
            ],
        },
        de: {
            service: 'Übersetzungen',
            descriptionCard: 'Unternehmensschulungen',
            subtitle: "Unternehmensschulungsmaterialien.",
            servizio: "Übersetzungen für Unternehmensschulungen",
            description: "Wir bieten Übersetzungen für Unternehmensschulungsmaterialien an.",
            bodysubtitle: "Schulung und Wachstum für Unternehmen",
            body: "Übersetzungen für Unternehmensschulungen müssen präzise und ansprechend sein, um das Lernen und Wachstum der Mitarbeiter zu fördern. Bei Pegoraro Übersetzungen bieten wir genaue Übersetzungen für Schulungsmaterialien, Kurse und E-Learning-Inhalte an.",
            servizi: [
                {
                    name: "Schulungshandbücher",
                    subtitle: "Übersetzung von Unternehmensschulungshandbüchern",
                    description: "Wir bieten präzise Übersetzungen für Schulungshandbücher an und verbessern das Lernen."
                },
                {
                    name: "E-Learning-Kurse",
                    subtitle: "Übersetzung von Inhalten für Online-Kurse",
                    description: "Wir bieten Übersetzungen für E-Learning-Kurse an und unterstützen das Fernlernen."
                },
                {
                    name: "Sicherheitsdokumentation",
                    subtitle: "Übersetzung von Unternehmenssicherheitsleitfäden und -handbüchern",
                    description: "Wir bieten Übersetzungen für Unternehmenssicherheitsdokumente an, die den Vorschriften entsprechen."
                },
                {
                    name: "Bildungsmaterialien",
                    subtitle: "Übersetzung von Studienmaterialien und Handouts",
                    description: "Wir bieten genaue Übersetzungen für Bildungsmaterialien an und verbessern die Qualität der Schulung."
                },
                {
                    name: "Workshops und Seminare",
                    subtitle: "Übersetzung von Inhalten für Schulungsveranstaltungen",
                    description: "Wir bieten Übersetzungen für Workshops und Seminare an und erleichtern die internationale Teilnahme."
                },
            ],
        },
        fr: {
            service: 'Traductions',
            descriptionCard: 'Formations Entreprises',
            subtitle: "Matériels de formation d'entreprise.",
            servizio: "Traductions pour Formations Entreprises",
            description: "Nous offrons des traductions pour les matériels de formation d'entreprise.",
            bodysubtitle: "Formation et Croissance pour les Entreprises",
            body: "Les traductions pour les formations en entreprise doivent être précises et engageantes pour faciliter l'apprentissage et la croissance des employés. Chez Pegoraro Traductions, nous offrons des traductions précises pour les matériels de formation, les cours et les contenus d'e-learning.",
            servizi: [
                {
                    name: "Manuels de Formation",
                    subtitle: "Traduction de manuels de formation d'entreprise",
                    description: "Nous offrons des traductions précises pour les manuels de formation, améliorant l'apprentissage."
                },
                {
                    name: "Cours d'E-learning",
                    subtitle: "Traduction de contenus de cours en ligne",
                    description: "Nous fournissons des traductions pour les cours d'e-learning, soutenant la formation à distance."
                },
                {
                    name: "Documentation de Sécurité",
                    subtitle: "Traduction de guides et de manuels de sécurité d'entreprise",
                    description: "Nous offrons des traductions conformes aux réglementations pour les documents de sécurité d'entreprise."
                },
                {
                    name: "Matériels Éducatifs",
                    subtitle: "Traduction de matériels d'étude et de supports",
                    description: "Nous fournissons des traductions précises pour les matériels éducatifs, améliorant la qualité de la formation."
                },
                {
                    name: "Ateliers et Séminaires",
                    subtitle: "Traduction de contenus pour les événements de formation",
                    description: "Nous offrons des traductions pour les ateliers et les séminaires, facilitant la participation internationale."
                },
            ],
        },
    },
    // ... Ripeti la stessa struttura per gli altri servizi (id 2-11) ...
];