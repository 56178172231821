import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import './Disclaimer.scss';
import { CallMade } from '@mui/icons-material';
import MetaTags from '../components/MetaTags';
import { useLanguage, routeMap } from '../hooks/useLanguage';

const Disclaimer = () => {
    const [language] = useLanguage();
    const intl = useIntl();

    return (
        <>
            <MetaTags
                title={intl.formatMessage({ id: "disclaimer.metaTitle" })}
                description={intl.formatMessage({ id: "disclaimer.metaDescription" })}
                keywords={intl.formatMessage({ id: "disclaimer.metaKeywords" })}
            />
            <div className='disclaimer-page'>
                <section className='hero'>
                    <div className='hero--container'>
                        <h1><FormattedMessage id="disclaimer.title" /></h1>
                        <p><FormattedMessage id="disclaimer.description" /></p>
                        {/* <div className='hero--buttons'>
                        <a href="#cookie"  >
                            <button className='icon-button'>
                                <span><FormattedMessage id="disclaimer.privacyPolicy" /></span>
                                <CallMade />
                            </button></a>
                            <a href="#cookie"  > <button className='icon-button'>
                                <span><FormattedMessage id="disclaimer.cookiePolicy" /></span>
                                <CallMade />
                            </button></a>
                        </div> */}
                    </div>
                </section>
                <div className=''>
                    <div className="decorartion-shape">
                        <img src="/media/shape.png" alt="decoration" />
                    </div>
                </div>
                <section className='disclaimer-content'  >
                    {languageSpecificContent[language]}
                </section>
                <hr />
            </div>
        </>
    )
}

export default Disclaimer;

const languageSpecificContent = {
    en: (
        <section className='content--container'>
            <h1>Privacy Policy</h1>
            <p>Effective date: 2024-07-15</p>
            <p>Updated on: 2024-07-15</p>
            <p>
                This Privacy Policy explains the policies of Pegoraro Traduzioni on the collection
                and use of the information we collect when you access https://pegorarotranslations.com (the “Service”). This Privacy Policy describes your
                privacy rights and how you are protected under privacy laws.</p>
            <p>
                By using our Service, you are consenting to the collection and use of your information in accordance
                with this Privacy Policy. Please do not access or use our Service if you do not consent to the collection
                and use of your information as outlined in this Privacy Policy. This Privacy Policy has been created with
                the help of <a target="_blank" href="https://cookie-script.com/privacy-policy-generator">CookieScript Privacy Policy Generator</a>.</p>
            <p>Pegoraro Traduzioni is authorized to modify this Privacy Policy at any time.
                This may occur without prior notice.</p>
            <p>
                Pegoraro Traduzioni will post the revised Privacy Policy on the https://pegorarotranslations.com website</p>

            <h3>Collection and Use of Your Personal Information</h3>
            <h4>Information We Collect</h4>
            <p>
                When using our Service, you will be prompted to provide us with personal information used to
                contact or identify you. https://pegorarotranslations.com collects the following information:    </p>
            <ul>
                <li>Usage Data</li>
            </ul>
            <p>Usage Data includes the following:</p>
            <ul>
                <li>Internet Protocol (IP) address of computers accessing the site</li>
                <li>Web page requests</li>
                <li>Referring web pages</li>
                <li>Browser used to access site</li>
                <li>Time and date of access</li>
            </ul>
            <h4>How We Collect Information</h4>
            <p>
                https://pegorarotranslations.com collects and receives information from you in the following manner:    </p>
            <ul>
                <li>When you interact with our Service.</li>
                <li>When you fill a registration form or otherwise submit your personal information.</li>
            </ul>
            <p>
                Your information will be stored for up to 30 days after it is
                no longer required to provide you the services. Your information may be retained for longer periods for
                reporting or record- keeping in accordance with applicable laws. Information which does not identify you
                personally may be stored indefinitely.    </p>
            <h4>How We Use Your Information</h4>
            <p>
                https://pegorarotranslations.com may use your information for the following purposes:        </p>
            <ul>
                <li><b>Providing and maintaining our Service,</b> as well as monitoring the usage of our Service.</li>
            </ul>
            <h4>How We Share Your Information</h4>
            <p>
                Pegoraro Traduzioni will share your information, when applicable, in the following situations:        </p>
            <ul>
                <li><b>With your consent.</b> Pegoraro Traduzioni will share your information for any purpose with your explicit
                    consent.</li>
            </ul>

            <h4>Third-party Sharing</h4>

            <p>Your information may be disclosed for additional reasons, including:</p>
            <ul>
                <li>Complying with applicable laws, regulations, or court orders.</li>
                <li>Responding to claims that your use of our Service violates third-party rights.</li>
                <li>Enforcing agreements you make with us, including this Privacy Policy.</li>
            </ul>

            <h4 id="cookie" >Cookies</h4>
            <p>
                Cookies are small text files that are placed on your computer by websites that you visit. Websites use
                cookies to help users navigate efficiently and perform certain functions. Cookies that are required for
                the website to operate properly are allowed to be set without your permission. All other cookies need
                to be approved before they can be set in the browser.    </p>
            <ul>
                <li><b>Strictly necessary cookies.</b> Strictly necessary cookies allow core website functionality such as
                    user login and account management. The website cannot be used properly without strictly necessary cookies.</li>
                <li><b>Performance cookies.</b> Performance cookies are used to see how visitors use the website, eg.
                    analytics cookies. Those cookies cannot be used to directly identify a certain visitor.</li>
                <li><b>Functionality cookies.</b> Functionality cookies are used to remember visitor information on the
                    website, eg. language, timezone, enhanced content.</li>
            </ul>

            <div className="cookie-report-container">
                <p data-cookiereport="true">
                    You can change your consent to cookie usage below.            </p>
                <script
                    type="text/javascript"
                    charset="UTF-8"
                    data-cookiescriptreport="report"
                    data-cs-lang="en"
                    src="//report.cookie-script.com/r/e0e295b576e0c9d920ab1941ea624a6a.js"
                >
                </script>
            </div>

            <h4>Security</h4>
            <p>
                Your information’s security is important to us. https://pegorarotranslations.com
                utilizes a range of security measures to prevent the misuse, loss, or alteration of the information you have given us.
                However, because we cannot guarantee the security of the information you provide us, you must access our service at your
                own risk.    </p>
            <p>
                Pegoraro Traduzioni is not responsible for the performance of websites
                operated by third parties or your interactions with them. When you leave this website, we recommend you review the
                privacy practices of other websites you interact with and determine the adequacy of those practices.    </p>

            <h4>Contact Us</h4>
            <p>For any questions, please contact us through the following methods:</p>
            <p>Name: Pegoraro Traduzioni</p>
            <p>Address: Ramon y Cajal 65 5D 43005 Tarragona SPAIN</p>
            <p>Email: info@pegorarotranslations.com</p>
            <p>Website: https://pegorarotranslations.com</p>
        </section>
    ),
    it: (
        <section className='content--container'>
            <h1>Informativa sulla privacy</h1>
            <p>Data di entrata in vigore: 2024-07-15</p>
            <p>Ultimo aggiornamento: 2024-07-15</p>
            <p>
                La presente Informativa sulla privacy spiega le politiche di Pegoraro Traduzioni sulla
                raccolta e l'uso delle informazioni che raccogliamo quando accedi a https://pegorarotranslations.com (il "Servizio"). La presente Informativa
                sulla privacy descrive i tuoi diritti e in che maniera sei protetto dalle leggi sulla privacy.</p>
            <p>
                Utilizzando il nostro Servizio, acconsenti alla raccolta e all'utilizzo delle tue informazioni
                in conformità con la presente Informativa sulla privacy. Ti preghiamo di non accedere o utilizzare il nostro Servizio se
                non acconsenti alla raccolta e all'utilizzo delle informazioni come indicato nella presente Informativa sulla privacy. La
                presente Informativa sulla privacy è stata creata con l'aiuto del <a target='_blank' href='https://cookie-script.com/privacy-policy-generator'>Generatore di informative sulla privacy di CookieScript</a>.</p>
            <p>Pegoraro Traduzioni è autorizzata a modificare la presente Informativa sulla privacy in qualsiasi momento. Questo può avvenire senza preavviso.</p>
            <p>
                Pegoraro Traduzioni pubblicherà l'Informativa sulla privacy modificata sul sito web https://pegorarotranslations.com.</p>

            <h3>Raccolta e utilizzo delle tue informazioni personali</h3>
            <h4>Informazioni raccolte</h4>
            <p>
                Quando utilizzi il nostro Servizio, ti verrà richiesto di fornirci delle informazioni personali utilizzate per contattarti o identificarti. https://pegorarotranslations.com raccoglie le seguenti informazioni:    </p>

            <ul>
                <li>Dati di utilizzo</li>
            </ul>

            <p>I dati di utilizzo includono quanto segue:</p>
            <ul>
                <li>Indirizzo Protocollo Internet (IP) dei computer che accedono al sito</li>
                <li>Richieste di pagine web</li>
                <li>Pagine web di riferimento</li>
                <li>Browser utilizzato per accedere al sito</li>
                <li>Ora e data di accesso</li>
            </ul>

            <h4>Come raccogliamo le informazioni</h4>
            <p>
                https://pegorarotranslations.com raccoglie e riceve informazioni da parte tua nelle seguenti modalità:    </p>
            <ul>
                <li>Quando interagisci con il nostro Servizio.</li>
                <li>Quando compili un modulo di registrazione o invii le tue informazioni personali in altro modo.</li>
            </ul>
            <p>
                Le tue informazioni saranno conservate per un massimo di 30 giorni
                dopo la chiusura del tuo account. Le tue informazioni possono essere conservate per periodi più lunghi per il
                reporting o per scopi di registrazione in conformità con le leggi applicabili. Le informazioni che non ti identificano
                personalmente possono essere conservate a tempo indeterminato.    </p>

            <h4>Come usiamo le tue informazioni</h4>
            <p>
                https://pegorarotranslations.com può utilizzare le tue informazioni per i seguenti scopi:        </p>
            <ul>
                <li><b>Fornire e mantenere il nostro Servizio,</b> così come monitorare l'utilizzo del nostro Servizio.</li>
            </ul>

            <h4>Come condividiamo le tue informazioni</h4>
            <p>
                Pegoraro Traduzioni condividerà le tue informazioni, se del caso, nelle seguenti situazioni:        </p>
            <ul>
                <li><b>Con il tuo consenso.</b> Pegoraro Traduzioni condividerà le tue informazioni per qualsiasi scopo con il tuo esplicito consenso.</li>
            </ul>

            <h4>Condivisione con terze parti</h4>

            <p>Le tue informazioni possono essere divulgate per altri motivi, tra cui:</p>
            <ul>
                <li>Conformità con le normative, i regolamenti o le ingiunzioni applicabili.</li>
                <li>Risposta a reclami sul tuo uso del nostro Servizio violando diritti di terzi.</li>
                <li>Rispetto degli accordi stipulati con noi, compresa la presente Informativa sulla privacy.</li>
            </ul>

            <h4 id="cookie"  >Cookie</h4>
            <p>
                I cookie sono piccoli file di testo che vengono collocati sul tuo computer dai siti web che visiti. I siti web utilizzano i cookie per aiutare gli utenti a navigare in modo efficiente ed eseguire determinate funzioni. I cookie che sono necessari per il corretto funzionamento del sito web possono essere impostati senza il tuo permesso. Tutti gli altri cookie devono essere approvati prima di essere impostati nel browser.    </p>
            <ul>
                <li><b>Cookie strettamente necessari.</b> I cookie strettamente necessari consentono le funzionalità principali del sito web come l'accesso dell'utente e la gestione dell'account. Il sito web non può essere utilizzato correttamente senza i cookie strettamente necessari.</li>
                <li><b>Cookie di performance.</b> I cookie di performance vengono utilizzati per vedere come i visitatori utilizzano il sito web, ad esempio con i cookie analitici. Questi cookie non possono essere utilizzati per identificare direttamente un determinato visitatore.</li>
                <li><b>Cookie di funzionalità.</b> I cookie di funzionalità vengono utilizzati per ricordare le informazioni del visitatore sul sito web, ad esempio lingua, fuso orario e contenuto avanzato.</li>
            </ul>

            <div className="cookie-report-container">
                <p data-cookiereport="true">
                    Puoi modificare il tuo consenso all'uso dei cookie qui sotto.            </p>
                <script
                    type="text/javascript"
                    charset="UTF-8"
                    data-cookiescriptreport="report"
                    data-cs-lang="it"
                    src="//report.cookie-script.com/r/e0e295b576e0c9d920ab1941ea624a6a.js"
                >
                </script>
            </div>

            <h4>Sicurezza</h4>
            <p>
                La sicurezza delle tue informazioni è importante per noi. https://pegorarotranslations.com utilizza una serie di misure di sicurezza per prevenire l'uso improprio, la perdita o l'alterazione delle informazioni che ci hai fornito. Tuttavia, poiché non possiamo garantire la sicurezza delle informazioni che ci fornisci, stai eseguendo l'accesso al nostro servizio a tuo rischio e pericolo.    </p>
            <p>
                Pegoraro Traduzioni non è responsabile delle prestazioni dei siti web gestiti da terzi o delle tue interazioni con essi. Quando abbandoni questo sito web, ti consigliamo di rivedere le pratiche in materia di privacy degli altri siti web con cui interagisci e determinare l'adeguatezza di tali pratiche.    </p>

            <h4>Contatti</h4>
            <p>Per qualsiasi domanda, puoi contattarci attraverso i seguenti canali:</p>
            <p>Nome: Pegoraro Traduzioni</p>
            <p>Indirizzo: Ramon y Cajal 65 5D 43005 Tarragona SPAIN</p>
            <p>E-mail: info@pegorarotranslations.com</p>
            <p>Sito web: https://pegorarotranslations.com</p>
        </section>
    ),
    de: (
        <section className='content--container'>
            <h1>Datenschutzrichtlinie</h1>
            <p>Gültig seit: 2024-07-15</p>
            <p>Aktualisiert am: 2024-07-15</p>
            <p>
                Diese Datenschutzrichtlinie erläutert die Richtlinien von
                Pegoraro Traduzioni zur Erfassung und Verwendung der Informationen, die wir sammeln, wenn Sie auf https://pegorarotranslations.com (den „Dienst“) zugreifen.
                Diese Datenschutzrichtlinie beschreibt Ihre Datenschutzrechte und wie Sie durch Datenschutzgesetze geschützt sind.</p>
            <p>
                Durch die Nutzung unseres Dienstes erklären Sie sich mit der Erfassung und Nutzung Ihrer
                Daten in Übereinstimmung mit dieser Datenschutzrichtlinie einverstanden. Bitte greifen Sie nicht auf unseren Dienst zu
                oder nutzen Sie ihn nicht, wenn Sie nicht mit der Erfassung und Nutzung Ihrer Daten, wie in dieser Datenschutzrichtlinie
                dargelegt, einverstanden sind. Diese Datenschutzerklärung wurde mithilfe des
                <a target="_blank" href="https://cookie-script.com/privacy-policy-generator">CookieScript Datenschutzerklärungs-Generators</a>
                erstellt.</p>
            <p>Pegoraro Traduzioni ist berechtigt, diese Datenschutzerklärung jederzeit zu ändern.
                Dies kann ohne vorherige Ankündigung geschehen. </p>
            <p>
                Pegoraro Traduzioni wird die überarbeitete Datenschutzrichtlinie auf der
                Website https://pegorarotranslations.com veröffentlichen.</p>

            <h3>Erfassung und Verwendung Ihrer persönlichen Daten</h3>
            <h4>Welche Art von Daten wir sammeln</h4>
            <p>
                Bei der Nutzung unseres Dienstes werden Sie aufgefordert, uns personenbezogene Daten
                mitzuteilen, um Sie zu kontaktieren oder zu identifizieren. https://pegorarotranslations.com sammelt die folgenden Informationen:    </p>

            <ul>
                <li>Nutzungsdaten</li>
            </ul>

            <p>Zu den Nutzungsdaten gehören die folgenden Daten:</p>
            <ul>
                <li>Internet-Protokoll (IP)-Adresse der Computer, die auf die Website zugreifen</li>
                <li>Website-Anfragen</li>
                <li>Verweisende Webseiten</li>
                <li>Für den Zugriff auf die Website verwendeter Browser</li>
                <li>Uhrzeit und Datum des Zugriffs</li>
            </ul>

            <h4>Auf welche Weise wir Daten sammeln</h4>
            <p>
                https://pegorarotranslations.com sammelt und erhält Daten von Ihnen auf folgende Weise:    </p>
            <ul>
                <li>Wenn Sie mit unserem Dienst interagieren.</li>
                <li>Wenn Sie ein Anmeldeformular ausfüllen oder auf andere Weise Ihre persönlichen Daten übermitteln.</li>
            </ul>
            <p>
                Ihre Daten werden bis zu 30 Tag(e) nach Kündigung Ihres
                Kontos gespeichert. Ihre Daten können in Übereinstimmung mit den geltenden Gesetzen für längere Zeiträume
                aufbewahrt werden, um Berichte oder Aufzeichnungen zu erstellen. Daten, die Sie nicht persönlich
                identifizieren, können auf unbestimmte Zeit gespeichert werden.    </p>

            <h4>Wozu wir Ihre Daten verwenden</h4>
            <p>
                https://pegorarotranslations.com kann Ihre Daten für die folgenden Zwecke verwenden:        </p>
            <ul>
                <li><b>Bereitstellung und Wartung unseres Dienstes,</b>sowie die Überwachung der Nutzung unseres Dienstes.</li>
            </ul>

            <h4>Wie wir Ihre Daten verwenden</h4>
            <p>
                Pegoraro Traduzioni kann Ihre Daten gegebenenfalls in den folgenden Situationen weitergeben:        </p>
            <ul>
                <li><b>Mit Ihrer Zustimmung.</b> Pegoraro Traduzioni wird Ihre Daten für jeden Zweck nur mit Ihrer ausdrücklichen Zustimmung weitergeben.</li>
            </ul>

            <h4>Freigabe durch Dritte</h4>

            <p>Ihre Daten können aus weiteren Gründen
                weitergegeben werden, z. B:</p>
            <ul>
                <li>Einhaltung geltender Gesetze, Vorschriften oder gerichtlicher Anordnungen.</li>
                <li>Reagieren auf Behauptungen, dass Ihre Nutzung unseres Dienstes die Rechte Dritter verletzt.</li>
                <li>Durchsetzung von Vereinbarungen, die Sie mit uns getroffen haben, einschließlich dieser Datenschutzrichtlinie.</li>
            </ul>

            <h4 id="cookie" >Cookies</h4>
            <p>
                Cookies sind kleine Textdateien, die von
                Websites, die Sie besuchen, auf Ihrem Computer gespeichert werden. Websites verwenden Cookies, um den Nutzern eine
                effiziente Navigation zu ermöglichen und zu helfen, bestimmte Funktionen auszuführen. Cookies, die für den
                ordnungsgemäßen Betrieb der Website erforderlich sind, dürfen auch ohne Ihre Zustimmung gesetzt werden. Alle anderen
                Cookies müssen erst genehmigt werden, bevor sie im Browser gesetzt werden können.    </p>
            <ul>
                <li><b>Unbedingt erforderliche Cookies.</b> Unbedingt erforderliche Cookies ermöglichen
                    wesentliche Kernfunktionen der Website wie die Benutzeranmeldung und die Kontoverwaltung. Ohne die unbedingt
                    erforderlichen Cookies kann die Website nicht ordnungsgemäß verwendet werden.</li>
                <li><b>Performance-Cookies.</b> Performance-Cookies sammeln Informationen darüber, wie Besucher
                    eine Webseite nutzen, z. B. Analyse-Cookies. Diese Cookies können nicht verwendet werden, um einen bestimmten Besucher
                    direkt zu identifizieren.</li>
                <li><b>Funktionale Cookies.</b> Funktionale Cookies werden verwendet, um Besucherinformationen
                    auf der Website zu speichern, z. Sprache, Zeitzone, erweiterter Inhalte.</li>
            </ul>

            <div className="cookie-report-container">
                <p data-cookiereport="true">
                    Sie können Ihre Zustimmung zur Cookie-Nutzung unten ändern.            </p>
                <script
                    type="text/javascript"
                    charset="UTF-8"
                    data-cookiescriptreport="report"
                    data-cs-lang="de"
                    src="//report.cookie-script.com/r/e0e295b576e0c9d920ab1941ea624a6a.js"
                >
                </script>
            </div>

            <h4>Sicherheit</h4>
            <p>
                Die Sicherheit Ihrer Daten ist für uns wichtig. https://pegorarotranslations.com setzt
                eine Reihe von Sicherheitsmaßnahmen ein, um den Missbrauch, den Verlust oder die Veränderung der von Ihnen zur Verfügung
                gestellten Daten zu verhindern. Da wir jedoch die Sicherheit der von Ihnen bereitgestellten Daten nicht garantieren
                können, erfolgt der Zugang zu unserem Dienst auf Ihr eigenes Risiko.    </p>
            <p>
                Pegoraro Traduzioni ist nicht verantwortlich für die Leistung von
                Websites, die von Dritten betrieben werden, oder für Ihre Interaktionen mit diesen. Wenn Sie diese Website
                verlassen, empfehlen wir Ihnen, die Datenschutzpraktiken anderer Websites, mit denen Sie interagieren, zu überprüfen
                und die Angemessenheit dieser Praktiken festzustellen.    </p>

            <h4>Kontakt</h4>
            <p>Wenn Sie Fragen haben, kontaktieren Sie uns bitte über eine der folgenden Kanäle:</p>
            <p>Name: Pegoraro Traduzioni</p>
            <p>Adresse: Ramon y Cajal 65 5D 43005 Tarragona SPAIN</p>
            <p>E-Mail: info@pegorarotranslations.com</p>
            <p>Website: https://pegorarotranslations.com</p>
        </section>
    ),
    fr: (
        <section className='content--container'>
            <h1>Politique de confidentialité</h1>
            <p>Date d'entrée en vigueur: 2024-07-15</p>
            <p>Mise à jour le: 2024-07-15</p>
            <p>
                Cette Politique de confidentialité explicite les politiques de Pegoraro Traduzioni
                relatives à la collecte et l'utilisation des informations que nous recueillons lorsque vous accédez
                à https://pegorarotranslations.com (le «Service»). Cette Politique de confidentialité décrit vos droits en matière de confidentialité et la façon
                dont vous êtes protégé par les lois sur la confidentialité. </p>
            <p>
                En utilisant notre Service, vous consentez à la collecte et à l'utilisation de vos
                informations conformément à la présente Politique de confidentialité. Veuillez ne pas accéder à notre Service ou
                l'utiliser si vous ne consentez pas à la collecte et à l'utilisation de vos informations comme indiqué dans la présente
                Politique de confidentialité. Cette Politique de confidentialité a été créée avec l'aide
                du <a target='_blank' href='https://cookie-script.com/privacy-policy-generator'>Générateur de Politique de Confidentialité de CookieScript</a>.</p>
            <p>Pegoraro Traduzioni est autorisé à modifier cette Politique de confidentialité à tout
                moment, y compris sans avis préalable. </p>
            <p>
                Pegoraro Traduzioni publiera la Politique de confidentialité révisée sur le site Web https://pegorarotranslations.com.</p>

            <h3>Collecte et utilisation de vos informations personnelles</h3>
            <h4>Informations que nous collectons</h4>
            <p>
                Lorsque vous utilisez notre Service, vous serez invité à nous fournir des informations
                personnelles utilisées pour vous contacter ou vous identifier. https://pegorarotranslations.com collecte les informations suivantes:    </p>

            <ul>
                <li>Données d'utilisation</li>
            </ul>

            <p>Les données d'utilisation comprennent les éléments suivants:</p>
            <ul>
                <li>L'adresse de protocole Internet (IP) des ordinateurs accédant au site</li>
                <li>Demandes de pages Web</li>
                <li>Pages Web de renvoi</li>
                <li>Navigateur utilisé pour accéder au site</li>
                <li>Date et heure d'accès</li>
            </ul>

            <h4>Comment nous collectons les informations</h4>
            <p>
                https://pegorarotranslations.com collecte et reçoit des informations de votre part de la manière suivante:    </p>
            <ul>
                <li>Lorsque vous interagissez avec notre Service.</li>
                <li>Lorsque vous remplissez un formulaire d'inscription ou soumettez autrement vos informations personnelles.</li>
            </ul>
            <p>
                Vos informations seront stockées jusqu'à 30 jour(s) après
                que vous ayez résilié votre compte. Vos informations peuvent être conservées pendant des périodes plus longues pour
                l'établissement de rapports ou la tenue d'archives, conformément aux lois applicables. Les informations qui ne permettent
                pas de vous identifier personnellement peuvent être conservées indéfiniment.    </p>

            <h4>Comment nous utilisons vos informations</h4>
            <p>
                https://pegorarotranslations.com peut utiliser vos informations aux fins suivantes:        </p>
            <ul>
                <li><b>Fournir et maintenir notre Service,</b> ainsi que surveiller l'utilisation de notre Service.</li>
            </ul>

            <h4>Comment nous partageons vos informations</h4>
            <p>
                Pegoraro Traduzioni partagera vos informations, le cas échéant, dans les situations suivantes:        </p>
            <ul>
                <li><b>Avec votre consentement.</b> Pegoraro Traduzioni partagera vos informations à toute fin avec votre consentement explicite.</li>
            </ul>

            <h4>Partage avec des tiers</h4>

            <p>Vos informations peuvent être divulguées pour d'autres raisons, notamment:</p>
            <ul>
                <li>Se conformer aux lois, règlements ou ordonnances judiciaires applicables.</li>
                <li>Répondre aux réclamations selon lesquelles votre utilisation de notre Service viole les droits de tiers.</li>
                <li>Faire respecter les accords que vous avez conclus avec nous, y compris la présente Politique de confidentialité.</li>
            </ul>

            <h4 id="cookie" >Cookies</h4>
            <p>
                Les cookies sont de petits fichiers texte qui
                sont placés sur votre ordinateur par les sites Web que vous visitez. Les sites Web utilisent des cookies pour aider les
                utilisateurs à naviguer efficacement et à exécuter certaines fonctions. Les cookies qui sont nécessaires au bon
                fonctionnement du site Web peuvent être installés sans votre autorisation. Tous les autres cookies doivent être approuvés
                avant de pouvoir être installés dans le navigateur.    </p>
            <ul>
                <li><b>Cookies strictement nécessaires.</b> Les cookies strictement nécessaires habilitent des fonctionnalités de base du site Web telles que la connexion des utilisateurs et la gestion des comptes. Le site Web ne peut pas être utilisé correctement sans les cookies strictement nécessaires.</li>
                <li><b>Cookies de performance.</b> Les cookies de performance sont utilisés pour voir comment les visiteurs utilisent le site Web, par exemple les cookies d'analyse. Ces cookies ne peuvent pas être utilisés pour identifier directement un visiteur spécifique.</li>
                <li><b>Cookies de fonctionnalité.</b> Les cookies de fonctionnalité sont utilisés pour mémoriser les informations des visiteurs sur le site Web, comme. la langue, le fuseau horaire, le contenu amélioré.</li>
            </ul>

            <div className="cookie-report-container">
                <p data-cookiereport="true">
                    Vous pouvez modifier votre consentement à l'utilisation des cookies ci-dessous.            </p>
                <script
                    type="text/javascript"
                    charset="UTF-8"
                    data-cookiescriptreport="report"
                    data-cs-lang="fr"
                    src="//report.cookie-script.com/r/e0e295b576e0c9d920ab1941ea624a6a.js"
                >
                </script>
            </div>

            <h4>Sécurité</h4>
            <p>
                La sécurité de vos informations nous tient à cœur. https://pegorarotranslations.com
                utilise une série de mesures de sécurité pour empêcher l'utilisation abusive, la perte ou l'altération des informations
                que vous nous avez fournies. Toutefois, comme nous ne pouvons pas garantir la sécurité des informations que vous nous
                fournissez, vous devez accéder à notre service à vos propres risques.    </p>
            <p>
                Pegoraro Traduzioni n'est pas responsable de la performance des sites
                Web exploités par des tiers ou de vos interactions avec eux. Lorsque vous quittez ce site Web, nous vous recommandons de
                passer en revue les pratiques de confidentialité des autres sites Web avec lesquels vous interagissez et de déterminer
                si ces pratiques sont adéquates.    </p>

            <h4>Nous contacter</h4>
            <p>Pour toute question, veuillez nous contacter par les biais suivants:</p>
            <p>Nom: Pegoraro Traduzioni</p>
            <p>Adresse: Ramon y Cajal 65 5D 43005 Tarragona SPAIN</p>
            <p>E-mail: info@pegorarotranslations.com</p>
            <p>Site Web: https://pegorarotranslations.com</p>
        </section>
    )
};