import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import './Work.scss';
import MetaTags from '../components/MetaTags';
import { useLanguage, routeMap } from '../hooks/useLanguage';
import { CallMade } from '@mui/icons-material';
import { motion } from 'framer-motion';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';




const JobCard = ({ data }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const intl = useIntl();

    const getTranslation = (id, defaultMessage) => {
        return intl.formatMessage({ id: id, defaultMessage: defaultMessage });
    };

    // Gestione sicura della descrizione
    const descriptionParts = data?.description?.split('\n\n') || ['', ''];
    const shortDescription = descriptionParts[0] || '';
    const fullDescription = descriptionParts[1] || '';

    return (
        <div className='card'>
            <div>
                <div className='title-and-tag'>
                    <h3>{data?.title || 'No Title'}</h3>
                    <span>
                        {getTranslation(`job.category.${data?.category}`, data?.category)}
                    </span>
                </div>
                <p>{shortDescription}</p>
                <motion.div
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: isExpanded ? 'auto' : 0, opacity: isExpanded ? 1 : 0 }}
                    transition={{ duration: 0.5 }}
                    style={{ overflow: 'hidden' }}
                >
                    <p>{fullDescription}</p>
                </motion.div>
            </div>
            <div className='time-and-button'>
                <div className='time'>
                    <span><AccessTimeOutlinedIcon /></span> 
                    {getTranslation(`job.type.${data?.type}`, data?.type)}
                </div>
                <div>
                    <button className='button-light' onClick={() => setIsExpanded(!isExpanded)}>
                        {isExpanded ? 
                            getTranslation("work.hideButton", "Hide") : 
                            getTranslation("work.readButton", "Read more")
                        }
                    </button>
                    <button className='button-dark'>
                        {getTranslation("work.applyButton", "Apply")}
                    </button>
                </div>
            </div>
        </div>
    );
};

const Work = () => {
    const [language] = useLanguage();
    const intl = useIntl();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitResult, setSubmitResult] = useState(null);
    const [jobs, setJobs] = useState([]);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: '',
    });

    const jobDetails = {
        titleId: "work.jobTitle",
        categoryId: "work.jobCategory",
        description1Id: "work.jobDescription1",
        description2Id: "work.jobDescription2",
        typeId: "work.jobType"
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        const formDataToSend = new FormData();
        for (const key in formData) {
            formDataToSend.append(key, formData[key]);
        }
        if (e.target.file.files[0]) {
            formDataToSend.append('file', e.target.file.files[0]);
        }
        try {
            const response = await fetch('https://pegoraro-backend.onrender.com/api/send-job-application', {
                method: 'POST',
                body: formDataToSend,
            });
            const data = await response.json();
            if (response.ok) {
                setSubmitResult({ success: true, message: intl.formatMessage({ id: 'work.successMessage' }) });
                setFormData({ firstName: '', lastName: '', email: '', phone: '', message: '' });
            } else {
                throw new Error(data.error || 'Errore nell\'invio della candidatura');
            }
        } catch (error) {
            setSubmitResult({ success: false, message: intl.formatMessage({ id: 'work.errorMessage' }) });
        } finally {
            setIsSubmitting(false);
        }
    };

  
    useEffect(() => {
        const fetchJobs = async () => {
            try {
                const response = await fetch('https://pegoraro-backend.onrender.com/api/works');
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                console.log('Dati ricevuti dall\'API:', data); // Debug log
                setJobs(data.docs);
            } catch (error) {
                console.error('Error fetching jobs:', error);
            }
        };

        fetchJobs();
    }, []); // Rimuovi language dalle dipendenze
    return (
        <div className="work">
            <MetaTags
                title={intl.formatMessage({ id: "work.metaTitle" })}
                description={intl.formatMessage({ id: "work.metaDescription" })}
            />
            <section  className='pegoraro-hero-section'   >
            <section className='hero'>
                <div className='hero--container'>
                    <h1><FormattedMessage id="work.title" /></h1>
                    <p><FormattedMessage id="work.description" /></p>
                    <div className='hero--buttons'>
                        <button className='button-dark'><FormattedMessage id="work.applyAsTranslator" /></button>
                        <button className='button-light'><FormattedMessage id="work.viewOpenPositions" /></button>
                    </div>
                </div>
            </section>

            </section>
         

            <div className='mt-50'>
                <div className="decorartion-shape">
                    <img src="/media/shape.png" alt="decoration" />
                </div>
            </div>
            <section   className='work-and-cadidate' >

            <section className='open-jobs'  >

<section className='content--container'>
                <span><FormattedMessage id="work.openPositions" /></span>
                <h2><FormattedMessage id="work.careerTitle" /></h2>
                <p><FormattedMessage id="work.careerDescription" /></p>
            </section>

            <div className="posizioni-cards">
                {jobs.map(job => {
                    const langKey = language.charAt(0).toUpperCase() + language.slice(1);
                    console.log('Dati per JobCard:', job); // Debug log
                    console.log('Lingua corrente:', language, 'LangKey:', langKey); // Debug log
                    return (
                        <JobCard 
                            key={job.id}
                            data={{
                                title: job[`title${langKey}`] || job.titleEn || 'No Title',
                                category: job.category,
                                description: job[`description${langKey}`] || job.descriptionEn || '',
                                type: job.type
                            }}
                        />
                    );
                })}
            </div>


</section>
         
            <div className='mt-50 desktop-remove'>
                <div className="decorartion-shape">
                    <img src="/media/shape.png" alt="decoration" />
                </div>
            </div>
<section  className='form-jobs'   >
<section className='content--container'>
                <span><FormattedMessage id="work.application" /></span>
                <h2><FormattedMessage id="work.lookingForTalent" /></h2>
                <p><FormattedMessage id="work.applicationDescription" /></p>
            </section>

            <section className='form--container'>
                <form onSubmit={handleSubmit}>
                    <div className='name-email'>
                        <input name="firstName" value={formData.firstName} onChange={handleInputChange} placeholder={intl.formatMessage({ id: 'work.firstName' })} required />
                        <input name="lastName" value={formData.lastName} onChange={handleInputChange} placeholder={intl.formatMessage({ id: 'work.lastName' })} required />
                    </div>
                    <div className='name-email'>
                        <input name="email" type="email" value={formData.email} onChange={handleInputChange} placeholder={intl.formatMessage({ id: 'work.email' })} required />
                        <input name="phone" value={formData.phone} onChange={handleInputChange} placeholder={intl.formatMessage({ id: 'work.phone' })} required />
                    </div>
                    <textarea name="message" value={formData.message} onChange={handleInputChange} placeholder={intl.formatMessage({ id: 'work.message' })} required />
                    <input type="file" name="file" />
                    <button className='icon-button' type="submit" disabled={isSubmitting}>
                        <span>
                            {isSubmitting 
                                ? <FormattedMessage id="work.submittingApplication" />
                                : <FormattedMessage id="work.submitApplication" />
                            }
                        </span>
                        <CallMade />
                    </button>
                </form>
                {submitResult && (
                    <div className={`submit-result ${submitResult.success ? 'success' : 'error'}`}>
                        {submitResult.message}
                    </div>
                )}
            </section>

</section>
            </section>

           
        </div>
    );
};

export default Work;