import React, { useEffect, useCallback, useRef, useMemo } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import Servizi from './pages/Servizi';
import ServiceDetail from './pages/ServiziDetail';
import Disclaimer from './pages/Disclaimer'; 
import Contact from './pages/Contact';
import NotFound from './pages/NotFound'
import LanguagePrompt from './components/LanguagePrompt';
import messages from './translations';
import About from './pages/About'
import Work from './pages/Work'
import { useLanguage, routeMap, languages, defaultLanguage } from './hooks/useLanguage';
import './App.scss'
import useMatomo from './hooks/useMatomo'; 

// App component che gestisce lo scroll
function App() {
  const lastScrollY = useRef(window.pageYOffset);

  const handleScroll = useCallback(() => {
    const currentScrollY = window.pageYOffset;
    if (currentScrollY > lastScrollY.current) {
      document.body.classList.add('scroll-down');
      document.body.classList.remove('scroll-up');
    } else {
      document.body.classList.add('scroll-up');
      document.body.classList.remove('scroll-down');
    }
    lastScrollY.current = currentScrollY;
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return ( 
    <Router>
      <AppContent />
    </Router>
  );
}

// AppContent component ottimizzato con React.memo
const AppContent = React.memo(function AppContent() {
  const [language, changeLanguage, showLanguagePrompt, setShowLanguagePrompt, declineLanguageChange, isManualChange] = useLanguage();
  const location = useLocation();
  const renderCount = useRef(0);

  // useEffect(() => {
  //   useMatomo()
  // }, []);


  // Effetto per aggiornare l'attributo lang del documento
  useEffect(() => {
    document.documentElement.lang = language;
    console.log('Language changed to:', language);
  }, [language]);

  // Effetto per scrollare in cima alla pagina quando cambia il pathname
  useEffect(() => {
    window.scrollTo(0, 0);
    console.log('Scrolled to top due to pathname change:', location.pathname);
  }, [location.pathname]);

  // Effetto per gestire la visibilità del Language Prompt
  useEffect(() => {
    if (isManualChange) {
      setShowLanguagePrompt(false);
    }
  }, [isManualChange, setShowLanguagePrompt]);

  // Callback per accettare il cambio di lingua
  const handleAcceptLanguageChange = useCallback(() => {
    const browserLang = navigator.language.split('-')[0];
    if (languages.includes(browserLang)) {
      changeLanguage(browserLang);
    }
    setShowLanguagePrompt(false);
    console.log('Accepted language change to browser language:', browserLang);
  }, [changeLanguage, setShowLanguagePrompt]);

  // Callback per rifiutare il cambio di lingua
  const handleDeclineLanguageChange = useCallback(() => {
    declineLanguageChange();
    setShowLanguagePrompt(false);
    console.log('Declined language change');
  }, [declineLanguageChange, setShowLanguagePrompt]);

  // Memo per la lingua del browser
  const detectedLanguage = useMemo(() => navigator.language.split('-')[0], []);

  // Memo per la lingua iniziale
  const getInitialLanguage = useMemo(() => {
    const browserLang = navigator.language.split('-')[0];
    return languages.includes(browserLang) ? browserLang : defaultLanguage;
  }, []);

  // Memo per le route language-specific
  const languageRoutes = useMemo(() => 
    languages.map(lang => (
      <Route key={lang} path={`/${lang}/*`} element={
        <Routes>
          <Route path={routeMap[lang].home} element={<Home />} />
          <Route path={routeMap[lang].contact} element={<Contact />} />
          <Route path={routeMap[lang].services} element={<Servizi />} />
          <Route path={`${routeMap[lang].services}/:url`} element={<ServiceDetail />} />
          <Route path={routeMap[lang].disclaimer} element={<Disclaimer />} />
          <Route path={routeMap[lang].about} element={<About />} /> 
          <Route path={routeMap[lang].workWithUs} element={<Work />} /> 
          <Route path="*" element={<NotFound />} /> 
        </Routes>
      } />
    )),
  []);

  // Incrementa e logga il conteggio dei render
  useEffect(() => {
    renderCount.current += 1;
    console.log('AppContent render count:', renderCount.current);
  });

  //console.log('Current location in AppContent:', location.pathname);

  return (
    <IntlProvider messages={messages[language]} locale={language}>
      <Navbar language={language} onLanguageChange={changeLanguage} routeMap={routeMap} />
      {showLanguagePrompt && !isManualChange && (
        <LanguagePrompt
          onAccept={handleAcceptLanguageChange}
          onDecline={handleDeclineLanguageChange}
          detectedLanguage={detectedLanguage}
        />
      )}
      <Routes>
        <Route path="/" element={<Navigate to={`/${getInitialLanguage}/${routeMap[getInitialLanguage].home}`} replace />} />
        {languageRoutes}
        <Route path="*" element={<NotFound />} /> 
      </Routes>
      <Footer language={language} onLanguageChange={changeLanguage} routeMap={routeMap} />
    </IntlProvider>
  );
});

export default App;
